import cn from 'classnames';

import { IconAccount } from '$components/index';

import { AgentProps } from './types';
import styles from './Agent.module.less';
import { useDateTime } from '$hooks/index';

const Agent = ({ agent, className }: AgentProps) => {
  const dateTime = useDateTime();

  if (agent) {
    return (
      <div className={cn(styles.main, className)}>
        <div className={styles.head}>
          <div className={styles.title}>Agent information</div>
          <div className={styles.data}>joined {dateTime.format(agent.joinedDate, 'withoutDay')}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.avatar}>
            <IconAccount name="avatar" />
          </div>
          <div className={styles.contacts}>
            <div className={styles.name}>{agent.name}</div>
            <div className={styles.phone}>{agent.accountPhone}</div>
            <div className={styles.phone}>{agent.companyPhone}</div>
            <div className={styles.email}>{agent.email}</div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default Agent;
