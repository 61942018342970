import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Image } from '$ui/index';

import useGeneratePath from '$hooks/useGeneratePath';
import { formatCurrency } from '$utils/formatCurrency';
import { routes } from '$router/index';
import { BoatIcons, Icon, ManualSkeleton } from '$components/index';

import { BoatCardProps } from './types';
import styles from './BoatCard.module.less';
import picturePlaceholder from '$assets/img/placeholder.svg';

const BoatCard = ({ item, isBuy, isLazyImages, isNewSize }: BoatCardProps) => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { generatePath } = useGeneratePath();

  const mainPhoto = item ? item.pictures[0] : undefined;

  if (!item) {
    return (
      <div className={styles.card}>
        <div className={styles.photo}>
          <ManualSkeleton className={styles.skeletonPhoto} />
        </div>
        <div className={styles.info}>
          <div className={styles.specifications}>
            <ManualSkeleton className={styles.skeletonSpecifications} />
          </div>
          <div className={styles.name}>
            <ManualSkeleton className={styles.skeletonName} />
          </div>
          <div className={styles.rating}>
            <ManualSkeleton className={styles.skeletonRating} />
          </div>
          <div className={styles.location}>
            <ManualSkeleton className={styles.skeletonLocation} />
          </div>
          <div className={styles.price}>
            <div>
              <ManualSkeleton className={styles.skeletonPrice} />
            </div>
            <div>
              <ManualSkeleton className={styles.skeletonPerWeek} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const rentPrice = [
    item.exclusivePrice?.pricePerHour,
    item.exclusivePrice?.pricePerDay,
    item.exclusivePrice?.pricePerWeek,
  ]
    .filter((i) => i)
    .find((i) => i);

  const labelRentPricePeriod = [
    item.exclusivePrice?.pricePerHour && 'per hour',
    item.exclusivePrice?.pricePerDay && 'per day',
    item.exclusivePrice?.pricePerWeek && 'per week',
  ]
    .filter((i) => i)
    .find((i) => i);

  return (
    <Link
      to={{
        pathname: generatePath(isBuy ? routes.BUY_BOAT_VIEW : routes.BOAT_VIEW, {
          boatIdOrSlug: item.slug ? String(item.slug) : item.id,
        }),
        state: {
          prevPath: generatePath(isBuy ? routes.BUY_BOATS : routes.BOATS) + routerLocation.search,
        },
      }}
    >
      <div className={styles.card}>
        <div className={styles.photo}>
          {isNewSize && mainPhoto ? (
            <picture>
              <source
                srcSet={[
                  mainPhoto.w280 ? `${mainPhoto.w280} 1x` : null,
                  mainPhoto.w560 ? `${mainPhoto.w560} 2x` : null,
                  mainPhoto.w840 ? `${mainPhoto.w840} 3x` : null,
                ]
                  .filter((i) => i)
                  .join(', ')}
              />
              <img
                src={`${mainPhoto.md || picturePlaceholder}`}
                alt={item.name ?? 'boat'}
                loading={isLazyImages ? 'lazy' : undefined}
              />
            </picture>
          ) : (
            <Image
              className={styles.photo}
              alt={item.name ?? 'boat'}
              preview={false}
              fallback={picturePlaceholder}
              src={item.pictures[0]?.md || picturePlaceholder}
              loading={isLazyImages ? 'lazy' : undefined}
            />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.specifications}>
            <BoatIcons boat={item} />
          </div>
          <div className={styles.name}>
            {item.name}
            {item.builtAt && ` (${item.builtAt})`}
          </div>
          <div className={styles.rating}>
            <Icon name="rating" className={styles.icon} />
            {item.rating ? item.rating.toFixed(1) : '5.0'}
          </div>
          {(item.location?.address || item.location?.country?.name) && (
            <div className={styles.location}>
              <Icon name="location" className={styles.icon} />
              {item.location?.address ? item.location.address : t('frontend.boat_card.no_address')}{' '}
              {item.location?.country?.name ? item.location.country.name : t('frontend.boat_card.no_country')}
            </div>
          )}

          {!isBuy && !!rentPrice && !!item.exclusivePrice?.currency && (
            <div className={styles.price}>
              <div>
                From <span className={styles.value}>{formatCurrency(rentPrice, item.exclusivePrice.currency)}</span>
              </div>
              <div>{labelRentPricePeriod}</div>
            </div>
          )}

          {isBuy && !!item.salePrice?.value && !!item.salePrice?.currency && (
            <div className={styles.price}>
              Sale price:{' '}
              <span className={styles.value}>{formatCurrency(item.salePrice.value, item.salePrice.currency)}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default BoatCard;
