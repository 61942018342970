import cn from 'classnames';
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input } from '$ui/index';

import { InputPhonePreviewProps } from './types';

import styles from './InputPhonePreview.module.less';

const InputPhonePreview = ({ value, onChange, handleActivity, isSpin, theme }: InputPhonePreviewProps) => {
  if (typeof onChange !== 'function' || typeof handleActivity !== 'function') {
    return null;
  }

  return (
    <div className={cn(styles.preview, theme === 'mini' && styles.themeMini)}>
      <div className={styles.selectorFlag} onClick={() => handleActivity('flag')}>
        <div className={styles.icon} />
        <div className={styles.arrow}>
          <CaretDownOutlined />
        </div>
      </div>

      <Input
        value={value ? value : '+1'}
        onChange={(e) => onChange(e.currentTarget.value)}
        onFocus={() => handleActivity('input')}
        className={cn(styles.input, isSpin && styles.withSpin)}
      />

      {isSpin && (
        <div className={styles.spin}>
          <LoadingOutlined spin />
        </div>
      )}
    </div>
  );
};

export default InputPhonePreview;
