import { useState, useEffect, MouseEventHandler } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, Collapse, message, Popover } from '$ui/index';
import { QuestionCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { GraphQL } from '$graphql/client';
import { useAddStripePaymentMutation } from '$graphql/hooks';
import { useDateTime } from '$hooks/index';
import { CharterOffersSubscription, OffersQuery, OfferStatus } from '$graphql/types';
import { formatCurrency } from '$utils/formatCurrency';
import { routes } from '$router/index';

import { BoatIcons, Icon } from '$components/index';
import picturePlaceholder from '$assets/img/placeholder.svg';

import { BoatsCardProps } from './types';
import SelectorInterestedUser from './components/SelectorInterestedUser';
import styles from './BoatsCard.module.less';
import useGeneratePath from '$hooks/useGeneratePath';

const BoatsCard = ({ offer, disabled }: BoatsCardProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const dateTime = useDateTime();
  const { Panel } = Collapse;

  const history = useHistory();

  const [addStripePayment] = useAddStripePaymentMutation({ errorPolicy: 'all' });
  const [paymentMethod] = useState('stripe');
  const [disabledAll, setDisabledAll] = useState(false);
  const [currentProcessing, setCurrentProcessing] = useState<
    OffersQuery['offers'][0]['id'] | CharterOffersSubscription['charterOffers'][0]['id'] | null
  >(null);
  const [processing, setProcessing] = useState(false);
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    const hash = history.location.hash;
    const el = hash && document.getElementById(hash.substr(1));
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }, [history.location.hash]);

  const handleUnLink: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  const priceMainTitle = (
    <>
      {offer?.priceWithoutDiscount?.amount &&
        offer?.price?.amount &&
        offer.priceWithoutDiscount.amount > offer.price.amount && (
          <div className="offers-page__popover-row">
            <span>Without discount</span>
            <div>
              <p className="price">
                <s>{formatCurrency(offer.priceWithoutDiscount.amount, offer.priceWithoutDiscount.currency)}</s>
              </p>
            </div>
          </div>
        )}

      <div className="offers-page__popover-row">
        <span>{t('frontend.charter.boats_card.total_renting_price')}</span>
        <div>
          <p className="price green">
            {offer?.price?.amount
              ? formatCurrency(offer.price.amount, offer.price.currency)
              : t('frontend.charter.boats_card.n_a')}
          </p>
        </div>
      </div>
    </>
  );

  const priceMobile = (
    <>
      <div>{priceMainTitle}</div>
    </>
  );

  const pricePerDay = (offer: BoatsCardProps['offer']) => {
    const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;

    return (
      <>
        {offer && !!offer.price?.amount && offer.startAt && offer.endAt && !!diffDays && diffDays > 1 && (
          <div className={styles.pricePerDay}>
            <div>{t('frontend.charter.boats_card.price_per_day')}</div>
            <div>{formatCurrency(Number((offer.price?.amount / diffDays).toFixed(0)), offer.price.currency)}</div>
          </div>
        )}
      </>
    );
  };

  const pricePerDayPopover = (offer: BoatsCardProps['offer']) => {
    const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;

    return (
      <>
        {offer && offer.price?.amount && offer.startAt && offer.endAt && !!diffDays && diffDays > 1 && (
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.price_per_day')}</span>
            <div>
              <p className="price">
                {formatCurrency(Number((offer.price?.amount / diffDays).toFixed(0)), offer.price.currency)}
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const contentPopover = (offer: BoatsCardProps['offer'], withTitle = true) => (
    <div className="offers-page__popover">
      {withTitle && <div>{priceMainTitle}</div>}
      {offer?.prepayPercent === 100 ? (
        <>
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.single_payment')}</span>
            <div>
              <p className="price">{formatCurrency(offer?.firstPaymentAmount, offer?.price?.currency)}</p>
            </div>
          </div>
          {pricePerDayPopover(offer)}
        </>
      ) : (
        <>
          {pricePerDayPopover(offer)}
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.first_payment')}</span>
            <div>
              <p className="price">{formatCurrency(offer?.firstPaymentAmount, offer?.price?.currency)}</p>
              <span className="price additional">({offer?.prepayPercent}%)</span>
            </div>
          </div>
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.second_payment')}</span>
            <div>
              <p className="price">{formatCurrency(offer?.secondPaymentAmount, offer?.price?.currency)}</p>
              <span className="price additional">({offer?.secondPaymentPercent}%)</span>
            </div>
          </div>
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.second_payment_till')}</span>
            <div>
              <p className="price">{dateTime.format(offer?.payTillOn, 'th', true)}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );

  type CardProps = {
    offer: BoatsCardProps['offer'];
    disabled?: boolean;
  };

  const handlePay = (offer: OffersQuery['offers'][0] | CharterOffersSubscription['charterOffers'][0]) => {
    setCurrentProcessing(offer.id);
    setDisabledAll(true);
    setProcessing(true);

    if (paymentMethod !== 'stripe') return;

    if (offer && offer.id && offer.firstPaymentAmount && offer.price?.currency) {
      addStripePayment({
        variables: {
          offerId: offer.id + '',
          amount: offer.firstPaymentAmount,
          currency: offer.price.currency,
        },
      })
        .then(({ data }) => {
          const paymentId = data?.addStripePayment?.id;
          if (paymentId) {
            sendDataToGTM({ event: 'submit_ga_charter_page_create_payment', value: offer.id });
            history.push(generatePath(routes.PAYMENT_VIEW, { paymentId: `${paymentId}` }));
            return;
          }
        })
        .catch(({ errors }) => {
          const extensions: GraphQLError['extensions'] = GraphQL.formatErrors(errors, 'signIn');

          if (extensions?.validation) {
            message.error(extensions.validation.base);
          }
        })
        .finally(() => {
          setCurrentProcessing(null);
          setDisabledAll(false);
          setProcessing(false);
        });
    }
  };

  const getPayBtn = (
    offer: OffersQuery['offers'][0] | CharterOffersSubscription['charterOffers'][0],
    disabled?: boolean
  ) => {
    const link =
      (offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering) && offer.payments[0].id > 0
        ? generatePath(routes.PAYMENT_VIEW, { paymentId: `${offer.payments[0].id}` })
        : generatePath(routes.BOOKING_VIEW, { offerId: `${offer.id}` });

    if (offer.payments[0].id > 0) {
      return (
        <Link to={link}>
          <Button
            disabled={disabledAll || disabled}
            type="primary"
            shape="round"
            size="large"
            style={{ width: '180px' }}
            className={
              offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering
                ? 'ga_charter_page_payment_button'
                : 'ga_charter_page_booking_button'
            }
          >
            {offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering
              ? t('frontend.charter.boats_card.request_book')
              : 'Booking'}
          </Button>
        </Link>
      );
    }

    return (
      <Button
        disabled={disabled}
        loading={processing && currentProcessing === offer.id}
        onClick={() => handlePay(offer)}
        type="primary"
        shape="round"
        size="large"
        style={{ width: '180px' }}
        className="ga_charter_page_create_payment_button"
      >
        {offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering
          ? t('frontend.charter.boats_card.request_book')
          : 'Booking'}
      </Button>
    );
  };

  const Card = ({ offer, disabled }: CardProps) => (
    <>
      {offer && (
        <div id={String(offer.id)} className={cn('offers-page-item', disabled && 'offers-page-item--disabled')}>
          <div className="offers-page-item__img">
            <Link
              to={{
                pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                  boatIdOrSlug: offer.boat.slug ? String(offer.boat.slug) : offer.boat.id,
                  offerId: `${offer.id}`,
                }),
              }}
            >
              <img
                className="ant-image-img offers-img"
                src={offer.boat.pictures[0]?.md || picturePlaceholder}
                alt={offer.boat.name || 'boat #' + offer.boat.id}
              />
            </Link>
            <div className="offers-page-item__rating">
              <span>{offer.boat.rating ? offer.boat.rating.toFixed(1) : '5.0'}</span>
              <Icon name="rating" style={{ fill: disabled ? '#e9e9e9' : '#ffcd1a', zIndex: 1 }} />
            </div>
          </div>
          <div className="offers-page-item__content">
            <div className="offers-page-item__title">
              <Link
                to={{
                  pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                    boatIdOrSlug: offer.boat.slug ? String(offer.boat.slug) : offer.boat.id,
                    offerId: `${offer.id}`,
                  }),
                }}
              >
                <h3>{offer.boat.name + (offer.boat.builtAt ? ', ' + offer.boat.builtAt : '')}</h3>
              </Link>
            </div>
            <div className="offers-page-item__info">
              <div className="offers-page__icons">
                <BoatIcons boat={offer.boat} disabled={disabled} />
              </div>
              <div className="offers-page__location">
                {[offer.boat.location?.address, offer.boat.location?.country?.name].filter((i) => i).join(', ')}
              </div>
              <div className="offers-page__date">{dateTime.textDateRange(offer.startAt, offer.endAt)}</div>
            </div>
            <div className="offers-page-item__price offers-page-item__price-desktop">
              <div>{t('frontend.charter.boats_card.total_renting_price')}</div>
              <Popover placement="bottom" content={contentPopover(offer, true)}>
                <div>
                  {offer?.priceWithoutDiscount?.amount &&
                    offer?.price?.amount &&
                    offer.priceWithoutDiscount.amount > offer.price.amount && (
                      <div className="price">
                        <s>{formatCurrency(offer.priceWithoutDiscount.amount, offer.priceWithoutDiscount.currency)}</s>
                      </div>
                    )}
                  <div className="price">
                    {offer.price?.amount
                      ? formatCurrency(offer.price?.amount, offer.price.currency)
                      : t('frontend.charter.boats_card.n_a')}
                    <QuestionCircleOutlined onClick={handleUnLink} />
                  </div>
                </div>
              </Popover>
              {offer.price?.amount && offer.startAt && offer.endAt && pricePerDay(offer)}
            </div>
            <div className="offers-page-item__price offers-page-item__price-mobile" onClick={handleUnLink}>
              <Collapse ghost>
                <Panel header={priceMobile} key="1">
                  {contentPopover(offer, false)}
                </Panel>
              </Collapse>
            </div>
            <div className="offers-page-item__actions">
              {offer.isPreliminary ? (
                <SelectorInterestedUser offerId={offer.id} value={!!offer.isUserInterest} />
              ) : (
                getPayBtn(offer, disabled)
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );

  if (!offer || !offer.boat) return null;

  return <>{offer && <>{!disabled ? <Card offer={offer} /> : <Card offer={offer} disabled={disabled} />}</>}</>;
};

export default BoatsCard;
