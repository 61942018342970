import { lazy, Suspense, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { Input } from '$ui/index';
import styles from './DatePickerLazy.module.less';

const DatePicker = lazy(() => import('./component/DatePicker'));

const DatePickerLazy = ({ ...props }: PickerDateProps<Dayjs>) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isSpin, setIsSpin] = useState<boolean>(false);

  const handleChange = (value?: string) => {
    if (value && props.onChange) {
      props.onChange(dayjs(value), value);
    }
  };

  const handleLoad = () => {
    setIsLoad(true);
    setTimeout(() => setIsSpin(true), 300);
  };

  return (
    <>
      {!isLoad ? (
        <Input
          placeholder={props.placeholder}
          value={props.value ? props.value.format('YYYY-MM-DD') : undefined}
          onChange={(e) => handleChange(e.currentTarget.value)}
          onFocus={handleLoad}
          className={cn(props.className, styles.inputPreview)}
        />
      ) : (
        <Suspense
          fallback={
            <Input
              placeholder={props.placeholder}
              value={props.value ? props.value.format('YYYY-MM-DD') : undefined}
              onChange={(e) => handleChange(e.currentTarget.value)}
              suffix={isSpin ? <LoadingOutlined className={styles.spin} spin /> : undefined}
              className={cn(props.className, isSpin ? styles.inputLoading : styles.inputPreview)}
            />
          }
        >
          <DatePicker
            {...props}
            autoFocus={isOpen}
            open={isOpen}
            onClick={() => setIsOpen(true)}
            onFocus={() => setIsOpen(true)}
            onSelect={() => setIsOpen(false)}
            onBlur={() => setIsOpen(false)}
            className={cn(props.className, styles.datePicker)}
          />
        </Suspense>
      )}
    </>
  );
};

export default DatePickerLazy;
