import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { useCurrentUserLocalQuery, useForLandingPageLazyQuery } from '$graphql/hooks';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { Collapse } from '$components/index';

import {
  TitleBlock,
  Form,
  ExploreBlock,
  SwipeBoats,
  CategoriesBoats,
  Countries,
  Reviews,
  ListYourBoat,
  Articles,
  AdvantagesTabs,
  CallMe,
  LinkMore,
} from './components';

import staticData from './staticData';
import { LandingPageSearchParams } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './LandingPage.module.less';

const LandingPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { generatePath } = useGeneratePath();
  const search = parseUrlQuery(location.search) as LandingPageSearchParams;
  const { data: currentUser } = useCurrentUserLocalQuery();

  const [getData, { data, loading }] = useForLandingPageLazyQuery();

  const [formLocationId, setFormLocationId] = useState<string | undefined>();
  const [formLocationName, setFormLocationName] = useState<string | undefined>();
  const [locationNameForTitle, setLocationNameForTitle] = useState<string | undefined>();

  useEffect(() => {
    setLocationNameForTitle(search?.l);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData({
      variables: { locationId: formLocationId, locationName: formLocationId ? undefined : formLocationName, limit: 6 },
    });
  }, [getData, formLocationId, formLocationName]);

  const getTitle = (search: LandingPageSearchParams) => {
    return t('frontend.banner.title');

    // TODO 2023 Temporarily turn off header changes. We'll remove it completely later.
    if (search?.ch) {
      return search?.ch;
    } else if (!search?.ch && locationNameForTitle) {
      return 'Best Prices for Yacht Charters in ' + locationNameForTitle;
    } else {
      return t('frontend.banner.title');
    }
  };

  return (
    <>
      <Helmet>
        <title>Boat rentals near me - yacht charters and boats hide with getboat.com</title>
        <meta
          name="description"
          content="Find boat rentals, yachts for sale, and charter services worldwide. Your journey on the water begins here."
        />
      </Helmet>

      <div className={commonStyles.container} data-cy="LandingPageComponent">
        <TitleBlock title={getTitle(search)} subTitle="20,000 boats • Hourly, Daily, Weekly • Captain optional" />
      </div>

      <div className={cn(commonStyles.container, styles.form)}>
        <Form
          setFormLocationId={setFormLocationId}
          setFormLocationName={setFormLocationName}
          setLocationNameForTitle={setLocationNameForTitle}
        />
      </div>

      <div className={cn(commonStyles.container, styles.block, styles.exploreBlock)}>
        <h2 className={styles.subTitle}>How does it work?</h2>
        <ExploreBlock />
      </div>

      {/*<div className={cn(commonStyles.container, styles.exploreBlock)}>*/}
      <AdvantagesTabs />
      {/*</div>*/}

      {(data?.featuredBoatsForRent.length || loading) && (
        <div className={cn(commonStyles.container, styles.blockWithShadow)}>
          <h2 className={styles.subTitle}>{t('frontend.featured.title')}</h2>
          <SwipeBoats boats={data?.featuredBoatsForRent} isSkeleton={loading} isNewPhoto />
        </div>
      )}

      {(data?.rentBoats.items.length || loading) && (
        <div className={cn(commonStyles.container, styles.block, styles.subTitleBlock)}>
          <h2 className={styles.subTitle}>Latest added yachts</h2>
          <SwipeBoats boats={data?.rentBoats.items} isSkeleton={loading} isNewPhoto />
          <LinkMore link={generatePath(routes.BOATS)} className={styles.linkMore} />
        </div>
      )}

      {staticData?.categoriesBoats && (
        <div className={cn(commonStyles.container, styles.block)}>
          <h2 className={styles.subTitle}>Browse by boat type</h2>
          <CategoriesBoats items={staticData.categoriesBoats} />
        </div>
      )}

      {staticData?.countries && (
        <div className={cn(commonStyles.container, styles.block)}>
          <h2 className={styles.subTitle}>Discover the best destinations</h2>
          <Countries items={staticData.countries} />
        </div>
      )}

      {staticData?.reviews && (
        <div className={cn(commonStyles.container, styles.block)}>
          <h2 className={styles.subTitle}>What our clients are saying</h2>
          <Reviews items={staticData.reviews} />
        </div>
      )}

      {(data?.saleBoats.items.length || loading) && (
        <div className={cn(commonStyles.container, styles.blockWithShadow, styles.subTitleBlock)}>
          <h2 className={styles.subTitle}>Boat for sales</h2>
          <SwipeBoats boats={data?.saleBoats.items} isSkeleton={loading} isBuy isNewPhoto />
          <LinkMore link={generatePath(routes.BUY_BOATS)} className={styles.linkMore} />
        </div>
      )}

      <div className={cn(commonStyles.container, styles.block)}>
        <ListYourBoat isAdmin={!!currentUser?.currentUser.accessAdmin} />
      </div>

      {staticData?.articles && (
        <div className={cn(commonStyles.container, styles.block, styles.subTitleBlock)}>
          <h2 className={styles.subTitle}>Recent from our blog</h2>
          <Articles items={staticData.articles} />
          <LinkMore link="https://newsgetboat.com/" isExternal className={styles.linkMore} />
        </div>
      )}

      {staticData?.defaultFaqs && (
        <div className={cn(commonStyles.container, styles.block, styles.subTitleBlock)}>
          <h2 className={styles.subTitle}>Frequently asked questions</h2>
          <Collapse items={staticData.defaultFaqs} className={styles.collapse} />
        </div>
      )}

      <div className={cn(commonStyles.container, styles.block)}>
        <CallMe />
      </div>
    </>
  );
};

export default LandingPage;
