type TDefaultFaqs = { key: string; label: string; children: string };

const defaultFaqs: Array<TDefaultFaqs> = [
  {
    key: '1',
    label: 'Where are your yachts docked?',
    children:
      'Literally everywhere! We are an online marketplace that operates worldwide. No matter where you are going, all you need for your sail vacation is our website. We make it easy for you to plan your next sea adventure!',
  },
  {
    key: '2',
    label: 'Do we need to come to your office to sign the agreement?',
    children:
      'We value your time and strive to make all paperwork convenient. With no need for a contract, we operate based on the offer available on our website and sign all necessary documents using e-signature',
  },
  {
    key: '3',
    label: 'What is the cancellation policy?',
    children:
      'Know before you go! Every boat has its own cancellation policy, set by the owner. But don&apos;t worry, we&apos;ll fill you in on all the details before you rent, so you can sail with peace of mind!',
  },
  {
    key: '4',
    label: "I made a request, but can't see any offers.",
    children:
      'You can expect to start receiving offers from yacht owners within the first 2-3 hours after you make a request. Sometimes it may take longer if the owner is in a different time zone or not immediately available to respond. If you don&apos;t receive enough offers or the ones you receive aren&apos;t suitable, don&apos;t worry! Our managers are always available to assist you in your search. Just make sure to check your WhatsApp and email regularly as we&apos;ll communicate with you there.',
  },
  {
    key: '5',
    label: 'I haven’t found what I am looking for. What can I do?',
    children:
      'Don&apos;t hesitate to email us at <a href="mailto:info@getboat.com" target="_blank" rel="noreferrer">info@getboat.com</a>! We&apos;ll do our best to respond as quickly as possible and provide the assistance you need.',
  },
  {
    key: '6',
    label: 'Can you pick us up from our location?',
    children:
      'Absolutely! We can arrange for transfers to and from your yacht. Please note that this is an additional service and will be charged separately for your convenience.',
  },
  {
    key: '7',
    label: 'Can I charter the boat without a captain?',
    children:
      'Absolutely! You&apos;re more than welcome to book a yacht with us and operate it on your own. However, we do require that you provide us with a valid license corresponding to the region you choose. This ensures everyone&apos;s safety while out on the water.',
  },
];

export default defaultFaqs;
