import { Collapse } from '$components/index';

import { FaqProps } from './types';
import styles from './Faq.module.less';

const Faq = ({ title, items, className }: FaqProps) => {
  return (
    <div className={className}>
      <h2 className={styles.title}>{title}</h2>
      <Collapse items={items} className={styles.collapse} />
    </div>
  );
};
export default Faq;
