import { useState } from 'react';
import { Typography } from '$ui/index';
import cn from 'classnames';

import { FloatInputLabelProps } from './types';
import styles from './FloatInputLabel.module.less';

const FloatInputLabel = ({
  children,
  label,
  isFloated,
  labelStyles,
  labelFloatedStyles,
  size,
  labelFloatedClass,
}: FloatInputLabelProps) => {
  const { Text } = Typography;
  const [focus, setFocus] = useState(false);

  return (
    <div
      className={cn(styles.label, size === 'small' && styles.small)}
      style={labelStyles}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      {children}
      <Text
        className={cn(labelFloatedClass, styles.text, (isFloated || focus) && styles.textFloat)}
        style={labelFloatedStyles}
      >
        {label}
      </Text>
    </div>
  );
};

export default FloatInputLabel;
