import spritePaymentSystems from './assets/sprite.svg';

export const paymentSystems = [
  {
    name: 'Visa',
    width: 30,
    icon: spritePaymentSystems + '#visa',
  },
  {
    name: 'Master Card',
    width: 21,
    icon: spritePaymentSystems + '#mastercard',
  },
  {
    name: 'Discover',
    width: 53,
    icon: spritePaymentSystems + '#discover',
  },
  {
    name: 'Apple Pay',
    width: 30,
    icon: spritePaymentSystems + '#applePay',
  },
  {
    name: 'Google Pay',
    width: 33,
    icon: spritePaymentSystems + '#googlePay',
  },
];
