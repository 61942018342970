import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';

import { Swiper, SwiperSlide } from '$components/index';

import { TabsProps } from './types';
import styles from './Tabs.module.less';

const Tabs = ({ items, selected, changeSelected, theme = 'underlining', className }: TabsProps) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 460px)',
  });

  return (
    <div
      className={cn(
        className,
        styles.main,
        theme === 'underlining' && styles.themeUnderlining,
        theme === 'button' && styles.themeButton
      )}
    >
      <div className={styles.list}>
        {isMobile && theme === 'underlining' ? (
          <Swiper spaceBetween={0} isNavigation={false} isFreeMode>
            {items.map((item) => (
              <SwiperSlide key={item.key} style={{ maxWidth: 'max-content' }}>
                <div
                  onClick={() => changeSelected(item.key)}
                  className={cn(styles.item, item.key === selected && styles.selected)}
                >
                  {item.name}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            {items.map((item) => (
              <div
                onClick={() => changeSelected(item.key)}
                className={cn(styles.item, item.key === selected && styles.selected)}
                key={item.key}
              >
                {item.name}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Tabs;
