import { AutoComplete } from '$ui/index';
import cn from 'classnames';

import { Icon } from '$components/index';

import { LocationsProps } from './types';
import styles from './Locations.module.less';
import '../InputPhone/components/LazyInputPhone/style.css'; // eject!!!

const Locations = ({
  value,
  onChange,
  onSelect,
  onBlur,
  items,
  placeholder,
  config,
  dataCy,
  className,
}: LocationsProps) => {
  return (
    <AutoComplete
      defaultValue={value}
      onChange={onChange}
      onSelect={onSelect}
      onBlur={onBlur}
      placeholder={placeholder}
      className={className}
      data-cy={dataCy}
      {...config}
    >
      {items?.map((item) => (
        <AutoComplete.Option key={item.id} value={item.address as string}>
          <div className={styles.option}>
            {item.type === 'Location::Country' && (
              <div className="react-tel-input">
                <span className={cn(styles.flag, 'flag ' + item.country?.code.toLocaleLowerCase())} />
              </div>
            )}
            {item.type !== 'Location::Country' && <Icon name="location" className={styles.marker} />}
            <div>{item.address}</div>
            {item.type !== 'Location::Country' && <div>, {item.country?.name}</div>}
          </div>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};

export default Locations;
