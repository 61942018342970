import { useEffect, useRef } from 'react';

import { DrawMapProps } from './types';

const DrawMap = ({ lat, lng, zoom, circle, height }: DrawMapProps) => {
  const refDomMap = useRef<HTMLDivElement>(null);
  const refMap = useRef<google.maps.Map | null>(null);
  const refCircle = useRef<google.maps.Circle | null>(null);

  useEffect(() => {
    if (!refMap.current) {
      refMap.current = new window.google.maps.Map(refDomMap.current as HTMLElement, {
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
      });
    }
    // not use else
    if (refMap.current) {
      refMap.current.setCenter({ lat: lat, lng: lng });
      refMap.current.setZoom(zoom);

      // Чистим карту от старой метки
      if (refCircle.current) {
        refCircle.current.setMap(null);
      }

      // Если нужно создаём новую метку
      if (circle && lat && lng) {
        refCircle.current = new google.maps.Circle({
          strokeColor: '#1967DD',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#1967DD',
          fillOpacity: 0.2,
          map: refMap.current,
          center: { lat: lat, lng: lng },
          radius: 16093,
        });
      }
    }
  }, [lat, lng, zoom, circle]);

  return (
    <div
      ref={refDomMap}
      style={{
        height: height,
        width: '100%',
      }}
    />
  );
};

export default DrawMap;
