import MediaQuery from 'react-responsive';
import cn from 'classnames';

import { Swiper, SwiperSlide } from '$components/index';

import { ReviewCard, Total } from './components/index';
import { ReviewsProps } from './types';
import styles from './Reviews.module.less';

const Reviews = ({ reviews, total, className }: ReviewsProps) => {
  return (
    <div className={cn(className, styles.reviews)}>
      <div className={styles.title}>Top boat rentals reviews</div>

      {(total?.average || total?.customers) && <Total average={total?.average} customers={total?.customers} />}

      {reviews && (
        <div className={styles.list}>
          <MediaQuery maxWidth={991}>
            <Swiper isNavigation={false} className={styles.swiper}>
              {reviews.map((review) => (
                <SwiperSlide key={review.id} className={styles.slide}>
                  <ReviewCard key={review.id} item={review} />
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaQuery>
          <MediaQuery minWidth={992}>
            {reviews.map((review) => (
              <ReviewCard key={review.id} item={review} />
            ))}
          </MediaQuery>
        </div>
      )}
    </div>
  );
};

export default Reviews;
