import { Link } from 'react-router-dom';
import cn from 'classnames';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { Swiper, SwiperSlide } from '$components/index';

import { SubLocationsProps } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './SubLocations.module.less';

const SubLocations = ({ items, title, className }: SubLocationsProps) => {
  const { generatePath } = useGeneratePath();

  return (
    <div className={cn(commonStyles.container, styles.container, className)}>
      <div className={styles.title}>Destinations for boat rentals in {title}</div>
      <Swiper spaceBetween={28} isNavigation={false} className={styles.categories}>
        {items?.map((item) => (
          <>
            {item && item.slug && item.address && item.previewImages?.x1 && (
              <SwiperSlide key={item.id} className={styles.slide}>
                <Link to={{ pathname: generatePath(routes.LOCATION, { path: item.slug }) }} className={styles.category}>
                  <div className={styles.photo}>
                    <picture>
                      <source
                        type="image/webp"
                        srcSet={[
                          item.previewImages?.x1 && `${item.previewImages.x1} 1x`,
                          item.previewImages?.x2 && `${item.previewImages.x1} 2x`,
                          item.previewImages?.x3 && `${item.previewImages.x1} 3x`,
                        ]
                          .filter((i) => i)
                          .join(', ')}
                      />
                      <img src={item.previewImages?.x1} alt={item.address} />
                    </picture>
                  </div>
                  <div className={styles.name}>{item.address}</div>
                </Link>
              </SwiperSlide>
            )}
          </>
        ))}
      </Swiper>
    </div>
  );
};

export default SubLocations;
