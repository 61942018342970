import cn from 'classnames';

import { SocialNetworksProps } from './types';
import styles from './SocialNetworks.module.less';

const SocialNetworks = ({ items, className }: SocialNetworksProps) => {
  return (
    <div className={cn(styles.main, className)}>
      {items.map((item) => (
        <a key={item.name} href={item.link} target="_blank" rel="noreferrer" className={styles.item}>
          <svg width={item.width}>
            <use xlinkHref={item.icon} />
          </svg>
        </a>
      ))}
    </div>
  );
};

export default SocialNetworks;
