import { useProfileLazyQuery } from '$graphql/hooks';
import { userVar } from '$graphql/local/vars';
import useSession from '$stores/session';
import { useEffect } from 'react';

const useProfile = () => {
  const [getProfile] = useProfileLazyQuery();

  const token = useSession.getState()?.session?.token;

  useEffect(() => {
    if (token) {
      getProfile({
        context: { errorPolicy: 'IgnoreErrorsQuery' },
      }).then((result) => {
        if (result.data) {
          userVar({
            id: result.data.profile?.id,
            isLoggedIn: true,
            email: result.data.profile?.email || '',
            accessAdmin: !!result.data.profile?.accessAdmin,
            isCentralAgent: !!result.data.profile?.isCentralAgent,
            isAdmin: !!result.data.profile?.isAdmin,
            isPaidSubscription: !!result.data.profile?.isPaidSubscription,
            isSeniorAccount: !!result.data.profile?.isSeniorAccount,
            isPartnerAccount: !!result.data.profile?.isPartnerAccount,
          });
        }
      });
    }
  }, [getProfile, token]);
};
export default useProfile;
