export const defaultFaqs: Array<{ key: string; label: string; children: string }> = [
  {
    key: '1',
    label: 'Why is GetBoat.com better than other yacht and boat rental platforms?',
    children:
      '<a href="https://getboat.com/" target="_blank">Getboat.com</a> is the only platform that operates on the principle of an auction market system. When you make a rental request setting up the necessary parameters and your budget, the owners compete with each other to make you the best price offer. <a href="https://getboat.com/" target="_blank">Getboat.com</a> allows you to get rental offers easily and fast worldwide; it is our second key difference.',
  },
  {
    key: '2',
    label: 'How can I rent a boat on Getboat.com?',
    children:
      'Go to the request page <a href="https://getboat.com/en/request_offers" target="_blank">https://getboat.com/en/request_offers</a>;<br>' +
      'Choose the type of boat you need;<br>' +
      'Set your location, travel dates, and number of guests on the boat;<br>' +
      'Specify your email so you can receive our boat rental newsletters;<br>' +
      'Enter your phone number to get our offers via SMS;<br>' +
      'Specify your approximate budget;<br>' +
      'Add any important information to your request;<br>' +
      'Add extra services if necessary;<br>' +
      'Click the button to grab the best offers.',
  },
  {
    key: '3',
    label: 'How much does it cost to rent a boat?',
    children:
      'The rental cost depends on the boat type, size, and other characteristics, as well as the rental location and period (it may be hourly, daily, weekly, etc.)',
  },
  {
    key: '4',
    label: 'How many guests can I have on a rental yacht?',
    children:
      'It depends on the boat size. Boat owners specify the maximum number of people allowed. You can rent a boat for up to 50 people on our website.',
  },
  {
    key: '5',
    label: 'What is the minimum age for renting boats?',
    children:
      'You must be 18 years old to rent a captained boat and 25 years old if you would like to rent a bareboat charter.',
  },
  {
    key: '6',
    label: 'Do I need a boating license to rent a boat?',
    children:
      'Boating license requirements vary from state to state. As a renter, you are responsible for understanding local state’s boating license requirements.',
  },
];

export const federalStateCode = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District of Columbia',
    code: 'DC',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },

  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },

  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },

  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },

  // Далее различные земли и острава
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Minor Outlying Islands',
    code: 'UM',
  },

  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Micronesia',
    code: 'FM',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
];
