import Table from 'antd/lib/table';
import { PartnerProps } from './types';

const Partner = ({ item }: PartnerProps) => {
  const referralLink = `https://getboat.com/?utm_medium=${item.referralTag}&utm_source=partner`;

  const dataSource = [
    {
      key: '1',
      name: 'Name',
      value: item.name,
    },
    {
      key: '2',
      name: 'E-mail',
      value: item.email,
    },
    {
      key: '3',
      name: 'Contacts',
      value: item.contacts,
    },
    {
      key: '4',
      name: 'Phone',
      value: item.phone,
    },
    {
      key: '5',
      name: 'Link',
      value: (
        <a href={referralLink} target="_blank" rel="noreferrer">
          {referralLink}
        </a>
      ),
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return <Table dataSource={dataSource} columns={columns} showHeader={false} pagination={false} />;
};

export default Partner;
