import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Steps } from '$ui/index';
import { SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { OfferStatus } from '$graphql/types';

import BoatsCard from './components/BoatsCard';

import { RequestBoatsProps } from './types';
import '$assets/styles/offersPage/index.less';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './RequestBoats.module.less';

const RequestBoats = (props: RequestBoatsProps) => {
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();
  const { Step } = Steps;
  const isCreateListOffers = props?.offers && props.offers.length > 0;

  useEffect(() => {
    if (isCreateListOffers) {
      sendDataToGTM({ event: 'create_ga_charter_page_list_offers' });
    }
  }, [isCreateListOffers, sendDataToGTM]);

  if (!props.offers) return null;

  const offers = [...props.offers];

  offers
    .sort((offer1, offer2) => {
      if (offer1.id < offer2.id) {
        return -1;
      }

      return 0;
    })
    .sort((offer1, offer2) => {
      if (
        (offer1.status === OfferStatus.Processing || offer1.status === OfferStatus.Selected) &&
        offer2.status !== OfferStatus.Processing &&
        offer2.status !== OfferStatus.Selected
      ) {
        return -1;
      }

      if (offer1.status === OfferStatus.Selected && offer2.status === OfferStatus.Processing) {
        return -1;
      }

      return 0;
    });

  let isDisabledOffers = false;

  if (offers.find((offer) => offer.status === OfferStatus.Processing || offer.status === OfferStatus.Selected)) {
    isDisabledOffers = true;
  }

  return (
    <div className="offers-page__wrapper">
      <div className={commonStyles.container}>
        {offers.length > 0 ? (
          <div className={cn('offers-page__items', 'ga_charter_page_list_offers')}>
            {offers.map((offer) => (
              <BoatsCard
                key={offer.id}
                offer={offer}
                disabled={
                  isDisabledOffers && offer.status !== OfferStatus.Processing && offer.status !== OfferStatus.Selected
                }
              />
            ))}
          </div>
        ) : (
          <div className={styles.notOffers}>
            <Steps current={1} size="small" responsive>
              <Step title={t('frontend.charter.getting_offers_step1')} icon={<SolutionOutlined />} />
              <Step title={t('frontend.charter.getting_offers_step2')} icon={<LoadingOutlined />} />
              <Step title={t('frontend.charter.getting_offers_step3')} icon={<SmileOutlined />} />
            </Steps>
            <div className={styles.title}>{t('frontend.charter.header_while_no_offers')}</div>
            <div className={styles.text}>{t('frontend.charter.text_while_no_offers')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestBoats;
