export enum TCharterSource {
  /** all charters allowed role */
  All = 'all',
  /** own charters partner */
  Own = 'own',
  /** all charters of partner */
  Partner = 'partner',
  /** all charters by promo code */
  PromoCode = 'promo_code',
  /** charters by referral link */
  ReferralLink = 'referral_link',
}
