const useTextGenerator = (
  location: {
    type?: string;
    name?: string | null;
    country?: string | null;
    countryCode?: string | null;
    federalState?: string | null;
  },
  federalStateCodes?: Array<{ name: string; code: string }>,
  boatCategories?: Array<{ id: string; name?: string | null }> | null
) => {
  const locationText = (type?: 'withBoatCategory') => {
    const getLocationNames = () => {
      const getFederalSateCode = (name?: string | null) => {
        return federalStateCodes?.find((i) => i.name === name)?.code || null;
      };

      if (type === 'withBoatCategory') {
        switch (location.type) {
          case 'Location::Country':
            return [location.name, location.countryCode];

          case 'Location::State':
            return [location.name, getFederalSateCode(location.name)];

          case 'Location::City':
          default:
            return [location.name, getFederalSateCode(location.federalState) || location.countryCode];
        }
      }

      switch (location.type) {
        case 'Location::Country':
          return [location.name];

        case 'Location::State':
          return [location.name, getFederalSateCode(location.name), location.country];

        case 'Location::City':
        default:
          return [location.name, location.country];
      }
    };

    return (
      getLocationNames()
        ?.filter((i) => i)
        .join(', ') || ''
    );
  };

  const boatCategoriesText = () => {
    return boatCategories?.map((i) => i.name)?.join(', ') || '';
  };

  return { locationText, boatCategoriesText };
};

export default useTextGenerator;
