import { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { useGetBoatQuery } from '$graphql/hooks';
import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { BoatViewParams } from '$router/types';
import NotFoundPage from '$pages/NotFoundPage';

import { BoatPageProps } from './types';
import BoatPageView from './BoatPageView';

const BoatPage = ({ isBuy, idOldUrl }: BoatPageProps) => {
  const { generatePath } = useGeneratePath();
  const history = useHistory();
  const sendDataToGTM = useGTMDispatch();

  const { boatIdOrSlug } = useParams<BoatViewParams>();
  const isUrlBoatId = boatIdOrSlug === boatIdOrSlug?.replace(/[^0-9]/g, '');
  const {
    loading: boatLoading,
    data: boatData,
    error: boatError,
  } = useGetBoatQuery({
    variables: {
      id: isUrlBoatId ? +boatIdOrSlug : undefined,
      slug: !isUrlBoatId ? boatIdOrSlug : undefined,
    },
  });
  const boat = boatData?.boat;
  const routerLocation = useLocation<{ prevPath: string }>();

  /* For boat only rent - "URL boat sale" redirect to "URL boat rent" */
  const redirectUrl =
    boat &&
    generatePath(routes.BOAT_VIEW, {
      boatIdOrSlug: boat.slug ? boat.slug : boat.id,
    });

  useEffect(() => {
    if (redirectUrl && isBuy && boat?.boatProvidedFor === 'rent') {
      history.push(redirectUrl);
    }
  }, [redirectUrl, history, isBuy, boat?.boatProvidedFor]);
  /**/

  useEffect(() => {
    if (boat?.id) {
      sendDataToGTM({
        event: 'cityAdsProductId',
        product_id: boat?.id,
      });
    }
  }, [boat?.id, sendDataToGTM]);

  // Если пришли по ID, то подмена URL на slug
  if (boat && ((isUrlBoatId && boat?.slug && boat.slug.length > 0) || idOldUrl)) {
    window.location.replace(
      generatePath(isBuy ? routes.BUY_BOAT_VIEW : routes.BOAT_VIEW, {
        boatIdOrSlug: boat.slug ? boat.slug : boat.id,
      })
    );

    return null;
  }

  let markerPosition;

  /* TODO 2021 переодически в dev-mode вылетает ошибка связанная с отсудствием lat,
    как только будет получен устойчивый кейс нужно решить проблему */
  if (boat && boat.location?.longitude && boat?.location?.latitude) {
    markerPosition = {
      lng: boat.location.longitude,
      lat: boat.location.latitude,
    };
  }

  let breadcrumbItems: Array<{ title?: string | null; url?: string | null; tag?: 'h1' }> | undefined;
  let seo: { title?: string } | undefined;

  if (boat) {
    breadcrumbItems = [
      {
        title: isBuy ? 'Boats for sale' : 'Boats for charter',
        url: routerLocation.state?.prevPath || generatePath(isBuy ? routes.BUY_BOATS : routes.BOATS),
      },
      { title: [boat.name, boat.builtAt].filter((i) => i).join(', ') },
    ];

    seo = {
      title: [
        boat.boatCategory?.name,
        boat.name,
        boat.location?.country?.name,
        boat.location?.address,
        isBuy ? 'for purchase' : 'for charter',
      ]
        .filter((i) => i)
        .join(' '),
    };
  }

  // Home -> [Country] -> Rent a Boat in [City] -> [Type] [City] -> [H1 boat]
  if (localStorage.getItem('hidden_seo') === 'true' && boat) {
    breadcrumbItems = [
      {
        title: boat.location?.country?.name,
        url: boat.slugs?.country && generatePath(routes.LOCATION, { path: boat.slugs.country }),
      },
      {
        title: boat.location?.address && `Rent a Boat in ${boat.location.address}`,
        url: boat.location?.slug && generatePath(routes.LOCATION, { path: boat.location.slug }),
      },
      {
        title:
          boat.boatCategory?.name && boat.location?.address && `${boat.boatCategory.name} ${boat.location.address}`,
        url: boat.slugs?.locationAndCategory && generatePath(routes.LOCATION, { path: boat.slugs.locationAndCategory }),
      },
      {
        title: `${boat.name}`,
        tag: 'h1',
      },
    ];
  }

  if (boatError) {
    return <NotFoundPage />;
  }

  return (
    <BoatPageView
      boat={boat}
      boatLoading={boatLoading}
      seo={seo}
      breadcrumbItems={breadcrumbItems}
      markerPosition={markerPosition}
      isBuy={isBuy}
    />
  );
};

export default BoatPage;
