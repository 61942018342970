import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import StripeSection from './StripeSection';

const PaymentPage = () => {
  let key = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

  switch (window.location.hostname) {
    case 'staging.getboat.com':
    case 'staging-travelpeople.getboat.com':
    case 'localhost':
      key = process.env.REACT_APP_STRIPE_PUBLISH_KEY_TEST;
      break;
  }

  const stripePromise = loadStripe(key || '');

  return (
    <Elements stripe={stripePromise}>
      <StripeSection />
    </Elements>
  );
};

export default PaymentPage;
