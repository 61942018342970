import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { Button } from '$ui/index';

import { images } from './data';
import { ListYourBoatProps } from './types';
import styles from './ListYourBoat.module.less';

const ListYourBoat = ({ isAdmin }: ListYourBoatProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h3 className={styles.title}>List your boat on GetBoat.com</h3>
        <div className={styles.description}>Let your yacht work for you while you earn.</div>
        {isAdmin ? (
          <a href="https://getboat.com/admin/#/Boat" target="_blank" rel="noreferrer" className={styles.link}>
            <Button type="primary" size="large" className={styles.button}>
              {t('frontend.header.actions.list_your_boat')}
            </Button>
          </a>
        ) : (
          <Link to={{ pathname: generatePath(routes.SIGN_UP), search: '?iam=ca' }} className={styles.link}>
            <Button type="primary" size="large" className={styles.button}>
              {t('frontend.header.actions.list_your_boat')}
            </Button>
          </Link>
        )}
      </div>
      {images.banner.img && (
        <div className={styles.image}>
          <picture>
            <source
              type="image/webp"
              media="(max-width: 360px)"
              srcSet={`${images.banner.img.mobile_320.x1.webp} 1x, ${images.banner.img.mobile_320.x2.webp} 2x, ${images.banner.img.mobile_320.x3.webp} 3x`}
            />
            <source
              type="image/webp"
              media="(min-width: 361px) and (max-width: 575px)"
              srcSet={`${images.banner.img.mobile_335.x1.webp} 1x, ${images.banner.img.mobile_335.x2.webp} 2x, ${images.banner.img.mobile_335.x3.webp} 3x`}
            />
            <source
              type="image/webp"
              media="(min-width: 576px) and (max-width: 991px)"
              srcSet={`${images.banner.img.table.x1.webp} 1x, ${images.banner.img.table.x2.webp} 2x, ${images.banner.img.table.x3.webp} 3x`}
            />
            <source
              type="image/webp"
              media="(min-width: 992px)"
              srcSet={`${images.banner.img.desktop.x1.webp} 1x, ${images.banner.img.desktop.x2.webp} 2x, ${images.banner.img.desktop.x3.webp} 3x`}
            />
            <img src={images.banner.img.desktop.x1.jpeg} alt={images.banner.alt} loading="lazy" />
          </picture>
          <div className={styles.shadow} />
        </div>
      )}
    </div>
  );
};

export default memo(ListYourBoat);
