import { Suspense, lazy, useState } from 'react';

import InputPhonePreview from './components/InputPhonePreview';

import { InputPhoneProps } from './types';

const LazyInputPhone = lazy(() => import('./components/LazyInputPhone'));

const InputPhone = ({ value, onChange, language, theme }: InputPhoneProps) => {
  const [isSpin, setIsSpin] = useState<boolean>(false);
  const [activity, setActivity] = useState<'input' | 'flag' | undefined>(undefined);

  if (typeof onChange !== 'function') {
    return null;
  }

  const handleActivity = (value?: 'input' | 'flag') => {
    setActivity(value);
    setTimeout(() => setIsSpin(true), 3000);
  };

  return (
    <>
      {!activity ? (
        <InputPhonePreview value={value} onChange={onChange} handleActivity={handleActivity} theme={theme} />
      ) : (
        <Suspense
          fallback={
            <InputPhonePreview
              value={value}
              onChange={onChange}
              handleActivity={handleActivity}
              isSpin={isSpin}
              theme={theme}
            />
          }
        >
          <LazyInputPhone value={value} onChange={onChange} language={language} activity={activity} theme={theme} />
        </Suspense>
      )}
    </>
  );
};

export default InputPhone;
