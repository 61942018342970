import { Spin } from '$ui/index';
import { LoadingOutlined } from '@ant-design/icons';

import commonStyle from '$assets/styles/Common.module.less';

const Preloader = () => {
  return (
    <div className={commonStyle.container} style={{ padding: '120px 0' }}>
      <Spin delay={1000} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default Preloader;
