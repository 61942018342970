import { generatePath, Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import { routes } from '$router/index';

import { LinkMoreProps } from './types';

const LinkMore = ({ link, isExternal, className }: LinkMoreProps) => {
  const body = () => (
    <>
      See more <RightOutlined style={{ fontSize: '10px' }} />
    </>
  );

  return isExternal ? (
    <a href={link} target="_blank" rel="noreferrer" className={className}>
      {body()}
    </a>
  ) : (
    <Link to={generatePath(routes.BUY_BOATS)} className={className}>
      {body()}
    </Link>
  );
};

export default LinkMore;
