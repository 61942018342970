import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import load from 'little-loader';

import { useChatQuery } from '$graphql/hooks';

import { ChatProps } from './types';
import styles from './Chat.module.less';

declare global {
  interface Window {
    Fiber: {
      _isFiberSdk?: boolean;
      init(a: { app_id?: number; lang?: string; user_token?: string }): void;
      push(a: () => void): void;
      renderChatUI(a: {
        target: string;
        navigate: {
          type: string;
          external_id?: string;
        };
      }): void;
      subscribe(a: string, b: (count: number) => void): void;
    };
  }
}

const Chat = ({ offerId }: ChatProps) => {
  const { i18n } = useTranslation();
  const { data: chatData } = useChatQuery({ variables: { offerId: offerId } });

  if (!window.Fiber?._isFiberSdk) {
    load('https://app.fiberchat.us/widget_sdk/sdk.js');
  }

  useEffect(() => {
    window.Fiber = window.Fiber || [];

    if (window.Fiber && window.Fiber && chatData && chatData.chat) {
      window.Fiber.push(() => {
        try {
          window.Fiber.init({
            app_id: chatData.chat?.appId,
            lang: i18n.language,
            user_token: chatData.chat?.userToken,
          });
        } catch (e) {
          if ((e as Error).message !== 'Fiber already initialized') {
            console.error(e);
          }
        }

        const threadId = chatData.chat?.threadId;

        window.Fiber.renderChatUI({
          target: '#fiber-chat', // chat container
          navigate: { type: 'thread', external_id: threadId }, // type - thread | thread_list
        });
        // display chat ui
        window.Fiber.subscribe(`unreadCount:e:${threadId}`, (_: number) => {
          // set unread badge
        });

        window.Fiber.subscribe('unreadCount', (_: number) => {
          // set total unread badge
        });
      });
    }
  }, [chatData, i18n.language]);

  return <div className={styles.chat} id="fiber-chat" />;
};

export default Chat;
