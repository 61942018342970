import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, message } from '$ui/index';
import cn from 'classnames';

import { useResetPasswordMutation } from '$graphql/hooks';
import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { PasswordResetParams } from '$router/types';

import { FormData } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './PasswordResetPage.module.less';

const PasswordResetPage = () => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const history = useHistory();
  const params = useParams<PasswordResetParams>();
  const [resetPassword, { loading }] = useResetPasswordMutation();

  const changePassword = async (data: FormData) => {
    try {
      await resetPassword({
        variables: {
          password: data.password,
          passwordConfirmation: data.passwordConfirm,
          accountId: Number(params.accountId),
          confirmationCode: params.confirmationCode,
        },
      });

      history.replace(generatePath(routes.SIGN_IN));
    } catch (err) {
      message.error('Invalid link');
    }
  };

  return (
    <div className={cn(commonStyles.container, styles.main)}>
      <Form layout="vertical" onFinish={changePassword}>
        <Form.Item name="password" required label={t('frontend.password_reset.new_password')}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="passwordConfirm"
          required
          label={t('frontend.password_reset.new_password_confirm')}
          rules={[
            {
              required: true,
              message: t('frontend.password_reset.no_confirm_password_provided'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('frontend.password_reset.passwords_not_same'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Button loading={loading} type="primary" htmlType="submit" shape="round" size="large" block>
          {t('frontend.password_reset.change_password_button')}
        </Button>
      </Form>
    </div>
  );
};

export default PasswordResetPage;
