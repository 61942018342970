import { Helmet } from 'react-helmet-async';

import useTextGenerator from './hooks/useTextGenerator';
import { SeoH1 } from './components/SeoH1';
import { SeoProps } from './types';

const Seo = ({ location, boatCategories, federalStateCodes, minPrice, totalCount }: SeoProps) => {
  const { locationText, boatCategoriesText } = useTextGenerator(location, federalStateCodes, boatCategories);

  if (boatCategories && boatCategories?.length > 0) {
    return (
      <Helmet>
        <title>
          {`${boatCategoriesText()} rentals in ${locationText('withBoatCategory')} - rent near me on GetBoat.com`}
        </title>

        {/* Запрет на индексацию для нескольких категория */}
        {boatCategories.length > 1 ? (
          <meta name="robots" content="noindex, follow" />
        ) : (
          <meta
            name="description"
            content={
              `Book the best ${locationText('withBoatCategory')} ` +
              `${boatCategoriesText()} rentals near you today on GetBoat.com. ` +
              `✓ 24/7 Customer Support ✓ With or without captain.`
            }
          />
        )}
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{`Boat rentals in ${locationText()} - Yacht charter near me: GetBoat.com`}</title>
      <meta
        name="description"
        content={
          `Book luxury yachts for charter in ${locationText()} ` +
          `${[
            minPrice && `from ${minPrice}/day`,
            totalCount && totalCount > 0 && `✓ ${totalCount} boats available`,
            `✓ With or without captain`,
            `✓ 24/7 Customer Support`,
            `✓ Rent a boat near you now!`,
          ]
            .filter((i) => i)
            .join(' ')}`
        }
      />
    </Helmet>
  );
};

Seo.H1 = SeoH1;

export default Seo;
