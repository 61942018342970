import { useState, memo } from 'react';
import { Link } from 'react-router-dom';

import { routes } from '$router/index';
import { Swiper, SwiperSlide } from '$components/index';

import { CategoriesBoatsProps } from './types';
import styles from './CategoriesBoats.module.less';
import useGeneratePath from '$hooks/useGeneratePath';

const CategoriesBoats = ({ items }: CategoriesBoatsProps) => {
  const { generatePath } = useGeneratePath();

  const [isLazyImages, setIsLazyImages] = useState<boolean>(true);

  return (
    <Swiper
      spaceBetween={18}
      onSliderFirstMove={() => {
        setIsLazyImages(false);
      }}
      isNavigation={false}
      className={styles.categories}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id} className={styles.slide}>
          <Link
            to={{
              pathname: generatePath(routes.BOATS),
              search: 'page=1&cat=' + item.id,
            }}
            className={styles.category}
          >
            <picture style={{ backgroundColor: item.backgroundColor }}>
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${item.img.mobile.x1.webp} 1x, ${item.img.mobile.x2.webp} 2x, ${item.img.mobile.x3.webp} 3x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px)"
                srcSet={`${item.img.desktop.x1.webp} 1x, ${item.img.desktop.x2.webp} 2x, ${item.img.desktop.x3.webp} 3x`}
              />
              <img src={item.img.desktop.x1.jpeg} alt={item.name} loading={isLazyImages ? 'lazy' : undefined} />
            </picture>
            <div className={styles.name}>{item.name}</div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(CategoriesBoats, (oldProps, newProps) => {
  return oldProps.items.map((i) => i.id).join(',') === newProps.items.map((i) => i.id).join(',');
});
