export const labelCondition = [
  {
    name: 'New',
    value: 1,
  },
  {
    name: 'Used',
    value: 2,
  },
];
