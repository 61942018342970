import { AccountsProps } from './types';
import styles from './Accounts.module.less';

const Accounts = ({ items }: AccountsProps) => {
  return (
    <div className={styles.list}>
      {items.map((item) => (
        <div key={item.id} className={styles.item}>
          {item.fullName && <div className={styles.fullName}>{item.fullName}</div>}
          {item.email && (
            <div className={styles.email}>
              <a href={`mailto:${item.email}`}>{item.email}</a>
            </div>
          )}
          <div>{item.isSeniorAccount ? 'Senior' : 'Partner'}</div>
        </div>
      ))}
    </div>
  );
};

export default Accounts;
