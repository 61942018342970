import cn from 'classnames';

import { IconProps } from './types';
import main from './assats/main.svg';
import styles from './Icon.module.less';

const Icon = ({ name, className, style }: IconProps) => {
  return (
    <svg className={cn(styles[name], className)} style={style}>
      <use xlinkHref={main + '#' + name} />
    </svg>
  );
};

export default Icon;
