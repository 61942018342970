export const sectionNames = [
  'accommodationAndCrew',
  'performance',
  'rentPrice',
  'dimensions',
  'tendersAndToys',
  'generalInformation',
  'amenities',
  'equipment',
];
