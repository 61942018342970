import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification } from '$ui/index';
import cn from 'classnames';

import styles from './NoticeGDPR.module.less';

const NoticeGDPR = () => {
  const { t } = useTranslation();

  const localStorageKey = 'notice-gdpr';
  const localStorageValueAccept = 'accept';
  const localStorageValueRejected = 'rejected';
  const notificationKey = 'notice-gdpr';

  const status = localStorage.getItem(localStorageKey);
  const [show, setShow] = useState<boolean>(!status);

  const handleAccept = () => {
    localStorage.setItem(localStorageKey, localStorageValueAccept);
    setShow(false);
    notification.close(notificationKey);
  };

  const handleRejected = () => {
    localStorage.setItem(localStorageKey, localStorageValueRejected);
  };

  useEffect(() => {
    if (show) {
      notification.open({
        message: t('frontend.gdpr_block.title'),
        key: notificationKey,
        placement: 'bottomRight',
        onClose: handleRejected,
        duration: 0,
        description: t('frontend.gdpr_block.body'),
        className: cn(styles.main, 'cy-NoticeGDPRComponent'),
        btn: (
          <Button type="primary" size="middle" onClick={handleAccept} data-cy="agreeButton">
            {t('frontend.gdpr_block.agree_button')}
          </Button>
        ),
      });
    }
  }, [show, notificationKey, t]);

  return null;
};

export default NoticeGDPR;
