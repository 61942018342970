import { makeVar, ReactiveVar } from '@apollo/client';

import { CurrentUser } from '$graphql/types';

const currentUser: CurrentUser = {
  id: undefined,
  email: '',
  isLoggedIn: false,
  accessAdmin: false,
  isCentralAgent: false,
  isAdmin: false,
  isPaidSubscription: false,
  isSeniorAccount: false,
  isPartnerAccount: false,
};

export const userVar: ReactiveVar<CurrentUser> = makeVar<CurrentUser>(currentUser);
