// https://developer.mozilla.org/ru/docs/Web/API/URLSearchParams
// Функция для разбора url query
type Result = {
  [key: string]: string | Array<string> | null;
};

export const parseUrlQuery = (query: string | undefined): Result | void => {
  if (!query) return;

  const params = new URLSearchParams(query);
  const result = {} as Result;

  params.forEach((_, key) => {
    result[key] = params.getAll(key).length > 1 ? params.getAll(key) : params.get(key);
  });

  return result;
};
