import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Button } from '$ui/index';
import { DownOutlined } from '@ant-design/icons';

import useGeneratePath from '$hooks/useGeneratePath';
import { supportedLanguages } from '../../../../i18n';

import { LanguageSelectorProps } from './types';
import sprite from './assets/sprite.svg';
import styles from './LanguageSelector.module.less';

const LanguageSelector = ({ className }: LanguageSelectorProps) => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { generatePath } = useGeneratePath();

  const changeAppLanguage = (language: string) => {
    const path = location.pathname;
    let newPath = '';

    if (path.length <= 3) {
      newPath = '';
    } else if (path.match(/^\/(\w{2})\//gi)) {
      newPath = path.slice(3);
    } else {
      newPath = path;
    }

    localStorage.setItem('lng', language);
    i18n.changeLanguage(language).then(() => {
      history.replace(generatePath('/:lng?' + newPath, { lng: language }) + location.search + location.hash);
    });
  };

  const menuLang = (
    <Menu>
      {supportedLanguages.map((language) => (
        <Menu.Item key={language} onClick={() => changeAppLanguage(language)} data-cy={`menuLanguage_${language}`}>
          <span>{language.toUpperCase()}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menuLang} trigger={['click']} className={className}>
      <Button type="link" className={styles.selectorLink} data-cy="LanguageSelectorComponent">
        <svg className={styles.flag}>
          <use xlinkHref={sprite + '#' + i18n.language} />
        </svg>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguageSelector;
