import { GraphQLError } from 'graphql/error/GraphQLError';

const formatValidations = (validation: GraphQLError['extensions']) => {
  return validation;
};

const formatExtensions = (extensions: GraphQLError['extensions']): GraphQLError['extensions'] => {
  if (extensions && extensions.validation) {
    return {
      ...extensions,
      validation: formatValidations(extensions.validation),
    };
  }

  if (extensions && extensions.message) {
    return {
      ...extensions,
      validation: {
        base: [extensions.message],
      },
    };
  }

  return extensions;
};

const formatErrors = (errors?: readonly GraphQLError[], path?: string): GraphQLError['extensions'] => {
  if (!errors) {
    return {};
  }

  if (path) {
    for (const e of errors) {
      if (e.path?.join('.') === path) {
        return formatExtensions(e.extensions);
      }
    }
  }

  const result: { [key: string]: GraphQLError['extensions'] } = {};

  for (const e of errors) {
    const _path = e.path?.join('.');
    if (_path && result[_path]) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-throw-literal
        throw `formatErrors ${_path} already`;
      }
      result[_path] = e.extensions;
    }
  }

  return result;
};

export { formatErrors };
