import { useDateTime } from '$hooks/index';

import { ParameterListProps } from './types';
import styles from './ParameterList.module.less';

const ParameterList = ({ offer, className }: ParameterListProps) => {
  const dateTime = useDateTime();

  const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;

  if (offer) {
    return (
      <div className={className}>
        <div className={styles.parameter}>
          <div className={styles.label}>Dates</div>
          <div className={styles.value}>
            {offer.startAt && offer.endAt && dateTime.magicShort(offer.startAt, offer.endAt)}
            {diffDays && ` / ${diffDays} days`}
          </div>
        </div>

        {offer.location && (
          <div className={styles.parameter}>
            <div className={styles.label}>Pick up location</div>
            <div className={styles.value}>
              {[offer.location?.address, offer.location?.country?.name].filter((i) => i).join(', ')}
            </div>
          </div>
        )}

        {offer.charter.guests && offer.charter.guests > 0 && (
          <div className={styles.parameter}>
            <div className={styles.label}>Number of guests</div>
            <div className={styles.value}>{offer.charter.guests}</div>
          </div>
        )}

        {offer.charter.captain && (
          <div className={styles.parameter}>
            <div className={styles.label}>Capitain</div>
            <div className={styles.value}>{offer.charter.captain ? 'Yes' : 'No'}</div>
          </div>
        )}

        {offer.charter.comment && (
          <div className={styles.parameter}>
            <div className={styles.label}>Comment</div>
            <div className={styles.value}>{offer.charter.comment}</div>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default ParameterList;
