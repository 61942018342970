import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Button } from '$ui/index';
import { DownOutlined } from '@ant-design/icons';

import { TUnit } from './types';
import styles from './UnitSelector.module.less';

const units: Array<TUnit> = [
  {
    name: 'frontend.header.unit.ft',
    value: 'ft',
  },
  {
    name: 'frontend.header.unit.m',
    value: 'm',
  },
];

const UnitSelector = () => {
  const { t } = useTranslation();
  const [currentUnit, setCurrentUnit] = useState<'ft' | 'm'>(localStorage.getItem('unit') === 'm' ? 'm' : 'ft');

  const changeCurrentUnit = (unit: 'ft' | 'm') => {
    localStorage.setItem('unit', unit);
    setCurrentUnit(unit);
  };

  const menuUnit = (
    <Menu>
      {units.map((unit) => (
        <Menu.Item key={unit.name} onClick={() => changeCurrentUnit(unit.value)}>
          <span>{t(unit.name)}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menuUnit} trigger={['click']}>
      <Button type="link" className={styles.selectorLink}>
        {t(units.find((unit) => unit.value === currentUnit)?.name || '')}
        <DownOutlined className="ml-2" />
      </Button>
    </Dropdown>
  );
};

export default UnitSelector;
