import { Spin } from '$ui/index';
import { LoadingOutlined } from '@ant-design/icons';

import styles from '../../UserProfile.module.less';

export const SkeletonUserProfile = () => {
  return (
    <div className={styles.spaceComponent}>
      <Spin indicator={<LoadingOutlined />} className={styles.icon} />
    </div>
  );
};

export default SkeletonUserProfile;
