import { paymentSystems } from './data';
import styles from './PaymentSystems.module.less';

const PaymentSystems = () => {
  return (
    <div className={styles.main}>
      {paymentSystems.map((paymentSystem) => (
        <svg key={paymentSystem.name} width={paymentSystem.width} className={styles.item}>
          <use xlinkHref={paymentSystem.icon} />
        </svg>
      ))}
    </div>
  );
};
export default PaymentSystems;
