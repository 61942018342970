import cn from 'classnames';

import { TimepickerProps } from './types';
import { Select } from '$ui/index';

import styles from './Timepicker.module.less';

const Timepicker = ({ value, onChange, label, theme }: TimepickerProps) => {
  const values = Array.from({ length: 25 }, (_, i) => {
    if (i === 0) {
      return { value: '00:00', label: 'Any' };
    } else if (i === 24) {
      return { value: '23:59', label: '23:59' };
    }

    const text = `${i <= 9 ? '0' + i : i}:00`;

    return { value: text, label: text };
  });

  return (
    <div
      className={cn(
        styles.main,
        theme === 'left' ? styles.themeLeft : undefined,
        theme === 'right' ? styles.themeRight : undefined
      )}
    >
      <div className={styles.label}>{label}</div>
      <Select
        defaultValue={value ? value : '00:00'}
        onChange={onChange}
        options={values}
        bordered={false}
        className={styles.select}
      />
    </div>
  );
};

export default Timepicker;
