import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { Helmet } from 'react-helmet-async';
import { Form, Input, message, Button, Layout } from '$ui/index';
import cn from 'classnames';

import { routes } from '$router/index';
import { GraphQL } from '$graphql/client';
import useSession from '$stores/session';
import { useSignInMutation } from '$graphql/hooks';
import { userVar } from '$graphql/local/vars';
import AbstractForm from '$components/abstract';
import FloatInputLabel from '$components/Forms/FloatInputLabel';

import { SignInProps } from './types';
import FormStyles from '$components/abstract/Form.module.less';
import styles from '$pages/SignUpPage/SignUpPage.module.less';
import useGeneratePath from '$hooks/useGeneratePath';

const SignInPage = ({ type, request, isModal }: SignInProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const { initSession, destroySession } = useSession();
  const [loading, setLoading] = useState(false);
  const [fetchSignIn] = useSignInMutation({ errorPolicy: 'all' });
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    destroySession();
  }, [destroySession]);

  const signIn = ({ email, password }: { email: string; password: string }) => {
    setLoading(true);

    fetchSignIn({ variables: { email, password } })
      .then(({ data, errors }) => {
        if (data?.signIn?.token) {
          initSession(data.signIn.token);

          if (!(data?.signIn.isCentralAgent || data?.signIn.isAdmin)) {
            sendDataToGTM({ event: 'submit_ga_signin_page_form' });
            message.success(t('frontend.sign_in.form.success'));
          }

          userVar({
            id: data.signIn.id,
            email,
            isLoggedIn: true,
            accessAdmin: false, // TODO 2023 в старом коде почему-то здесь false хотя напрашикаеться data.signIn.accessAdmin
            isAdmin: data.signIn.isAdmin,
            isCentralAgent: data.signIn.isCentralAgent,
            isPaidSubscription: !!data.signIn.isPaidSubscription,
            isSeniorAccount: !!data.signIn.isSeniorAccount,
            isPartnerAccount: !!data.signIn.isPartnerAccount,
          });

          if (type === 'request') {
            return request();
          } else if (data?.signIn.isCentralAgent || data?.signIn.isAdmin) {
            if (window.location.hostname === 'staging.getboat.com' || window.location.hostname === 'localhost') {
              window.location.href = 'https://staging.getboat.com/admin';
              return;
            }

            window.location.href = 'https://getboat.com/admin';
            return;
          }

          window.location.href = generatePath(routes.ROOT) !== '' ? generatePath(routes.ROOT) : '/';
          return;
        }

        const extensions: GraphQLError['extensions'] = GraphQL.formatErrors(errors, 'signIn');

        if (extensions && extensions.validation) {
          message.error(extensions.validation?.base);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Login page - getboat.com</title>
        <meta
          name="description"
          content="Sign in to GetBoat and access your account. Manage your boat rentals, bookings, and preferences conveniently."
        />
      </Helmet>

      <Layout style={type === 'request' ? { background: '#fff' } : { padding: '5% 0' }} data-cy="SignInPage">
        <AbstractForm
          onFinish={(form) => signIn(form as { email: string; password: string })}
          onFinishFailed={console.log}
          title={!isModal ? t('frontend.sign_in.title') : undefined}
          isModal={isModal}
          className="ga_signin_page_form"
        >
          <Form.Item shouldUpdate className={styles.wrapperInput}>
            {(form) => (
              <Form.Item
                name="email"
                rules={[{ required: true, message: t('frontend.sign_in.form.error_name') }]}
                data-cy="username"
              >
                <FloatInputLabel
                  label={t('frontend.sign_in.form.email.placeholder')}
                  isFloated={!!form.getFieldValue('email')}
                >
                  <Input
                    value={form.getFieldValue('email')}
                    onChange={(e) => form.setFieldsValue({ email: e.currentTarget.value })}
                    autoComplete="username"
                    autoCapitalize="none"
                    spellCheck="false"
                  />
                </FloatInputLabel>
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item shouldUpdate className={styles.wrapperInput}>
            {(form) => (
              <Form.Item
                name="password"
                rules={[{ required: true, message: t('frontend.sign_in.form.error_password') }]}
                data-cy="password"
              >
                <FloatInputLabel
                  label={t('frontend.sign_up.form.password.placeholder')}
                  isFloated={!!form.getFieldValue('password')}
                >
                  <Input.Password
                    value={form.getFieldValue('password')}
                    onChange={(e) => form.setFieldsValue({ password: e.currentTarget.value })}
                    autoComplete="current-password"
                    autoCapitalize="none"
                    spellCheck="false"
                  />
                </FloatInputLabel>
              </Form.Item>
            )}
          </Form.Item>
          {/*

          export const nonInputFormItem = {
            className: 'non-input-formitem',
            style: { marginBottom: 0, marginTop: 0 },
          };

          <Form.Item name="remember" valuePropName="checked" {...nonInputFormItem}>
            <Checkbox>{t('frontend.sign_in.form.remember')}</Checkbox>
          </Form.Item>
          */}
          <Form.Item style={{ marginTop: 16 }} data-cy="submit">
            <Button
              type="primary"
              disabled={loading}
              className={cn('btn', 'primary', FormStyles.btn, 'ga_signin_page_send_button')}
              htmlType="submit"
            >
              {t('frontend.sign_in.form.submit')}
            </Button>
          </Form.Item>

          {!isModal && (
            <>
              <p className={FormStyles.additional}>
                {t('frontend.sign_in.registration.label')}{' '}
                <Link to={generatePath(routes.SIGN_UP)}>{t('frontend.sign_in.registration.sign_up')}</Link>
              </p>
              <p className={FormStyles.additional}>
                <Link to={{ pathname: generatePath(routes.FORGOT_PASSWORD) }}>
                  {t('frontend.sign_up.login.forget_password')}
                </Link>
              </p>
            </>
          )}
        </AbstractForm>
      </Layout>
    </>
  );
};

export default SignInPage;
