import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge } from '$ui/index';
import cn from 'classnames';

import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { useDateTime } from '$hooks/index';

import { ListProps } from './types';
import styles from './List.module.less';

const List = ({ charters, isShowSource }: ListProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const dateTime = useDateTime();

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.id}>#</div>
        <div className={styles.createdAt}>{t('frontend.charter.created_at')}</div>
        <div className={styles.address}>{t('frontend.charter.address')}</div>
        <div className={styles.dateRange}>{t('frontend.charter.date_range')}</div>
      </div>
      <div className={styles.list}>
        {charters.map((charter) => (
          <Link
            to={generatePath(routes.CHARTER_VIEW, { charterId: `${charter.id}` })}
            key={charter.id}
            className={styles.item}
          >
            <div className={cn(styles.field, styles.id)}>
              <div className={styles.label}>#</div>
              <div className={styles.value}>{charter.id}</div>
            </div>
            <div className={cn(styles.field, styles.createdAt)}>
              <div className={styles.label}>{t('frontend.charter.created_at')}:</div>
              <div className={styles.value}>
                {charter.createdAt
                  ? dateTime.format(charter.createdAt, 'th', true)
                  : t('frontend.charter.date_not_present')}
              </div>
            </div>
            <div className={cn(styles.field, styles.address)}>
              <div className={styles.label}>{t('frontend.charter.address')}:</div>
              <div className={styles.value}>
                {charter.locations.map((l) => l?.location?.address || l.name).join(', ')}
              </div>
            </div>
            <div className={cn(styles.field, styles.dateRange)}>
              <div className={styles.label}>{t('frontend.charter.date_range')}:</div>
              <div className={styles.value}>
                {charter.startsAt && charter.endsAt && dateTime.textDateRange(charter.startsAt, charter.endsAt)}
              </div>
            </div>
            <div className={cn(styles.field, styles.offers)}>
              <div className={styles.value}>
                {t('frontend.charter.select_offers')}{' '}
                {charter.offers && charter?.offers?.length > 0 && (
                  <Badge count={charter.offers.length} className={styles.count} />
                )}
                {isShowSource && charter.createdBy && (
                  <>
                    {charter.createdBy === 'promo_code' ? (
                      <>
                        <div>Promo code:</div>
                        <div>{charter?.promoCode?.promoCode}</div>
                      </>
                    ) : (
                      <div>Source: {charter.createdBy?.replaceAll('_', ' ')}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default List;
