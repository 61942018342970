import { PictureSourceProps } from './PictureSourceProps';
import picturePlaceholder from '$assets/img/placeholder.svg';

const PictureSource = ({ x1, x2, x3, x4, alt, className }: PictureSourceProps) => {
  return (
    <picture className={className}>
      <source
        srcSet={[
          x1 ? `${x1} 1x` : undefined,
          x2 ? `${x2} 2x` : undefined,
          x3 ? `${x3} 3x` : undefined,
          x4 ? `${x4} 4x` : undefined,
        ].join(', ')}
      />
      <img src={x1 || picturePlaceholder} alt={alt} className={className} />
    </picture>
  );
};
export default PictureSource;
