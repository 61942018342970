import { useState } from 'react';
import cn from 'classnames';

import { InputNumberButtonProps } from '$components/Forms/InputNumberButton/types';
import { buttons } from './data';
import styles from './InputNumberButton.module.less';

const InputNumberButton = ({ value, onChange }: InputNumberButtonProps) => {
  const [hover, setHover] = useState<number | null>(null);

  if (typeof onChange !== 'function') return null;

  const handleChange = (item: number): void => {
    if (!value?.from && !value?.to) {
      // ничего не выбрано, задаём ОТ
      onChange({ from: item });
    } else if (value.from && !value.to) {
      // выбрано только ОТ
      if (item < value.from) {
        // поменять местами ОТ и ДО
        onChange({ from: item, to: value.from });
      } else {
        // задать ДО
        onChange({ from: value.from, to: item });
      }
    } else if (value.from && value.to) {
      // выбрано ОТ и ДО
      if (item < value.from) {
        // расширить выбор ОТ
        onChange({ from: item, to: value.to });
      } else if (item > value.to) {
        // расширить выбор ДО
        onChange({ from: value.from, to: item });
      } else {
        // если клик внутри выбранного промежутка, то отменяем выбор
        onChange(undefined);
      }
    }

    setHover(null);
  };

  const isSelect = (item: number): boolean => {
    return !!(item === value?.from || (value?.from && value?.to && item >= value.from && item <= value.to));
  };

  const isHover = (item: number): boolean => {
    return !!(
      hover &&
      value?.from &&
      !value?.to &&
      ((hover <= value.from && item >= hover && item <= value.from) ||
        (hover >= value.from && item >= value.from && item <= hover))
    );
  };

  return (
    <div className={styles.list}>
      {buttons.map((button) => (
        <div
          onClick={() => handleChange(button.value)}
          onMouseEnter={() => setHover(button.value)}
          onMouseLeave={() => setHover(null)}
          className={cn(styles.item, isHover(button.value) && styles.hover, isSelect(button.value) && styles.select)}
          key={button.value}
        >
          {button.label}
        </div>
      ))}
    </div>
  );
};

export default InputNumberButton;
