import { onError } from '@apollo/client/link/error';

const linkError = onError(({ graphQLErrors, networkError, operation }) => {
  const errorPolicy = operation.getContext()?.errorPolicy;
  const isIgnorePolicy = errorPolicy === 'IgnoreErrorsQuery';

  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const code = parseInt(error?.extensions?.code);
      if (code === 401 && !isIgnorePolicy) {
        window.location.href = '/sign-in';
      }
    }
  }

  if (networkError) {
    console.log(networkError);
  }
});

export { linkError };
