import { Rating } from '$components/index';

import { TotalProps } from './types';
import styles from './Total.module.less';

const Total = ({ average, customers }: TotalProps) => {
  // TODO 2022 toLocaleString - language changes(en|de|fr)

  return (
    <div className={styles.total}>
      <Rating className={styles.rating} value={5} size="large" />
      {average && <div className={styles.label}>{average.toFixed(2)} Avg. Rating</div>}
      {customers && (
        <div className={styles.customers}>&#8226; {customers.toLocaleString('en-US')} Customers Reviews</div>
      )}
    </div>
  );
};

export default Total;
