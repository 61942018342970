import { Avatar } from '$ui/index';

import { useDateTime } from '$hooks/index';
import { Rating } from '$components/index';

import { ReviewCardProps } from './types';

import styles from './ReviewCard.module.less';
import picturePlaceholder from '$assets/img/placeholder.svg';

const ReviewCard = ({ item }: ReviewCardProps) => {
  const dateTime = useDateTime();

  const photoBoat = item.boat.pictures?.find((i) => i)?.md;

  return (
    <div className={styles.review}>
      <div className={styles.card}>
        <div className={styles.boatPhoto}>
          <img src={photoBoat || picturePlaceholder} alt={item.boat.name ? item.boat.name : 'boat review'} />
        </div>
        <div className={styles.body}>
          <div className={styles.boatName}>{item.boat.name}</div>
          <Rating className={styles.rating} value={item.rating} size="small" />
          <div className={styles.divider} />
          <div className={styles.text}>{item.enContent}</div>
        </div>
        <div className={styles.arrow} />
      </div>
      <div className={styles.author}>
        <div className={styles.photo}>
          <Avatar size={40} className={styles.avatar}>
            {item.account.fullName?.charAt(0).toUpperCase()}
          </Avatar>
        </div>
        <div className={styles.text}>
          <div className={styles.name}>{item.account.fullName}</div>
          <div className={styles.date}>Rent {dateTime.format(item.createdAt, 'withoutDay')}</div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
