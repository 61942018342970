import img_m_320_x1_w_banner from './assets/mobile_320/x1/banner.webp';
import img_m_320_x2_w_banner from './assets/mobile_320/x2/banner.webp';
import img_m_320_x3_w_banner from './assets/mobile_320/x3/banner.webp';

import img_m_335_x1_w_banner from './assets/mobile_335/x1/banner.webp';
import img_m_335_x2_w_banner from './assets/mobile_335/x2/banner.webp';
import img_m_335_x3_w_banner from './assets/mobile_335/x3/banner.webp';

import img_t_x1_w_banner from './assets/table/x1/banner.webp';
import img_t_x2_w_banner from './assets/table/x2/banner.webp';
import img_t_x3_w_banner from './assets/table/x3/banner.webp';

import img_d_x1_w_banner from './assets/descktop/x1/banner.webp';
import img_d_x2_w_banner from './assets/descktop/x2/banner.webp';
import img_d_x3_w_banner from './assets/descktop/x3/banner.webp';

import img_d_x1_j_banner from './assets/descktop/x1/banner.jpg';

export const images = {
  banner: {
    alt: 'List your boat on GetBoat.com',
    img: {
      mobile_320: {
        x1: {
          webp: img_m_320_x1_w_banner,
        },
        x2: {
          webp: img_m_320_x2_w_banner,
        },
        x3: {
          webp: img_m_320_x3_w_banner,
        },
      },
      mobile_335: {
        x1: {
          webp: img_m_335_x1_w_banner,
        },
        x2: {
          webp: img_m_335_x2_w_banner,
        },
        x3: {
          webp: img_m_335_x3_w_banner,
        },
      },
      table: {
        x1: {
          webp: img_t_x1_w_banner,
        },
        x2: {
          webp: img_t_x2_w_banner,
        },
        x3: {
          webp: img_t_x3_w_banner,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_banner,
          jpeg: img_d_x1_j_banner,
        },
        x2: {
          webp: img_d_x2_w_banner,
        },
        x3: {
          webp: img_d_x3_w_banner,
        },
      },
    },
  },
};
