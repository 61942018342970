export const steps = [
  {
    key: 1,
    title: 'Make it work',
    icon: 'step1',
    description: "Sign up and upload your boat's information and high-quality photos on our website to start.",
  },
  {
    key: 2,
    title: 'Check the area',
    icon: 'step2',
    description: 'Get notifications about requests in your area and conduct research to stay ahead of the game.',
  },
  {
    key: 3,
    title: 'Offer the best',
    icon: 'step3',
    description:
      'Make appealing offers with the most favourable terms to attract more customers and provide them with the best service.',
  },
  {
    key: 4,
    title: 'We are one team',
    icon: 'step4',
    description:
      'GetBoat.com will provide a steady flow of clients and take care of promotion of your boats, allowing you to focus on your rental business!',
  },
];
