import { Locations } from '$components/Forms';

import { FormTypes } from './types';
import styles from './Form.module.less';

const Form = ({ locationName, handleSearchLocation, handleSelectLocation, handleBlurLocation, items }: FormTypes) => {
  return (
    <div className={styles.form}>
      <Locations
        value={locationName}
        onChange={handleSearchLocation}
        onSelect={handleSelectLocation}
        onBlur={handleBlurLocation}
        items={items}
        placeholder="Location"
        config={{ virtual: false }}
        className={styles.locations}
      />
    </div>
  );
};

export default Form;
