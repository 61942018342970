import { Modal, Switch } from '$ui/index';
import { useChangeUserInterestMutation } from '$graphql/hooks';
import { useState } from 'react';
import { SelectorInterestedUserProps } from './types';

const SelectorInterestedUser = ({ offerId, value }: SelectorInterestedUserProps) => {
  const [changeUserInterest] = useChangeUserInterestMutation({ errorPolicy: 'all' });
  const [isLoadingInterested, setIsLoadingInterested] = useState(false);

  const handleInterested = (offerId: number, value: boolean) => {
    setIsLoadingInterested(true);

    changeUserInterest({ variables: { offerId: `${offerId}`, value } }).then((result) => {
      if (result.data?.changeUserInterest?.value) {
        Modal.success({
          title: 'Accepted!',
          content:
            'We appreciate your interest in our services and thank you for your patience. ' +
            'Our manager has already received your request and is currently working on it. ' +
            'We will contact you shortly to confirm your reservation and discuss all the details. ' +
            'Please stay connected, as your satisfaction is our top priority.',
        });
      }

      setIsLoadingInterested(false);
    });
  };

  return (
    <div style={{ width: '180px' }}>
      <Switch
        onClick={() => {
          handleInterested(offerId, !value);
        }}
        checked={!!value}
        loading={isLoadingInterested}
      />{' '}
      I`m interested
    </div>
  );
};

export default SelectorInterestedUser;
