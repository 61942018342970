import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';

import styles from './Logo.module.less';
import iconLogo from '$assets/img/logo.svg';
import iconLogoWlPpl from '$assets/img/wl/ppl/logo.svg';

const Logo = () => {
  const { generatePath } = useGeneratePath();

  return (
    <Link to={generatePath(routes.ROOT)} data-cy="LogoComponent">
      {process.env.REACT_APP_SITE_ID === '2' ? (
        <ReactSVG src={iconLogoWlPpl} className={styles.main} />
      ) : (
        <ReactSVG src={iconLogo} className={styles.main} />
      )}
    </Link>
  );
};

export default Logo;
