import { useTranslation } from 'react-i18next';

import { AppGooglemap } from '$components/index';

import pictureStaticMap from '$assets/img/staticmap.png';

import { MapProps } from './types';
import styles from './Map.module.less';

const Map = ({ position, zoom, isCircle }: MapProps) => {
  const { i18n } = useTranslation();

  return (
    <div className={styles.map}>
      {position ? (
        <AppGooglemap
          markerPosition={position}
          height={300 + 'px'}
          language={i18n.language}
          zoom={zoom}
          circle={isCircle}
        />
      ) : (
        <img src={pictureStaticMap} className={styles.staticMap} alt="static world map" />
      )}
    </div>
  );
};

export default Map;
