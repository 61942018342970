import { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const UserAgentGtm = () => {
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    sendDataToGTM({
      event: 'userInfo',
      userAgent: navigator.userAgent,
    });
  }, [sendDataToGTM]);

  return null;
};

export default UserAgentGtm;
