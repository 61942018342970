import { useState } from 'react';

import InputNumberButton from '$components/Forms/InputNumberButton';
import Tabs from '$components/Tabs';
import { TTab } from '$components/Tabs/types';

import commonStyles from '$assets/styles/Common.module.less';

const TestPage = () => {
  const [hours, setHours] = useState<{ from?: number; to?: number }>();

  const labels: Array<TTab> = [
    {
      key: 'perDay',
      name: 'Per day',
    },
    {
      key: 'perHour',
      name: 'Per hour',
    },
  ];

  const [selectedTab, setSelectedTab] = useState<string | null | undefined>(labels[0].key);

  return (
    <div className={commonStyles.container}>
      <h1>Test Page</h1>
      <Tabs items={labels} selected={selectedTab} changeSelected={setSelectedTab} />
      <InputNumberButton value={hours} onChange={(value) => setHours(value)} />
    </div>
  );
};

export default TestPage;
