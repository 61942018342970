import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import cn from 'classnames';

import { AppGooglemap, BoatIcons, Breadcrumb, Carousel, Spoiler } from '$components/index';
import { Location, Rating } from '$components/Boat';

import { BoatPageViewProps } from './types';
import {
  AdditionalInfo,
  CancellationPolicy,
  FormRent,
  FormSale,
  PriceRent,
  PriceSale,
  Products,
  Title,
} from './components';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './BoatPageView.module.less';

const BoatPageView = ({ boat, boatLoading, seo, breadcrumbItems, markerPosition, isBuy }: BoatPageViewProps) => {
  return (
    <div className={cn(commonStyles.container, styles.container)}>
      {seo?.title && (
        <Helmet>
          <title>{seo.title}</title>
        </Helmet>
      )}

      <div className={styles.breadcrumb}>
        <Breadcrumb items={breadcrumbItems?.filter((i) => i.title)} isSkeleton={boatLoading} />
      </div>

      <div className={styles.main}>
        <div className={styles.left}>
          <Carousel
            images={boat?.pictures && boat.pictures.length > 0 ? boat.pictures : []}
            isSkeleton={boatLoading}
            className={styles.photos}
          />
          <BoatIcons boat={boat} isSkeleton={boatLoading} className={styles.icons} />
          <Title name={boat?.name} year={boat?.builtAt} isSkeleton={boatLoading} className={styles.title} />
          <Rating rating={boat?.rating} isSkeleton={boatLoading} className={styles.rating} />
          <Location
            address={boat?.location?.address}
            country={boat?.location?.country?.name}
            className={styles.location}
            isSkeleton={boatLoading}
          />

          {boat?.description && (
            <Spoiler suffixId="description">
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: boat.description }} />
            </Spoiler>
          )}
          <AdditionalInfo additionalInfo={boat?.boatAdditionalInfo} />
          {boat?.boatProducts && boat.boatProducts.length > 0 && <Products items={boat.boatProducts} />}
          {boat && markerPosition && (
            <div>
              <AppGooglemap height="400px" markerPosition={markerPosition} circle skeleton />
            </div>
          )}
          {!!boat?.cancellationPolicy && <CancellationPolicy mainText={boat?.cancellationPolicy} />}
        </div>
        <div className={styles.right}>
          {isBuy ? (
            <>
              <PriceSale price={boat?.salePrice?.value} currency={boat?.salePrice?.currency} className={styles.price} />
              <FormSale boat={boat} />
            </>
          ) : (
            <>
              <PriceRent
                pricePerHour={boat?.exclusivePrice?.pricePerHour}
                pricePerDay={boat?.exclusivePrice?.pricePerDay}
                pricePerWeek={boat?.exclusivePrice?.pricePerWeek}
                currency={boat?.exclusivePrice?.currency}
                isSkeleton={boatLoading}
                className={styles.price}
              />
              <FormRent boatId={boat?.id} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(BoatPageView, (oldProps, newProps) => {
  return oldProps.boat?.id === newProps.boat?.id && oldProps.boatLoading === newProps.boatLoading;
});
