import { Button } from '$ui/index';

import { formatCurrency } from '$utils/formatCurrency';

import { FormPlatronProps } from './types';
import styles from './FormPlatron.module.less';

const FormPlatron = ({ status, price, currency, redirectUrl }: FormPlatronProps) => {
  switch (status) {
    case 'loading':
      return (
        <div className={styles.main}>
          <Button type="primary" size="large" shape="round" loading className={styles.buttonSubmit}>
            Pay {formatCurrency(price, currency)} in rubles
          </Button>
        </div>
      );

    case 'loaded':
      return (
        <div className={styles.main}>
          <a href={redirectUrl || ''}>
            <Button type="primary" size="large" shape="round" className={styles.buttonSubmit}>
              Pay {formatCurrency(price, currency)} in rubles
            </Button>
          </a>
        </div>
      );

    case 'error':
      return (
        <div className={styles.main}>
          An error has occurred, please feel free to contact us on
          <a href="mailto:info@getboat.com" target="_blank" rel="noreferrer" style={{ paddingLeft: '6px' }}>
            info@getboat.com
          </a>
          .
        </div>
      );

    case 'none':
    default:
      return <></>;
  }
};

export default FormPlatron;
