import categoriesBoats from './categoriesBoats';
import countries from './countries';
import reviews from './reviews';
import articles from './articles';
import defaultFaqs from './defaultFaqs';

const staticData = {
  categoriesBoats,
  countries,
  reviews,
  articles,
  defaultFaqs,
};

export default staticData;
