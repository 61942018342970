import { Link } from 'react-router-dom';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { formatCurrency } from '$utils/formatCurrency';
import { BoatIcons, Icon } from '$components/index';

import { BoatProps } from './types';
import styles from './Boat.module.less';
import picturePlaceholder from '$assets/img/placeholder.svg';
import { useDateTime } from '$hooks/index';

const Boat = ({ offer }: BoatProps) => {
  const { generatePath } = useGeneratePath();
  const dateTime = useDateTime();

  const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;
  const boat = offer && offer.boat;

  if (offer && boat) {
    return (
      <div className={styles.main}>
        <div className={styles.photo}>
          {boat.pictures[0]?.md && (
            <Link
              to={{
                pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                  boatIdOrSlug: offer.boat.slug ? offer.boat.slug : offer.boat.id,
                  offerId: `${offer.id}`,
                }),
              }}
            >
              <img src={boat.pictures[0]?.md || picturePlaceholder} alt={boat.name ? boat.name : 'boat'} />
            </Link>
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <Link
              to={{
                pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                  boatIdOrSlug: offer?.boat.slug ? String(offer.boat.slug) : offer?.boat.id,
                  offerId: `${offer?.id}`,
                }),
              }}
            >
              {[boat.name, boat.builtAt].filter((i) => i).join(', ')}
            </Link>
          </div>
          <div className={styles.rating}>
            {boat.rating ? boat.rating.toFixed(1) : '5.0'} <Icon name="rating" className={styles.icon} />
          </div>

          <div className={styles.wrapperIcons}>
            <BoatIcons boat={boat} size="low" />
          </div>
          <div className={styles.price}>
            <div className={styles.all}>{formatCurrency(offer.price?.amount, offer.price?.currency)}</div>
            {offer.price?.amount && !!diffDays && diffDays > 0 && (
              <div className={styles.pricePerDay}>
                {formatCurrency(Math.ceil(offer.price.amount / diffDays), offer.price.currency)} per day
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default Boat;
