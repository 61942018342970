import { ReactElement, useState } from 'react';
import { Button, Input } from '$ui/index';
import { SendOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { useCheckPromoCodeLazyQuery } from '$graphql/hooks';
import { FloatInputLabel } from '$components/Forms';

import { PromoCodeProps, TStatus } from './types';
import styles from './PromoCode.module.less';

const PromoCode = ({ value, onChange, onResult }: PromoCodeProps) => {
  const [getCheckPromoCode, { loading }] = useCheckPromoCodeLazyQuery();
  const [status, setStatus] = useState<TStatus>('default');

  const handleCheck = (code?: string): void => {
    getCheckPromoCode({ variables: { promoCode: code } }).then((query) => {
      if (query.data?.checkPromoCode.status === 'active') {
        setStatus('active');
      } else {
        setStatus('none');
      }

      onResult(query.data?.checkPromoCode ?? { status: 'none' });
    });
  };

  const getIcon = (status: TStatus): ReactElement => {
    if (status === 'active') {
      return <CheckOutlined />;
    } else if (status === 'none') {
      return <CloseOutlined />;
    }

    return <SendOutlined />;
  };

  return (
    <div className={cn(styles.layout)}>
      <div className={cn(styles.form)}>
        <div className={styles.field}>
          <FloatInputLabel label="Promo code" isFloated={!!value && value?.length > 0}>
            <Input
              value={value}
              onChange={(e) => {
                setStatus('default');
                onResult({ status: 'default' });
                onChange && onChange(e.currentTarget.value);
              }}
              data-e2e="promo-code-input"
              className={styles.input}
            />
          </FloatInputLabel>
        </div>
        <div className={cn(styles.button)}>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => handleCheck(value)}
            icon={getIcon(status)}
            loading={loading}
            data-e2e="promo-code-submit"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
