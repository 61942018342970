import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Result } from '$ui/index';
import cn from 'classnames';

import { useSendPasswordResetEmailLazyQuery } from '$graphql/hooks';

import commonStyles from '$assets/styles/Common.module.less';
import styles from './ForgotPasswordPage.module.less';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailSend, setEmailSend] = useState(false);
  const [sendPasswordResetEmail, { data, loading }] = useSendPasswordResetEmailLazyQuery();

  useEffect(() => {
    if (!data) return;

    setEmailSend(true);
  }, [data]);

  const sendEmail = () => {
    if (!email) return;

    sendPasswordResetEmail({
      variables: { email },
    });
  };

  if (emailSend) {
    return (
      <Result
        status="success"
        title={t('frontend.password_forget_page.lettersent_title')}
        subTitle={t('frontend.password_forget_page.lettersent_extrainfo')}
      />
    );
  }

  return (
    <div className={cn(commonStyles.container, styles.main)}>
      <div className={styles.title}>{t('frontend.password_forget_page.title')}</div>
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onPressEnter={sendEmail}
        autoFocus
        placeholder={t('frontend.password_forget_page.email_field')}
      />
      <Button loading={loading} type="primary" onClick={sendEmail} shape="round" size="large" block>
        {t('frontend.password_forget_page.request_password_button')}
      </Button>
    </div>
  );
};

export default ForgotPasswordPage;
