import { useState, Fragment, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import MediaQuery from 'react-responsive';
import { Card, Collapse, Form, Divider, Checkbox } from '$ui/index';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { useLocationSuggestionsLazyQuery, useSearchBoatCategoriesQuery } from '$graphql/hooks';
import { Locations } from '$components/Forms';
import { ManualSkeleton, PictureSource } from '$components/index';

import { labelCondition } from './data';
import { FilterSidebarProps } from './types';
import styles from './FilterSidebar.module.less';

const FilterSidebar = ({
  locationName,
  setLocationName,
  showCondition,
  condition,
  setCondition,
  boatCategories = [],
  setBoatCategories,
  isBuy,
  isDisabledLocations,
}: FilterSidebarProps) => {
  const { t } = useTranslation();
  const numberCategoriesSkeleton = 5;

  const [categoriesLocation, setCategoriesLocation] = useState('');

  const [getLocationSuggestions, { data: locations }] = useLocationSuggestionsLazyQuery();
  const { data: categoriesData, loading: loadingBoat } = useSearchBoatCategoriesQuery({
    variables: { location: categoriesLocation },
  });

  const { Panel } = Collapse;

  // TODO 2021 выдедить фильтр Condition в отдельный подкомпонент
  const getCheckedCondition = (value: number): boolean => {
    switch (condition) {
      case 1:
        return value === 1;
      case 2:
        return value === 2;
      case 3:
        return value === 1 || value === 2;
      default:
        return false;
    }
  };

  const handleCheckedCondition = (values: Array<CheckboxValueType>) => {
    if (values.length === 1) {
      setCondition(values[0] as number);
    } else if (values.includes(1) && values.includes(2)) {
      setCondition(3);
    } else {
      setCondition(undefined);
    }
  };

  function handleChangeLocations(name: string) {
    // setLocation(name);
    getLocationSuggestions({ variables: { name } });
  }

  const handleSelectLocations = (location: string) => {
    setCategoriesLocation(location);
    setLocationName(location);
  };

  const handleBlurLocations = (e: FocusEvent<HTMLInputElement>) => {
    const location = (e.target as HTMLInputElement).value;
    setCategoriesLocation(location);
    setLocationName(location);
  };

  /*
  Если есть картегории то:
  Book a _Категория_ charter in _Город_, _Страна_ in 2022, 2023

  Если нет категории то:
  Rent a yacht in _Город_, _Страна_ in 2022, 2023

  Для марин (base) сделай пока как для городов. Но если там можно получать к base город - его бы тоже вывести.
  */

  let seo: { title?: string } | undefined;

  if (!isBuy && (locationName || boatCategories.length > 0)) {
    seo = {
      title: [
        boatCategories.length > 0 ? 'Book a' : 'Rent a yacht',
        categoriesData &&
          categoriesData.searchBoatCategories
            .filter((category) => boatCategories.includes(category.id))
            .map((category) => category.name)
            .join(', '),
        boatCategories.length > 0 && 'charter',
        locationName && 'in ' + locationName,
        'in 2022, 2023',
      ]
        .filter((i) => i)
        .join(' '),
    };
  }

  const Filters = () => {
    return (
      <div className={styles.filters}>
        <Form.Item label={t('frontend.request_page.boat_types')} noStyle name="boatCategories">
          <Checkbox.Group
            onChange={(values) => setBoatCategories(values as Array<string>)}
            className={styles.wrapperCategory}
          >
            {categoriesData?.searchBoatCategories?.map((category, index) => (
              <Fragment key={category.id}>
                <div className={styles.category}>
                  <Checkbox id={category.id} value={category.id} />
                  <label htmlFor={category.id} className={styles.categoryLabel}>
                    {category.picture && (
                      <PictureSource
                        x1={category.picture.w85}
                        x2={category.picture.w170}
                        x3={category.picture.w255}
                        x4={category.picture.w340}
                        alt={'category ' + category.name}
                        className={styles.icon}
                      />
                    )}
                  </label>
                  <label htmlFor={category.id} className={styles.categoryLabel}>
                    {category.name}
                  </label>
                </div>

                {index + 1 !== categoriesData?.searchBoatCategories.length && <Divider className={styles.divider} />}
              </Fragment>
            ))}
          </Checkbox.Group>
        </Form.Item>

        {showCondition && (
          <>
            <Divider className={styles.divider} />
            <Form.Item noStyle name="condition">
              <div className={styles.wrapperCondition}>
                <div className={styles.title}>Condition:</div>
                <Checkbox.Group onChange={handleCheckedCondition}>
                  {labelCondition.map((label) => (
                    <div key={label.value} className={styles.condition}>
                      <Checkbox value={label.value} checked={getCheckedCondition(label.value)}>
                        {label.name}
                      </Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            </Form.Item>
          </>
        )}
      </div>
    );
  };

  return (
    <Card className={styles.wrapper}>
      {seo?.title && (
        <Helmet>
          <title>{seo.title}</title>
        </Helmet>
      )}
      {categoriesData && !loadingBoat ? (
        <Form
          layout="vertical"
          initialValues={{
            boatCategories,
          }}
        >
          {!isDisabledLocations && (
            <Form.Item>
              <Locations
                value={locationName}
                onChange={handleChangeLocations}
                onSelect={handleSelectLocations}
                onBlur={handleBlurLocations}
                items={locations?.locationSuggestions}
                placeholder={t('frontend.banner.location.placeholder')}
                config={{ virtual: false }}
                className={styles.locations}
              />
            </Form.Item>
          )}

          {/* <Form.Item label={<Text strong>Boat length</Text>}>
            <Slider
              defaultValue={[boatLength?.from as number, boatLength?.to as number]}
              step={1}
              min={0}
              max={1000}
              onAfterChange={(values) => setBoatLength({ from: values[0], to: values[1] })}
              range
              included
            />
          </Form.Item> */}

          {/* <Form.Item label={<Text strong>Built At</Text>}>
            <Slider
              defaultValue={[builtAt?.from as number, builtAt?.to as number]}
              step={1}
              min={1950}
              max={new Date().getFullYear()}
              onAfterChange={(values) => setBuiltAt({ from: values[0], to: values[1] })}
              range
              included
            />
          </Form.Item> */}

          <MediaQuery maxWidth={767}>
            <Collapse ghost defaultActiveKey={boatCategories?.length > 0 || condition ? ['1'] : undefined}>
              <Panel header="Filter" key="1">
                <Filters />
              </Panel>
            </Collapse>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <Filters />
          </MediaQuery>
        </Form>
      ) : (
        <div>
          <div>
            <ManualSkeleton className={styles.skeletonLocation} />
          </div>
          <div>
            {Array.from({ length: numberCategoriesSkeleton }, (_, i) => (
              <Fragment key={i}>
                <ManualSkeleton className={styles.skeletonCategory} />
                {i + 1 < numberCategoriesSkeleton && <Divider className={styles.divider} />}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
};

export default FilterSidebar;
