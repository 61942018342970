import { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { useCurrentUserLocalQuery } from '$graphql/hooks';

const UserGtm = () => {
  const sendDataToGTM = useGTMDispatch();
  const { data: currentUser } = useCurrentUserLocalQuery();

  useEffect(() => {
    if (currentUser?.currentUser.id) {
      sendDataToGTM({ user_id: currentUser?.currentUser.id });
    }
  }, [currentUser?.currentUser.id, sendDataToGTM]);

  return null;
};

export default UserGtm;
