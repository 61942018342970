import { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import cn from 'classnames';
import { Button, Form, Checkbox, Input, Popover, message, notification } from '$ui/index';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { useAddChareterMutation } from '$graphql/hooks';
import { useUserParametersFromUrl, useStoringFormFields, useUtmTags, useDateTime } from '$hooks/index';
import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import useSession from '$stores/session';
import { AuthModal } from '$components/index';
import { Datepicker, FloatInputLabel } from '$components/Forms';
import LinkUserAgreement from '$components/LinkUserAgreement';
import { FormArgs } from '$pages/RequestPage/types';

import { FormRentProps } from './types';
import styles from './FormRent.module.less';

const FormRent = ({ boatId }: FormRentProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const history = useHistory();
  const { session } = useSession();
  const { getUserParameters } = useUserParametersFromUrl();
  const [fetchAddCharter] = useAddChareterMutation();
  const utmTags = useUtmTags().get();
  const sendDataToGTM = useGTMDispatch();
  const dateTime = useDateTime();

  const [formData, setFormData] = useState<FormArgs>();
  const { getFieldsValues, setFieldsValues } = useStoringFormFields();
  const storingValues = getFieldsValues();

  const initialValues = {
    /*
    // TODO 2021 Временно не применяем
    startsEndsAt: {
      startsAt: storingValues?.startDate ? new Date(storingValues.startDate) : undefined,
      endsAt: storingValues?.endDate ? new Date(storingValues.endDate) : undefined,
    },
     */
    startsEndsAt: {
      startsAt: undefined,
      endsAt: undefined,
    },
    flexibleDates: Boolean(storingValues?.flexibleDates),
    guests: storingValues?.guests ? Number(storingValues?.guests) : '',
    captain: Boolean(storingValues?.captain !== undefined ? storingValues?.captain : true),
    comment: storingValues?.comment,
  };

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [sendingData, setSendingData] = useState<boolean>(false);
  const [isShowPopoverCaptain, setIsShowPopoverCaptain] = useState(false);

  const [formOriginal] = Form.useForm<FormArgs>();

  const handleChange = (_?: FormEvent<HTMLFormElement>) => {
    const dataForm = formOriginal.getFieldsValue();
    const values = {
      /*
      // TODO 2021 Временно не применяем
      startDate: dataForm?.startsEndsAt?.startsAt && format(new Date(dataForm.startsEndsAt.startsAt), 'yyyy-MM-dd'),
      endDate: dataForm?.startsEndsAt?.endsAt && format(new Date(dataForm.startsEndsAt.endsAt), 'yyyy-MM-dd'),
      */
      guests: dataForm?.guests && Number(dataForm.guests) > 0 ? Number(dataForm.guests) : 0,
      flexibleDates: Boolean(dataForm?.flexibleDates),
      captain: Boolean(dataForm?.captain),
      comment: dataForm?.comment ? dataForm.comment : undefined,
    };

    setFieldsValues(values);
  };

  const handleSubmit = (values: FormArgs) => {
    setFormData(values);

    if (!session) {
      setVisibleModal(true);
      return;
    }

    requestCharter(values);
  };

  const requestCharter = async (values?: FormArgs) => {
    if (values && !sendingData) {
      setSendingData(true);

      try {
        const { data, errors } = await fetchAddCharter({
          variables: {
            boatId: boatId,
            startsAt: values.startsEndsAt?.startsAt
              ? dateTime.format(values.startsEndsAt.startsAt.toISOString(), 'output')
              : '',
            endsAt: values.startsEndsAt?.endsAt
              ? dateTime.format(values.startsEndsAt.endsAt.toISOString(), 'output')
              : '',
            guests: values.guests && values.guests > 0 ? values.guests : undefined,
            captain: values.captain,
            flexibleDates: values.flexibleDates,
            comment: values.comment,
            ...utmTags,
            ...getUserParameters(),
          },
        });

        const charterId = data?.addCharter?.id;

        if (!errors && charterId) {
          sendDataToGTM({ event: 'submit_ga_boat_rent_page_form', value: charterId });

          notification.success({
            message: t('frontend.boat_page.success_form'),
            key: 'request_form',
            placement: 'bottomRight',
            duration: 5,
          });

          history.push(generatePath(routes.CHARTER_VIEW, { charterId: `${charterId}` }));
        } else {
          message.error(t('frontend.boat_page.error_form'));
        }
      } catch (err) {
        message.error('This boat is busy for these dates');
      }
    }
  };

  return (
    <>
      <Form
        form={formOriginal}
        onFinish={handleSubmit}
        onChange={handleChange}
        initialValues={initialValues}
        className={cn(styles.form, 'ga_boat_rent_page_form')}
      >
        <Form.Item name="startsEndsAt" noStyle>
          <Datepicker placeholder="Select dates" size="small" />
        </Form.Item>

        <Form.Item name="flexibleDates" valuePropName="checked">
          <Checkbox>{t('frontend.request_page.flexible')}</Checkbox>
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {(form) => (
            <Form.Item name="guests">
              <FloatInputLabel
                label={t('frontend.request_page.guests')}
                isFloated={form.getFieldValue('guests') > 0}
                size="small"
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="off"
                  value={form.getFieldValue('guests')}
                  onChange={(e) => {
                    const n = Math.round(Math.abs(Number(e.currentTarget.value)));
                    form.setFieldsValue({ guests: n > 0 ? n : undefined });
                  }}
                />
              </FloatInputLabel>
            </Form.Item>
          )}
        </Form.Item>

        <div className={styles.captain}>
          <Form.Item name="captain" valuePropName="checked" noStyle>
            <Checkbox onChange={(e) => setIsShowPopoverCaptain(!e.target.checked)}>Captain</Checkbox>
          </Form.Item>
          <Popover
            content={<div style={{ maxWidth: '240px' }}>{t('frontend.request_page.captain_text')}</div>}
            trigger="click"
            onVisibleChange={setIsShowPopoverCaptain}
            visible={isShowPopoverCaptain}
          >
            <QuestionCircleOutlined style={{ fontSize: '16px' }} />
          </Popover>
        </div>

        <Form.Item shouldUpdate>
          {(form) => (
            <Form.Item name="comment">
              <FloatInputLabel
                label={t('frontend.request_page.comments')}
                isFloated={form.getFieldValue('comment')?.length > 0}
                labelFloatedStyles={{
                  backgroundColor: '#FFFFFF',
                  padding: '14px 6px 6px 25px',
                  top: '1px',
                  left: '1px',
                  width: 'calc(100% - 22px)',
                }}
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={200}
                  showCount
                  value={form.getFieldValue('comment')}
                  onChange={(e) => form.setFieldsValue({ comment: e.currentTarget.value })}
                />
              </FloatInputLabel>
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item noStyle>
          <Button
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
            className="ga_boat_rent_page_send_button"
            block
          >
            {t('frontend.boat_page.request')}
          </Button>
        </Form.Item>
        <LinkUserAgreement clickText={t('frontend.boat_page.request')} className={styles.linkUserAgreement} />
      </Form>

      <AuthModal
        visible={visibleModal}
        handleChangeVisible={setVisibleModal}
        request={() => {
          requestCharter(formData).then(() => {
            setVisibleModal(false);
          });
        }}
      />
    </>
  );
};

export default FormRent;
