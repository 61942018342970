import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Spin } from '$ui/index';
import { AuditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { useChartersLazyQuery, useCurrentUserLocalQuery } from '$graphql/hooks';
import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { CharterTypeOrigin } from '$graphql/types';
import { Tabs } from '$components/index';

import List from './components/List';

import { partnerTabs } from './data';
import { TCharterSource } from './type';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './CharterListPage.module.less';

const CharterListPage = () => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const { data: currentUser } = useCurrentUserLocalQuery();
  const [getCharters, { data, loading, error }] = useChartersLazyQuery();
  const [selectedTab, setSelectedTab] = useState<TCharterSource | string | null | undefined>(partnerTabs[0].key);

  useEffect(() => {
    getCharters({ variables: { typeOrigin: selectedTab as CharterTypeOrigin | null | undefined } });
  }, [selectedTab, getCharters]);

  return (
    <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.main)}>
      <div className={styles.title}>{t('frontend.charter.cabinet')}</div>
      <div className={styles.navigation}>
        {currentUser?.currentUser.isPartnerAccount && (
          <Button className={styles.partnership} type="primary" shape="round" size="large">
            <Link to={generatePath(routes.PARTNERSHIP)}>
              <AuditOutlined /> Partnership
            </Link>
          </Button>
        )}
        <Button type="primary" shape="round" size="large">
          <Link to={generatePath(routes.REQUEST_OFFERS)}>
            <PlusOutlined /> {t('frontend.charter.new_request')}
          </Link>
        </Button>
      </div>
      {currentUser?.currentUser.isPartnerAccount && (
        <Tabs
          items={
            currentUser?.currentUser?.isSeniorAccount
              ? partnerTabs
              : partnerTabs.filter((i) => i.key !== TCharterSource.Partner)
          }
          selected={selectedTab}
          changeSelected={setSelectedTab}
        />
      )}
      {data && !loading && !error && (
        <List
          charters={data.charters}
          isShowSource={currentUser?.currentUser.isPartnerAccount && selectedTab === 'all'}
        />
      )}
      {loading && currentUser?.currentUser.isPartnerAccount && (
        <Spin style={{ marginTop: '60px' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      )}
      {error && <div>Error server</div>}
    </div>
  );
};

export default CharterListPage;
