import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';

import useGeneratePath from '$hooks/useGeneratePath';
import { useGetOfferByIdQuery } from '$graphql/hooks';
import { routes } from '$router/index';
import { BookingViewParams } from '$router/types';
import { IconAccount } from '$components/index';

import { Boat, Agent, ParameterList, Payment } from './components/index';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './BookingPage.module.less';

const BookingPage = () => {
  const { generatePath } = useGeneratePath();
  const params = useParams<BookingViewParams>();

  const { data: offerData } = useGetOfferByIdQuery({ variables: { id: Number(params.offerId) } });

  const offer = offerData?.offer;

  if (offer) {
    return (
      <div className={cn(commonStyles.container, commonStyles.containerHigh)}>
        <div className={styles.back}>
          <Link
            to={generatePath(routes.CHARTER_VIEW, {
              charterId: `${offer.charter.id}`,
            })}
            className={styles.link}
          >
            <IconAccount name="arrowLeft" className={styles.icon} /> Booking #{offer.id}
          </Link>
        </div>

        <div className={styles.layout}>
          <div className={styles.layoutLeft}>
            <Boat offer={offer} className={styles.boat} />
            <ParameterList offer={offer} className={styles.parameterList} />
          </div>
          <div className={styles.layoutRight}>
            <Payment offer={offer} />
            <Agent agent={offer.centralAgent} className={styles.agent} />

            {/*
            <div className={styles.calendars}>
              <div className={styles.google}>
                <IconGoogle /><span>Add event to Google calendar</span>
              </div>
              <div className={styles.apple}>
                <a href='data:application/octet-stream;base64,dGVzdA=='>
                  <IconApple /><span>Add event to Apple calendar</span>
                </a>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default BookingPage;
