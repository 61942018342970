import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, message } from '$ui/index';
import cn from 'classnames';

import { useGetOfferByIdQuery, useAddStripePaymentMutation } from '$graphql/hooks';
import { GraphQL } from '$graphql/client';
import { CharterOffersSubscription, GetOfferByIdQuery, OffersQuery, OfferStatus } from '$graphql/types';
import { routes } from '$router/index';
import { BoatOfferViewParams } from '$router/types';
import { useDateTime } from '$hooks/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { formatCurrency } from '$utils/formatCurrency';
import { AppGooglemap, BoatIcons, Carousel, Spoiler } from '$components/index';
import { Location, Rating } from '$components/Boat';
import { AdditionalInfo, CancellationPolicy, Products, Title } from '$pages/BoatPage/components';

import commonStyles from '$assets/styles/Common.module.less';
import styles from '$pages/BoatPage/BoatPageView.module.less';

const OfferBoatPage = () => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const dateTime = useDateTime();
  const history = useHistory();
  const params = useParams<BoatOfferViewParams>();
  const offerId = Number(params.offerId);
  const [addStripePayment] = useAddStripePaymentMutation({ errorPolicy: 'all' });

  const { data: offerData, loading, error } = useGetOfferByIdQuery({ variables: { id: offerId } });
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (offerData?.offer?.boat?.id) {
      sendDataToGTM({
        event: 'cityAdsProductId',
        product_id: offerData.offer.boat.id,
      });
    }
  }, [offerData?.offer?.boat?.id, sendDataToGTM]);

  // Редирект на лодку, если нет доступа к Offer
  if (error) {
    history.push(
      generatePath(routes.BOAT_VIEW, {
        boatIdOrSlug: params.boatIdOrSlug,
      })
    );
  }

  const boat = offerData?.offer?.boat;
  const offer = offerData?.offer;
  const isPaidOne = offer?.payments.find(
    (payment) =>
      payment.paymentMethod?.type === 'PaymentMethod::Platron' &&
      (payment.status === 'paid' || payment.status === 'hold')
  );

  let markerPosition;

  /* TODO 2021 переодически в dev-mode вылетает ошибка связанная с отсудствием lat,
    как только будет получен устойчивый кейс нужно решить проблему */
  if (boat && boat.location?.longitude && boat?.location?.latitude) {
    markerPosition = {
      lng: boat.location.longitude,
      lat: boat.location.latitude,
    };
  }

  const handlePay = (offer: OffersQuery['offers'][0] | CharterOffersSubscription['charterOffers'][0]) => {
    if (offer && offer.id && offer.firstPaymentAmount && offer.price?.currency) {
      addStripePayment({
        variables: {
          offerId: offer.id + '',
          amount: offer.firstPaymentAmount,
          currency: offer.price.currency,
        },
      })
        .then(({ data }) => {
          const paymentId = data?.addStripePayment?.id;
          if (paymentId) {
            history.push(generatePath(routes.PAYMENT_VIEW, { paymentId: `${paymentId}` }));
            return;
          }
        })
        .catch(({ errors }) => {
          const extensions: GraphQLError['extensions'] = GraphQL.formatErrors(errors, 'signIn');

          if (extensions?.validation) {
            message.error(extensions.validation.base);
          }
        });
    }
  };

  const pricePerDayPopover = (offer: GetOfferByIdQuery['offer']) => {
    const diffDays = dateTime.diffDays(offer?.startAt, offer?.endAt);

    return (
      <>
        {offer && offer.price?.amount && offer.startAt && offer.endAt && !!diffDays && diffDays > 1 && (
          <div className="offers-page__popover-row">
            <span>{t('frontend.charter.boats_card.price_per_day')}</span>
            <div>
              <p className="price">
                {formatCurrency(Number((offer.price?.amount / diffDays).toFixed(0)), offer.price.currency)}
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  const getPayBtn = (offer: GetOfferByIdQuery['offer'], disabled?: boolean) => {
    if (offer) {
      const link =
        (offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering) && offer.payments[0].id > 0
          ? generatePath(routes.PAYMENT_VIEW, { paymentId: `${offer.payments[0].id}` })
          : generatePath(routes.BOOKING_VIEW, { offerId: `${offer.id}` });

      if (offer.payments[0].id > 0) {
        return (
          <Link to={link}>
            <Button disabled={disabled} type="primary" shape="round" size="large" style={{ minWidth: '100%' }}>
              {offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering
                ? t('frontend.charter.boats_card.request_book')
                : 'Booking'}
            </Button>
          </Link>
        );
      }

      return (
        <Button
          disabled={disabled}
          onClick={() => handlePay(offer)}
          type="primary"
          shape="round"
          size="large"
          style={{ minWidth: '100%' }}
        >
          {offer.status === OfferStatus.Pending || offer.status === OfferStatus.Considering
            ? t('frontend.charter.boats_card.request_book')
            : 'Booking'}
        </Button>
      );
    }
  };

  return (
    <div className={cn(commonStyles.container, styles.container)}>
      <div className={styles.main}>
        <div className={styles.left}>
          <Carousel
            images={boat?.pictures && boat.pictures.length > 0 ? boat.pictures : []}
            isSkeleton={!boat?.pictures}
            className={styles.photos}
          />
          <BoatIcons boat={boat} className={styles.icons} />
          <Title name={boat?.name} year={boat?.builtAt} className={styles.title} />
          <Rating rating={boat?.rating} isSkeleton={loading} className={styles.rating} />
          <Location
            address={boat?.location?.address}
            country={boat?.location?.country?.name}
            className={styles.location}
          />

          {boat?.description && (
            <Spoiler suffixId="description">
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: boat.description
                    .replace(/<(h1|h2|h3|h4|h5|h6)[^>]*>/gi, '<p>')
                    .replace(/<\/(h1|h2|h3|h4|h5|h6)>/gi, '</p>')
                    .replace(/<a[^>]*>/gi, ' ')
                    .replace(/<\/a>/gi, ' '),
                }}
              />
            </Spoiler>
          )}
          <AdditionalInfo additionalInfo={boat?.boatAdditionalInfo} />
          {boat?.boatProducts && boat.boatProducts.length > 0 && <Products items={boat.boatProducts} />}
          {boat && markerPosition && (
            <div>
              <AppGooglemap height={400 + 'px'} markerPosition={markerPosition} circle skeleton />
            </div>
          )}
          {!!boat?.cancellationPolicy && <CancellationPolicy mainText={boat?.cancellationPolicy} />}
        </div>
        <div className={styles.right}>
          <div style={{ padding: '10px 0' }}>{dateTime.textDateRange(offer?.startAt, offer?.endAt)}</div>

          {offer?.charter?.promoCode?.promoCode && offer?.charter?.promoCode?.discountPercentage && (
            <>
              <div className="offers-page__popover-row">
                <p className="price green">The discount has been applied</p>
              </div>
              <div className="offers-page__popover-row">
                <span>Promo code</span>
                <div>
                  <p className="price green">{offer.charter.promoCode.promoCode}</p>
                </div>
              </div>
              <div className="offers-page__popover-row">
                <span>Discount</span>
                <div>
                  <p className="price green">{offer.charter.promoCode.discountPercentage}%</p>
                </div>
              </div>
            </>
          )}

          {offer?.priceWithoutDiscount?.amount &&
            offer?.price?.amount &&
            offer.priceWithoutDiscount.amount > offer.price.amount && (
              <div className="offers-page__popover-row">
                <span>Without discount</span>
                <div>
                  <p className="price">
                    <s>{formatCurrency(offer.priceWithoutDiscount.amount, offer.priceWithoutDiscount.currency)}</s>
                  </p>
                </div>
              </div>
            )}

          <div className="offers-page__popover" style={{ padding: '0 ' }}>
            <div className="offers-page__popover-row">
              <span>{t('frontend.charter.boats_card.total_renting_price')}</span>
              <div>
                <p className="price green">
                  {offer?.price?.amount
                    ? formatCurrency(offer.price.amount, offer.price.currency)
                    : t('frontend.charter.boats_card.n_a')}
                </p>
              </div>
            </div>

            {offer?.prepayPercent === 100 || isPaidOne ? (
              <>
                <div className="offers-page__popover-row">
                  <span>{t('frontend.charter.boats_card.single_payment')}</span>
                  <div>
                    <p className="price">{formatCurrency(offer?.price?.amount, offer?.price?.currency)}</p>
                  </div>
                </div>
                {pricePerDayPopover(offer)}
              </>
            ) : (
              <>
                {pricePerDayPopover(offer)}
                <div className="offers-page__popover-row">
                  <span>{t('frontend.charter.boats_card.first_payment')}</span>
                  <div>
                    <p className="price">{formatCurrency(offer?.firstPaymentAmount, offer?.price?.currency)}</p>
                    <span className="price additional">({offer?.prepayPercent}%)</span>
                  </div>
                </div>
                <div className="offers-page__popover-row">
                  <span>{t('frontend.charter.boats_card.second_payment')}</span>
                  <div>
                    <p className="price">{formatCurrency(offer?.secondPaymentAmount, offer?.price?.currency)}</p>
                    <span className="price additional">({offer?.secondPaymentPercent}%)</span>
                  </div>
                </div>
                <div className="offers-page__popover-row">
                  <span>{t('frontend.charter.boats_card.second_payment_till')}</span>
                  <div>
                    <p className="price">{dateTime.format(offer?.payTillOn, 'th')}</p>
                  </div>
                </div>
              </>
            )}
            <div>{getPayBtn(offer, false)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferBoatPage;
