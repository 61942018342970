import cn from 'classnames';

import { ReactComponent as RatingIcon } from './assets/rating.svg';

import { RatingProps } from './types';
import styles from './Rating.module.less';

const Rating = ({ value, size, theme, className }: RatingProps) => {
  const maxRating = 5;

  const getRatingContent = (rating: number) => {
    const content = [];

    for (let i = 0; i < rating; i++) {
      content.push(<RatingIcon key={i} className={styles.icon} />);
    }

    if (theme === 'shadow') {
      for (let i = 0; i < maxRating - rating; i++) {
        content.push(<RatingIcon key={`shadow${i}`} className={cn(styles.icon, styles.shadow)} />);
      }
    }

    return content;
  };

  return (
    <div
      className={cn(
        styles.list,
        size === 'small' && styles.small,
        size === 'large' && styles.large,
        theme === 'shadow' && styles.themeShadow,
        className
      )}
    >
      {getRatingContent(value)}
    </div>
  );
};

export default Rating;
