/* eslint-disable */ 
import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "backend": {
    "auth": {
      "errors": {
        "invalid_email_or_password": "falsche Email oder Passwort"
      }
    },
    "payment": {
      "statuses": {
        "canceled": "Abgesagt",
        "created": "Erstellt",
        "declined": "Abgelehnt",
        "error": "Error",
        "hold": "Halt",
        "paid": "Bezahlt",
        "processed": "Verarbeitet"
      }
    }
  },
  "errors": {
    "account": {
      "registration": {
        "push_error": "Push-Benachrichtigung wurde nicht gesendet! Überprüfen Sie Ihre Parameter."
      }
    },
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "invalid_datetime": "Muss eine gültige Datums- / Uhrzeitangabe sein",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "start_at_less_or_equal_than_end_at": "Muss kleiner oder gleich dem Enddatum sein",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    }
  },
  "frontend": {
    "banner": {
      "arrival": {
        "label": "Einchecken",
        "menu": {
          "label": "Anzahl der vollen Tage",
          "options": {
            "eight_hour": "bis zu 8 Stunden",
            "four_hour": "bis zu 4 Stunden",
            "less_than_one_day": "Weniger als 1 Tag",
            "six_hour": "bis zu 6 Stunden",
            "two_week": "zwei Wochen",
            "week": "eine Woche"
          }
        },
        "placeholder": "Einchecken"
      },
      "days": {
        "label": "Tage",
        "placeholder": "Dauer"
      },
      "get_offers": "Angebote einholen",
      "location": {
        "label": "Ort",
        "no_location": "Am ausgewählten Ort wurden keine Kategorien gefunden",
        "placeholder": "Ort"
      },
      "title": "Yachtcharter zu den besten Preisen"
    },
    "boat_card": {
      "n_a": "n / A",
      "no_address": null,
      "no_country": null,
      "price_request": null,
      "rating_default": 5,
      "rent": "Buch",
      "send_request": "Anfrage senden"
    },
    "boat_list": {
      "found_number_of": "Gefunden",
      "xxx_of_boats": "Boote"
    },
    "boat_page": {
      "accommodation": "Unterkunft und Besatzung",
      "additional": "Zusätzlich",
      "check_in": "Einchecken",
      "check_out": "Auschecken",
      "description": "Beschreibung",
      "error_form": "Dieses Boot ist für diese Daten beschäftigt",
      "guests": "Gäste",
      "n_a": "n / A",
      "no_description": null,
      "no_location": null,
      "price_request": null,
      "request": "Angebote einholen",
      "success_form": "Ihre Anfrage wurde erfolgreich gesendet"
    },
    "breadcrumb": {
      "boat_list": "Boote",
      "main_page": "Main",
      "request_list": "Chartas"
    },
    "charter": {
      "active": "Aktiv",
      "additional": {
        "donut": "Krapfen",
        "fishing_gear": "Angelausrüstung",
        "jet_ski": "Jet Ski",
        "paddle_boards": "Paddle Boards",
        "snorkelling_gear": "Schnorchelausrüstung",
        "wakeboard": "Wakeboard"
      },
      "address": "Adresse",
      "boats_card": {
        "after_book": "Überprüfen Sie die Details",
        "discount_btn": "Fragen Sie nach Rabatt",
        "first_payment": "Erste Zahlung",
        "n_a": "n / A",
        "price_per_day": "Preis pro Tag",
        "rating_default": 5,
        "request_book": "Buch",
        "request_offer_paid": "Bezahlt",
        "second_payment": "Zweite Zahlung",
        "second_payment_till": "Zweite Zahlung am",
        "single_payment": "Einmalzahlung",
        "total_price": "Reisepreis",
        "total_renting_price": "Reisepreis"
      },
      "boats_more": {
        "accommodationAndCrew": {
          "bathrooms": "Anzahl der Badezimmer",
          "cabinsConfiguration": "Kabinenkonfiguration",
          "dayGuests": "Tagesgäste",
          "overnightGuests": "Übernachtungsgäste"
        },
        "accommodationAndCrew_title": "Unterkunft und Besatzung",
        "amenities": {
          "airConditioner": "Klimaanlage"
        },
        "amenities_title": "Ausstattung",
        "dimensions": {
          "beam": "Strahl",
          "draught": "Entwurf"
        },
        "dimensions_title": "Maße",
        "equipment": {
          "autopilot": "Autopilot",
          "cdPlayer": "Audio-Abspielgerät; Audio-Player; Musikabspielgerät",
          "chartPlotter": "Kartenplotter",
          "cockpitLoudspeakers": "Cockpit-Lautsprecher",
          "entertainmentSystem": "Unterhaltungssystem",
          "gps": "GPS-Navigationssystem",
          "internet": "Internet",
          "lcdTv": "LCD-Fernseher",
          "navigation": "Navigation",
          "satellitePhone": "Satellitentelefon",
          "wifi": "W-lan"
        },
        "equipment_title": "Ausrüstung",
        "generalInformation": {
          "exteriorColor": "Außenfarbe",
          "flag": "Flagge",
          "hullMaterial": "Rumpfmaterial",
          "hullType": "Bootsrumpf",
          "interiorDesigner": "Innenarchitekt",
          "motor": "Motor",
          "refit": "Überholen"
        },
        "generalInformation_title": "Allgemeine Information",
        "performance": {
          "cruiseSpeedKn": "Reisegeschwindigkeit, kn",
          "engine": "Motoren",
          "fuelCapacityLiters": "Kraftstoffkapazität, l",
          "generators": "Generatoren",
          "maxSpeedKn": "Max Speed, kn"
        },
        "performance_title": "Performance",
        "rentPrice": {
          "apaPercent": "APA,%",
          "rentPrice": "Mietpreis"
        },
        "rentPrice_title": "Zahlungsbedingungen",
        "tendersAndToys": {
          "banana": "Banane",
          "bartender": "Barmann",
          "beachKidsToys": "Strandkinder Spielzeug",
          "beach_kids_toys": "Strand Kinderspielzeug",
          "champagne": "Champagner",
          "divingGear": "Tauchausrüstung",
          "diving_gear": "Tauchausrüstung",
          "donut": "Krapfen",
          "exercise_equipment": "Trainingsgeräte",
          "fishing_gear": "Angelausrüstung",
          "flyboard": "FlyBoard",
          "fruits": "Früchte",
          "grill": "Grill",
          "infatable_sailboat": "Infatable Segelboot",
          "jetski": "Jet Ski",
          "kayak": "Kajak",
          "kneeboard": "Kniebrett",
          "lunch": "Mittagessen",
          "music": "Musik",
          "other": "Andere",
          "other_equipment": "Andere Ausrüstung",
          "paddleBoards": "Paddle-Boards",
          "paddle_boards": "Paddle Boards",
          "paddle_boat": "Paddelboot",
          "sailing_boat": "Segelboot",
          "skiboard": "Skiboard",
          "skuba_equipment": "Skuba Ausrüstung",
          "snorkellingGear": "Schnorchelausrüstung",
          "snorkelling_gear": "Schnorchelausrüstung",
          "spearfishing": "Speerfischen",
          "tender": "Zärtlich",
          "tenderComments": "Bemerkungen",
          "wakeboard": "WakeBoard",
          "waterSki": "Wasserski",
          "waterski": "Wasserski",
          "windsurf_equipment": "Windsurfausrüstung",
          "windsurfingBoard": "Windsurfbrett"
        },
        "tendersAndToys_title": "Angebote und Spielzeug"
      },
      "cabinet": "Konto",
      "charter_request": "Charteranfrage",
      "created_at": "Erstellt",
      "date_not_present": "Datum nicht vorhanden",
      "date_range": "Datum",
      "days": "Tage",
      "due": "Fällig am",
      "getting_offers_step1": "Details einreichen",
      "getting_offers_step2": "Warten auf Angebote",
      "getting_offers_step3": "Eine großartige Charter haben",
      "header_when_there_is_offers": "Legen Sie einen hervorragenden Preis für Ihre Reise fest",
      "header_while_no_offers": "Tolle Angebote werden bald hier sein.",
      "n_a": "n / A",
      "new_request": "Neue Anfrage",
      "no_description": null,
      "no_location": null,
      "note_request_created": "Charteranfrage wird erstellt",
      "price_request": "Preis auf Anfrage erhältlich",
      "rating_default": 5,
      "request_id": null,
      "select_offers": "Angebote anzeigen",
      "text_while_no_offers": "Es kann einige Minuten bis einige Stunden dauern."
    },
    "common_elements": {
      "base": "Base",
      "city": "Stadt",
      "location": "Ort",
      "offer": "Angebot",
      "request": "Anfrage"
    },
    "featured": {
      "n_a": "n / A",
      "no_name": "kein Name",
      "no_price": null,
      "rating_default": 5,
      "rent": "Buch",
      "request": "Anfrage senden",
      "title": "Ausgewählte Boote zu vermieten"
    },
    "footer": {
      "bottom_text": {
        "first": "© GETBOAT GLOBAL INC.",
        "second": "GETBOAT ™ ist eine Marke von GETBOAT GLOBAL INC.",
        "third": "Alle Rechte vorbehalten."
      },
      "contacts": {
        "delaware": {
          "title": "Delaware"
        },
        "email": {
          "title": "Email"
        },
        "florida": {
          "title": "Florida"
        },
        "label": "Kontakte",
        "phone": {
          "title": {
            "en": "EU",
            "us": "UNS"
          }
        }
      },
      "services": {
        "blog": "Yachtcharter",
        "buy_new": "Neu kaufen",
        "buy_used": "Gebraucht kaufen",
        "destinations": "Ziele",
        "experiences": "Erfahrungen",
        "label": "Dienstleistungen",
        "list_your_boat": "Listen Sie Ihr Boot auf",
        "rent": "Miete"
      },
      "site_map": {
        "blog": "Yachtcharter",
        "contacts": "Kontakte",
        "corporate": "Corporate",
        "data_processing": "Zustimmung zur Verarbeitung personenbezogener Daten",
        "faq": "FAQ",
        "label": "Seitenverzeichnis",
        "partnership": "Partnerschaft",
        "privacy_policy": "Datenschutz-Bestimmungen",
        "sign_in": "Einloggen",
        "sign_up": "Anmelden",
        "sitemap": "Seitenverzeichnis",
        "user_agreement": "Nutzungsbedingungen",
        "why": "Warum bei uns buchen?"
      }
    },
    "gdpr_block": {
      "agree_button": "OK",
      "body": "Wir verwenden Cookies, um unsere Website für einzelne Benutzer wie Sie zu optimieren und kontinuierlich zu verbessern.",
      "title": "Warnung DSGVO"
    },
    "header": {
      "actions": {
        "list_your_boat": "Listen Sie Ihr Boot auf"
      },
      "avatar_menu": {
        "charters": "Anfragen",
        "large_button": "Zentralagent",
        "log_in": "Einloggen",
        "log_out": "Ausloggen"
      },
      "currency": {
        "dollar": "US Dollar",
        "euro": "Euro",
        "pound": "Britisches Pfund"
      },
      "currency_sign": {
        "dollar": "$",
        "euro": "€",
        "pound": "£"
      },
      "menu": {
        "buy_new": "Neu kaufen",
        "buy_used": "Gebraucht kaufen",
        "charter": "Charta",
        "experiences": "Erfahrungen"
      },
      "unit": {
        "ft": "ft",
        "m": "m"
      }
    },
    "mainpage": {
      "howto_block": {
        "step1": "Mach ein\nAnfrage",
        "step2": "Erhalten\nBietet an",
        "step3": "Erhalten\nRabatte"
      }
    },
    "offer_object": {
      "status": "Status",
      "statuses": {
        "canceled": "Abgesagt",
        "complited": "Abgeschlossen",
        "considering": "In Anbetracht",
        "created": "Erstellt",
        "declined": "Abgelehnt",
        "error": "Error",
        "notcomplited": "Nicht abgeschlossen",
        "paid": "Bezahlt",
        "processed": "Verarbeitet",
        "processing": "wird bearbeitet",
        "selected": "Ausgewählt"
      }
    },
    "p404": {
      "h1": "404 Nicht gefunden",
      "return": "Ein Boot mieten",
      "subtitle": "Die von Ihnen besuchte Seite ist leider nicht vorhanden."
    },
    "password_forget_page": {
      "email_field": "Email",
      "lettersent_extrainfo": "Wenn Sie eine gültige E-Mail-Adresse für Ihr Profil angegeben haben, haben wir Ihnen einen Link zur E-Mail gesendet, um Ihr Passwort wiederherzustellen",
      "lettersent_title": "Bitte überprüfen Sie Ihre E-Mails",
      "request_password_button": "Kennwort abfragen",
      "title": "Bitte geben Sie die E-Mail-Adresse ein, die Sie auf der Website verwendet haben"
    },
    "password_reset": {
      "change_password_button": "Kennwort ändern",
      "error_with_link": "der Link ist abgelaufen",
      "new_password": "Neues Passwort eingeben",
      "new_password_confirm": "Bestätige neues Passwort",
      "no_confirm_password_provided": "Bitte bestätigen Sie Ihr Passwort",
      "passwords_not_same": "Die beiden eingegebenen Passwörter stimmen nicht überein!"
    },
    "pay_page": {
      "amount": "Menge",
      "no_price": "Ups! Irgendwas lief schief",
      "pay": null,
      "payment_type": "Art",
      "status": "Status"
    },
    "payment": {
      "statuses": {
        "canceled": "Abgesagt",
        "created": "Erstellt",
        "declined": "Abgelehnt",
        "error": "Error",
        "hold": "Halt",
        "paid": "Bezahlt",
        "processed": "Verarbeitet",
        "waiting": "Warten"
      }
    },
    "request_page": {
      "action_button": "Angebote einholen",
      "add_locations": "Weitere Orte und Details können Sie im Kommentar unten hinzufügen",
      "boat_types": "Typen",
      "budget": "Bieten Sie Ihren Preis an",
      "captain": "Brauchen Sie einen Kapitän",
      "captain_text": "Sie können aufgefordert werden, die Lizenz vor der Miete bereitzustellen, wenn die Kapitänsoption nicht ausgewählt ist",
      "comments": "Teilen Sie uns alle Ihre Kommentare und Anfragen mit",
      "dates": "Termine",
      "days": "Tage",
      "diving": "Tauchausrüstung",
      "email": "Email",
      "email_message": "Bitte geben Sie Ihre E-Mail-Adresse an.",
      "error_form": "Es gibt einen Fehler. Bitte kontaktieren Sie uns.",
      "extra_options": "Optionen",
      "flexible": "Termine sind flexibel",
      "from": "von",
      "guests": "Gäste",
      "jet_ski": "Jet Ski",
      "location": "Ort",
      "maximum": "Max",
      "minimal": "Mindest",
      "per_day": "pro Tag",
      "per_week": "pro Woche",
      "phone": "Telefon",
      "phone_extra_info": "Bitte geben Sie Ihre Telefonnummer an, damit unser Team Sie mit den besten Angeboten kontaktieren kann",
      "submit": "einreichen",
      "success_form": "Ihre Anfrage wurde erfolgreich gesendet",
      "transfer": "Transfer vom / zum Boot erforderlich",
      "watersports": "Wassersport"
    },
    "sign_in": {
      "form": {
        "email": {
          "placeholder": "Email"
        },
        "error": "Falsche Anmeldung oder falsches Passwort",
        "error_name": "Bitte geben Sie Ihre E-Mail-Adresse ein",
        "error_password": "Bitte geben Sie Ihr Passwort ein",
        "password": {
          "placeholder": "Passwort"
        },
        "phone": {
          "placeholder": "Telefon"
        },
        "remember": "Mich erinnern",
        "submit": "EINLOGGEN",
        "success": "Angemeldet"
      },
      "registration": {
        "label": "Noch keinen Account?",
        "sign_up": "Anmeldung"
      },
      "title": "Einloggen"
    },
    "sign_up": {
      "form": {
        "and": "und",
        "below_i_agree": "Ich stimme dem zu",
        "by_selecting": "Beim Klicken",
        "email": {
          "placeholder": "Email"
        },
        "error": "Fehler bei der Registrierung",
        "error_name": "Bitte geben Sie Ihre E-Mail-Adresse ein",
        "error_password": "Bitte geben Sie Ihr Passwort ein",
        "name": {
          "placeholder": "Name"
        },
        "password": {
          "placeholder": "Passwort"
        },
        "password_confirmation": {
          "placeholder": "Kennwort bestätigen"
        },
        "phone": {
          "placeholder": "Telefon"
        },
        "read": {
          "terms": "Nutzungsbedingungen",
          "text": "Ich habe gelesen"
        },
        "submit": "Stimmen Sie zu und fahren Sie fort"
      },
      "login": {
        "already": "Bereits registriert?",
        "forget_password": "Passwort vergessen?",
        "no_account": "Noch keinen Account?",
        "sign_in": "Einloggen"
      },
      "success_form": "Bitte überprüfen Sie Ihre E-Mails. Der Brief könnte sich im Spam-Ordner befinden.",
      "terms_of_service": "Nutzungsbedingungen",
      "title": "Anmelden"
    }
  },
  "notifications": {
    "sms": {
      "charter_created": "Vielen Dank, dass Sie eine Anfrage unter https://GetBoat.com erstellt haben\nUnsere lokalen Partner werden Ihnen in Kürze Angebote zusenden.\nBei Fragen rufen Sie bitte diese Nummer an."
    }
  },
  "registration": {
    "sms_code_verification": "Der Bestätigungscode lautet %{code}"
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "backend": {
    "auth": {
      "errors": {
        "invalid_email_or_password": "Wrong email or password"
      }
    },
    "payment": {
      "statuses": {
        "canceled": "Canceled",
        "created": "Created",
        "declined": "Declined",
        "error": "Error",
        "hold": "Hold",
        "paid": "Paid",
        "processed": "Processed"
      }
    }
  },
  "errors": {
    "account": {
      "registration": {
        "push_error": "Push notification was not sent! Check your parameters."
      }
    },
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "invalid_datetime": "Must be a valid datetime",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "start_at_less_or_equal_than_end_at": "Must be less or equal to the end date",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    }
  },
  "frontend": {
    "banner": {
      "arrival": {
        "label": "Check in",
        "menu": {
          "label": "Number of full days",
          "options": {
            "eight_hour": "up to 8 hour",
            "four_hour": "up to 4 hour",
            "less_than_one_day": "Less than 1 day",
            "six_hour": "up to 6 hour",
            "two_week": "two weeks",
            "week": "a week"
          }
        },
        "placeholder": "Check in"
      },
      "days": {
        "label": "Days",
        "placeholder": "Duration"
      },
      "get_offers": "Get offers",
      "location": {
        "label": "Location",
        "no_location": "No categories found in the selected location",
        "placeholder": "Location"
      },
      "title": "Yacht Charters at the Best Prices"
    },
    "boat_card": {
      "n_a": "n/a",
      "no_address": null,
      "no_country": null,
      "price_request": null,
      "rating_default": 5,
      "rent": "Book",
      "send_request": "Send request"
    },
    "boat_list": {
      "found_number_of": "Found",
      "xxx_of_boats": "boats"
    },
    "boat_page": {
      "accommodation": "Accommodation and Crew",
      "additional": "Additional",
      "check_in": "Check In",
      "check_out": "Check Out",
      "description": "Description",
      "error_form": "This boat is busy for these dates",
      "guests": "Guests",
      "n_a": "n/a",
      "no_description": null,
      "no_location": null,
      "price_request": null,
      "request": "Get offers",
      "success_form": "Your request has been successfully submitted"
    },
    "breadcrumb": {
      "boat_list": "Boats",
      "main_page": "Main",
      "request_list": "Charters"
    },
    "charter": {
      "active": "Active",
      "additional": {
        "donut": "Donut",
        "fishing_gear": "Fishing Gear",
        "jet_ski": "Jetski",
        "paddle_boards": "Paddle Boards",
        "snorkelling_gear": "Snorkelling Gear",
        "wakeboard": "Wakeboard"
      },
      "address": "Address",
      "boats_card": {
        "after_book": "Check details",
        "discount_btn": "Ask for discount",
        "first_payment": "First payment",
        "n_a": "n/a",
        "price_per_day": "Price per day",
        "rating_default": 5,
        "request_book": "Book",
        "request_offer_paid": "Paid",
        "second_payment": "Second payment",
        "second_payment_till": "Second payment on",
        "single_payment": "Single payment",
        "total_price": "Trip price",
        "total_renting_price": "Trip price"
      },
      "boats_more": {
        "accommodationAndCrew": {
          "bathrooms": "Number of bathrooms",
          "cabinsConfiguration": "Cabin configuration",
          "dayGuests": "Day guests",
          "overnightGuests": "Overnight guests"
        },
        "accommodationAndCrew_title": "Accommodation and Crew",
        "amenities": {
          "airConditioner": "Air conditioner"
        },
        "amenities_title": "Amenities",
        "dimensions": {
          "beam": "Beam",
          "draught": "Draft"
        },
        "dimensions_title": "Dimensions",
        "equipment": {
          "autopilot": "Autopilot",
          "cdPlayer": "Audio player",
          "chartPlotter": "Chartplotter",
          "cockpitLoudspeakers": "Cockpit loudspeakers",
          "entertainmentSystem": "Entertainment system",
          "gps": "Gps navigation system",
          "internet": "Internet",
          "lcdTv": "Lcd tv",
          "navigation": "Navigation",
          "satellitePhone": "Satellite phone",
          "wifi": "Wi-Fi"
        },
        "equipment_title": "Equipment",
        "generalInformation": {
          "exteriorColor": "Exteriror color",
          "flag": "Flag",
          "hullMaterial": "Hull material",
          "hullType": "Boat Hull",
          "interiorDesigner": "Interior Designer",
          "motor": "Motor",
          "refit": "Refit"
        },
        "generalInformation_title": "General information",
        "performance": {
          "cruiseSpeedKn": "Cruise Speed, kn",
          "engine": "Engines",
          "fuelCapacityLiters": "Fuel capacity, l",
          "generators": "Generators",
          "maxSpeedKn": "Max Speed, kn"
        },
        "performance_title": "Performance",
        "rentPrice": {
          "apaPercent": "APA, %",
          "rentPrice": "Rent Price"
        },
        "rentPrice_title": "Payment terms",
        "tendersAndToys": {
          "banana": "Banana",
          "bartender": "Bartender",
          "beachKidsToys": "Beach kids Toys",
          "beach_kids_toys": "Beach Kids Toys",
          "champagne": "Champagne",
          "divingGear": "Diving gear",
          "diving_gear": "Diving Gear",
          "donut": "Donut",
          "exercise_equipment": "Exercise Equipment",
          "fishing_gear": "Fishing Gear",
          "flyboard": "FlyBoard",
          "fruits": "Fruits",
          "grill": "Grill",
          "infatable_sailboat": "Infatable Sailboat",
          "jetski": "Jetski",
          "kayak": "Kayak",
          "kneeboard": "Kneeboard",
          "lunch": "Lunch",
          "music": "Music",
          "other": "Other",
          "other_equipment": "Other equipment",
          "paddleBoards": "Paddle boards",
          "paddle_boards": "Paddle Boards",
          "paddle_boat": "Paddle Boat",
          "sailing_boat": "Sailing Boat",
          "skiboard": "Skiboard",
          "skuba_equipment": "Skuba Equipment",
          "snorkellingGear": "Snorkelling gear",
          "snorkelling_gear": "Snorkelling Gear",
          "spearfishing": "Spearfishing",
          "tender": "Tender",
          "tenderComments": "Comments",
          "wakeboard": "WakeBoard",
          "waterSki": "Water ski",
          "waterski": "Waterski",
          "windsurf_equipment": "Windsurf Equipment",
          "windsurfingBoard": "Windsurfing board"
        },
        "tendersAndToys_title": "Tenders and Toys"
      },
      "cabinet": "Account",
      "charter_request": "Charter request",
      "created_at": "Created",
      "date_not_present": "date not present",
      "date_range": "Date",
      "days": "days",
      "due": "Due on",
      "getting_offers_step1": "Submitting details",
      "getting_offers_step2": "Waiting for offers",
      "getting_offers_step3": "Having a great charter",
      "header_when_there_is_offers": "Fix an excellent price for your trip",
      "header_while_no_offers": "Great offers will be here soon.",
      "n_a": "n/a",
      "new_request": "New request",
      "no_description": null,
      "no_location": null,
      "note_request_created": "Charter request is created",
      "price_request": "Price available on request",
      "rating_default": 5,
      "request_id": null,
      "select_offers": "View offers",
      "text_while_no_offers": "It could take from a couple of minutes to a few hours."
    },
    "common_elements": {
      "base": "Base",
      "city": "City",
      "location": "Location",
      "offer": "Offer",
      "request": "Request"
    },
    "featured": {
      "n_a": "n/a",
      "no_name": "no name",
      "no_price": null,
      "rating_default": 5,
      "rent": "Book",
      "request": "Send request",
      "title": "Featured boats for rent"
    },
    "footer": {
      "bottom_text": {
        "first": "©GETBOAT GLOBAL INC. ",
        "second": "GETBOAT™ is a trademark of GETBOAT GLOBAL INC.",
        "third": "All rights reserved."
      },
      "contacts": {
        "delaware": {
          "title": "Delaware"
        },
        "email": {
          "title": "Email"
        },
        "florida": {
          "title": "Florida"
        },
        "label": "Contacts",
        "phone": {
          "title": {
            "en": "EU",
            "us": "US"
          }
        }
      },
      "services": {
        "blog": "Yacht charters",
        "buy_new": "Buy new",
        "buy_used": "Buy used",
        "destinations": "Destinations",
        "experiences": "Experiences",
        "label": "Services",
        "list_your_boat": "List your boat",
        "rent": "Rent"
      },
      "site_map": {
        "blog": "Yacht charters",
        "contacts": "Contacts",
        "corporate": "Corporate",
        "data_processing": "Consent to personal data processing",
        "faq": "FAQ",
        "label": "Site map",
        "partnership": "Partnership",
        "privacy_policy": "Privacy policy",
        "sign_in": "Sign in",
        "sign_up": "Sign up",
        "sitemap": "Sitemap",
        "user_agreement": "User agreement",
        "why": "Why book with us?"
      }
    },
    "gdpr_block": {
      "agree_button": "Ok",
      "body": "We use cookies to optimise and continuously improve our website for individual users like you.",
      "title": "Warning GDPR"
    },
    "header": {
      "actions": {
        "list_your_boat": "List your boat"
      },
      "avatar_menu": {
        "charters": "Requests",
        "large_button": "Central Agent",
        "log_in": "Log in",
        "log_out": "Log out"
      },
      "currency": {
        "dollar": "USD",
        "euro": "Euro",
        "pound": "GBP"
      },
      "currency_sign": {
        "dollar": "$",
        "euro": "€",
        "pound": "£"
      },
      "menu": {
        "buy_new": "Buy new",
        "buy_used": "Buy used",
        "charter": "Charter",
        "experiences": "Experiences"
      },
      "unit": {
        "ft": "ft",
        "m": "m"
      }
    },
    "mainpage": {
      "howto_block": {
        "step1": "Make a \nRequest ",
        "step2": "Get \nOffers ",
        "step3": "Get\nDiscounts"
      }
    },
    "offer_object": {
      "status": "Status",
      "statuses": {
        "canceled": "Canceled",
        "complited": "Complited",
        "considering": "Considering",
        "created": "Created",
        "declined": "Declined",
        "error": "Error",
        "notcomplited": "Not complited",
        "paid": "Paid",
        "processed": "Processed",
        "processing": "Processing",
        "selected": "Selected"
      }
    },
    "p404": {
      "h1": "404 Not Found",
      "return": "Rent a boat",
      "subtitle": "Sorry, the page you visited does not exist."
    },
    "password_forget_page": {
      "email_field": "Email",
      "lettersent_extrainfo": "If you provided a valid email for your profile – we sent you a link to the email to restore your password",
      "lettersent_title": "Please check your email",
      "request_password_button": "Request password",
      "title": "Please enter the email you used on the website"
    },
    "password_reset": {
      "change_password_button": "Change password",
      "error_with_link": "Link has expired",
      "new_password": "Enter new password",
      "new_password_confirm": "Confirm new password",
      "no_confirm_password_provided": "Please confirm your password",
      "passwords_not_same": "The two passwords that you entered do not match!"
    },
    "pay_page": {
      "amount": "Amount",
      "no_price": "Oops, something went wrong",
      "pay": null,
      "payment_type": "Type",
      "status": "Status"
    },
    "payment": {
      "statuses": {
        "canceled": "Canceled",
        "created": "Created",
        "declined": "Declined",
        "error": "Error",
        "hold": "Hold",
        "paid": "Paid",
        "processed": "Processed",
        "waiting": "Waiting"
      }
    },
    "request_page": {
      "action_button": "Get offers",
      "add_locations": "You can add more locations and details in the comment below",
      "boat_types": "Types",
      "budget": "Offer your price",
      "captain": "Need a captain",
      "captain_text": "You may be requested to provide the license before the rent if captain option is not selected",
      "comments": "Tell us all your comments and requests",
      "dates": "Dates",
      "days": "days",
      "diving": "Diving equpment",
      "email": "Email",
      "email_message": "Please provide your email.",
      "error_form": "There is an error. Please contact us.",
      "extra_options": "Options",
      "flexible": "Dates are flexible",
      "from": "from",
      "guests": "Guests",
      "jet_ski": "Jet Ski",
      "location": "Location",
      "maximum": "Max",
      "minimal": "Min",
      "per_day": "per day",
      "per_week": "per week",
      "phone": "Phone",
      "phone_extra_info": "Please provide your phone number so our team be able to contact you with the best offers",
      "submit": "Submit",
      "success_form": "Your request has been successfully submitted",
      "transfer": "Transfer from/to boat needed",
      "watersports": "Watersports"
    },
    "sign_in": {
      "form": {
        "email": {
          "placeholder": "Email"
        },
        "error": "Incorrect login or password",
        "error_name": "Please input your email",
        "error_password": "Please input your password",
        "password": {
          "placeholder": "Password"
        },
        "phone": {
          "placeholder": "Phone"
        },
        "remember": "Remember me",
        "submit": "LOG IN",
        "success": "Signed_in"
      },
      "registration": {
        "label": "No account yet?",
        "sign_up": "Sign Up"
      },
      "title": "Log in"
    },
    "sign_up": {
      "form": {
        "and": "and",
        "below_i_agree": "I agree to the",
        "by_selecting": "By clicking",
        "email": {
          "placeholder": "Email"
        },
        "error": "Error while registration",
        "error_name": "Please input your email",
        "error_password": "Please input your password",
        "name": {
          "placeholder": "Name"
        },
        "password": {
          "placeholder": "Password"
        },
        "password_confirmation": {
          "placeholder": "Confirm password"
        },
        "phone": {
          "placeholder": "Phone"
        },
        "read": {
          "terms": "Terms of Service",
          "text": "I've read"
        },
        "submit": "Agree and continue"
      },
      "login": {
        "already": "Already registered?",
        "forget_password": "Forgot your password?",
        "no_account": "No account yet?",
        "sign_in": "Log In"
      },
      "success_form": "Please check your email. The letter could be in the spam folder.",
      "terms_of_service": "Terms of service",
      "title": "Sign up"
    }
  },
  "notifications": {
    "sms": {
      "charter_created": "Thank you for creating a request at https://GetBoat.com\nOur local partners will send you offers soon.\nIf you have any questions, please call this number."
    }
  },
  "registration": {
    "sms_code_verification": "Confirmation code is %{code}"
  }
});
I18n.translations["fr"] = I18n.extend((I18n.translations["fr"] || {}), {
  "backend": {
    "auth": {
      "errors": {
        "invalid_email_or_password": "Adresse e-mail ou mot de passe incorrect"
      }
    },
    "payment": {
      "statuses": {
        "canceled": "Annulé",
        "created": "Créé",
        "declined": "Diminué",
        "error": "Erreur",
        "hold": "Tenir",
        "paid": "Payé",
        "processed": "Traité"
      }
    }
  },
  "errors": {
    "account": {
      "registration": {
        "push_error": "La notification push n'a pas été envoyée! Vérifiez vos paramètres."
      }
    },
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "invalid_datetime": "Doit être une date / heure valide",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "start_at_less_or_equal_than_end_at": "Doit être inférieur ou égal à la date de fin",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    }
  },
  "frontend": {
    "banner": {
      "arrival": {
        "label": "Enregistrement",
        "menu": {
          "label": "Nombre de jours complets",
          "options": {
            "eight_hour": "jusqu'à 8 heures",
            "four_hour": "jusqu'à 4 heures",
            "less_than_one_day": "Moins d'un jour",
            "six_hour": "jusqu'à 6 heures",
            "two_week": "deux semaines",
            "week": "une semaine"
          }
        },
        "placeholder": "Enregistrement"
      },
      "days": {
        "label": "Journées",
        "placeholder": "Durée"
      },
      "get_offers": "Recevez des offres",
      "location": {
        "label": "Emplacement",
        "no_location": "Aucune catégorie trouvée dans l'emplacement sélectionné",
        "placeholder": "Emplacement"
      },
      "title": "Yacht Charters aux meilleurs prix"
    },
    "boat_card": {
      "n_a": "n / A",
      "no_address": null,
      "no_country": null,
      "price_request": null,
      "rating_default": 5,
      "rent": "Livre",
      "send_request": "Envoyer une demande"
    },
    "boat_list": {
      "found_number_of": "Trouvé",
      "xxx_of_boats": "bateaux"
    },
    "boat_page": {
      "accommodation": "Hébergement et équipage",
      "additional": "Supplémentaire",
      "check_in": "Enregistrement",
      "check_out": "Vérifier",
      "description": "La description",
      "error_form": "Ce bateau est occupé pour ces dates",
      "guests": "Invités",
      "n_a": "n / A",
      "no_description": null,
      "no_location": null,
      "price_request": null,
      "request": "Recevez des offres",
      "success_form": "Votre demande a été soumise avec succès"
    },
    "breadcrumb": {
      "boat_list": "Bateaux",
      "main_page": "Principale",
      "request_list": "Chartes"
    },
    "charter": {
      "active": "actif",
      "additional": {
        "donut": "Donut",
        "fishing_gear": "Matériel de pêche",
        "jet_ski": "Jet ski",
        "paddle_boards": "Planches à pagaie",
        "snorkelling_gear": "Équipement de plongée avec tuba",
        "wakeboard": "Wakeboard"
      },
      "address": "Adresse",
      "boats_card": {
        "after_book": "Vérifiez les détails",
        "discount_btn": "Demandez une remise",
        "first_payment": "Premier paiement",
        "n_a": "n / A",
        "price_per_day": "Prix par jour",
        "rating_default": 5,
        "request_book": "Livre",
        "request_offer_paid": "Payé",
        "second_payment": "Deuxième paiement",
        "second_payment_till": "Deuxième paiement le",
        "single_payment": "Paiement unique",
        "total_price": "Prix du voyage",
        "total_renting_price": "Prix du voyage"
      },
      "boats_more": {
        "accommodationAndCrew": {
          "bathrooms": "Nombre de salles de bain",
          "cabinsConfiguration": "Configuration de la cabine",
          "dayGuests": "Invités de jour",
          "overnightGuests": "Invités de nuit"
        },
        "accommodationAndCrew_title": "Hébergement et équipage",
        "amenities": {
          "airConditioner": "Climatisation"
        },
        "amenities_title": "Agréments",
        "dimensions": {
          "beam": "Faisceau",
          "draught": "Brouillon"
        },
        "dimensions_title": "Dimensions",
        "equipment": {
          "autopilot": "Pilote automatique",
          "cdPlayer": "Lecteur audio",
          "chartPlotter": "Traceur de cartes",
          "cockpitLoudspeakers": "Haut-parleurs de cockpit",
          "entertainmentSystem": "Système de divertissement",
          "gps": "Système de navigation GPS",
          "internet": "l'Internet",
          "lcdTv": "Téléviseur LCD",
          "navigation": "La navigation",
          "satellitePhone": "Téléphone satellite",
          "wifi": "Wifi"
        },
        "equipment_title": "Équipement",
        "generalInformation": {
          "exteriorColor": "Couleur de l'exteriror",
          "flag": "Drapeau",
          "hullMaterial": "Matériau de la coque",
          "hullType": "Coque de bateau",
          "interiorDesigner": "Décorateur d'intérieur",
          "motor": "Moteur",
          "refit": "Remonter"
        },
        "generalInformation_title": "informations générales",
        "performance": {
          "cruiseSpeedKn": "Vitesse de croisière, kn",
          "engine": "Moteurs",
          "fuelCapacityLiters": "Capacité de carburant, l",
          "generators": "Générateurs",
          "maxSpeedKn": "Vitesse maximale, kn"
        },
        "performance_title": "Performance",
        "rentPrice": {
          "apaPercent": "APA,%",
          "rentPrice": "Prix de location"
        },
        "rentPrice_title": "Modalités de paiement",
        "tendersAndToys": {
          "banana": "banane",
          "bartender": "Barman",
          "beachKidsToys": "Jouets de plage pour enfants",
          "beach_kids_toys": "Jouets de plage pour enfants",
          "champagne": "Champagne",
          "divingGear": "Équipement de plongée",
          "diving_gear": "Équipement de plongée",
          "donut": "Donut",
          "exercise_equipment": "Equipement d'exercice",
          "fishing_gear": "Matériel de pêche",
          "flyboard": "FlyBoard",
          "fruits": "Des fruits",
          "grill": "Gril",
          "infatable_sailboat": "Voilier gonflable",
          "jetski": "Jet ski",
          "kayak": "Kayak",
          "kneeboard": "Genou",
          "lunch": "Déjeuner",
          "music": "Musique",
          "other": "Autre",
          "other_equipment": "Autre équipement",
          "paddleBoards": "Planches à pagaie",
          "paddle_boards": "Planches à pagaie",
          "paddle_boat": "Pédalo",
          "sailing_boat": "Bateau à voile",
          "skiboard": "Skiboard",
          "skuba_equipment": "Équipement Skuba",
          "snorkellingGear": "Équipement de plongée avec tuba",
          "snorkelling_gear": "Équipement de plongée avec tuba",
          "spearfishing": "Chasse sous-marine",
          "tender": "Tendre",
          "tenderComments": "commentaires",
          "wakeboard": "WakeBoard",
          "waterSki": "Ski nautique",
          "waterski": "Ski nautique",
          "windsurf_equipment": "Équipement de planche à voile",
          "windsurfingBoard": "Planche à voile"
        },
        "tendersAndToys_title": "Offres et jouets"
      },
      "cabinet": "Compte",
      "charter_request": "Demande de charte",
      "created_at": "Créé",
      "date_not_present": "date non présente",
      "date_range": "Date",
      "days": "journées",
      "due": "Dû le",
      "getting_offers_step1": "Soumettre des détails",
      "getting_offers_step2": "En attente d'offres",
      "getting_offers_step3": "Avoir une belle charte",
      "header_when_there_is_offers": "Fixez un excellent prix pour votre voyage",
      "header_while_no_offers": "Des offres intéressantes seront bientôt disponibles.",
      "n_a": "n / A",
      "new_request": "Nouvelle requête",
      "no_description": null,
      "no_location": null,
      "note_request_created": "La demande de charte est créée",
      "price_request": "Prix disponible sur demande",
      "rating_default": 5,
      "request_id": null,
      "select_offers": "Voir les offres",
      "text_while_no_offers": "Cela peut prendre de quelques minutes à quelques heures."
    },
    "common_elements": {
      "base": "Base",
      "city": "Ville",
      "location": "Emplacement",
      "offer": "Offrir",
      "request": "Demander"
    },
    "featured": {
      "n_a": "n / A",
      "no_name": "sans nom",
      "no_price": null,
      "rating_default": 5,
      "rent": "Livre",
      "request": "Envoyer une demande",
      "title": "Bateaux en vedette à louer"
    },
    "footer": {
      "bottom_text": {
        "first": "© GETBOAT GLOBAL INC.",
        "second": "GETBOAT ™ est une marque de commerce de GETBOAT GLOBAL INC.",
        "third": "Tous les droits sont réservés."
      },
      "contacts": {
        "delaware": {
          "title": "Delaware"
        },
        "email": {
          "title": "E-mail"
        },
        "florida": {
          "title": "Floride"
        },
        "label": "Contacts",
        "phone": {
          "title": {
            "en": "UE",
            "us": "nous"
          }
        }
      },
      "services": {
        "blog": "Location de yachts",
        "buy_new": "Acheter neuf",
        "buy_used": "Acheter d'occasion",
        "destinations": "Les destinations",
        "experiences": "Expériences",
        "label": "Prestations de service",
        "list_your_boat": "Listez votre bateau",
        "rent": "Location"
      },
      "site_map": {
        "blog": "Location de yachts",
        "contacts": "Contacts",
        "corporate": "Entreprise",
        "data_processing": "Consentement au traitement des données personnelles",
        "faq": "FAQ",
        "label": "Plan du site",
        "partnership": "Partenariat",
        "privacy_policy": "Politique de confidentialité",
        "sign_in": "S'identifier",
        "sign_up": "S'inscrire",
        "sitemap": "Plan du site",
        "user_agreement": "Accord de l'utilisateur",
        "why": "Pourquoi réserver avec nous?"
      }
    },
    "gdpr_block": {
      "agree_button": "D'accord",
      "body": "Nous utilisons des cookies pour optimiser et améliorer continuellement notre site Web pour des utilisateurs individuels comme vous.",
      "title": "Avertissement GDPR"
    },
    "header": {
      "actions": {
        "list_your_boat": "Listez votre bateau"
      },
      "avatar_menu": {
        "charters": "Demandes",
        "large_button": "Agent central",
        "log_in": "Connexion",
        "log_out": "Se déconnecter"
      },
      "currency": {
        "dollar": "USD",
        "euro": "euro",
        "pound": "GBP"
      },
      "currency_sign": {
        "dollar": "$",
        "euro": "€",
        "pound": "£"
      },
      "menu": {
        "buy_new": "Acheter neuf",
        "buy_used": "Acheter d'occasion",
        "charter": "Charte",
        "experiences": "Expériences"
      },
      "unit": {
        "ft": "pi",
        "m": "m"
      }
    },
    "mainpage": {
      "howto_block": {
        "step1": "Faire un\nDemander",
        "step2": "Obtenir\nDes offres",
        "step3": "Obtenir\nRéductions"
      }
    },
    "offer_object": {
      "status": "Statut",
      "statuses": {
        "canceled": "Annulé",
        "complited": "Complété",
        "considering": "Considérant",
        "created": "Créé",
        "declined": "Diminué",
        "error": "Erreur",
        "notcomplited": "Pas compliqué",
        "paid": "Payé",
        "processed": "Traité",
        "processing": "Traitement",
        "selected": "Choisi"
      }
    },
    "p404": {
      "h1": "404 introuvable",
      "return": "Louer un bateau",
      "subtitle": "Désolé, la page que vous avez visitée n'existe pas."
    },
    "password_forget_page": {
      "email_field": "E-mail",
      "lettersent_extrainfo": "Si vous avez fourni un e-mail valide pour votre profil, nous vous avons envoyé un lien vers l'e-mail pour restaurer votre mot de passe",
      "lettersent_title": "Merci de consulter vos emails",
      "request_password_button": "Demander un mot de passe",
      "title": "Veuillez saisir l'adresse e-mail que vous avez utilisée sur le site Web"
    },
    "password_reset": {
      "change_password_button": "Changer le mot de passe",
      "error_with_link": "Le lien a expiré",
      "new_password": "Entrez un nouveau mot de passe",
      "new_password_confirm": "Confirmer le nouveau mot de passe",
      "no_confirm_password_provided": "Veuillez confirmer votre mot de passe",
      "passwords_not_same": "Les deux mots de passe que vous avez saisis ne correspondent pas!"
    },
    "pay_page": {
      "amount": "Montant",
      "no_price": "Oups, un problème est survenu",
      "pay": null,
      "payment_type": "Taper",
      "status": "Statut"
    },
    "payment": {
      "statuses": {
        "canceled": "Annulé",
        "created": "Créé",
        "declined": "Diminué",
        "error": "Erreur",
        "hold": "Tenir",
        "paid": "Payé",
        "processed": "Traité",
        "waiting": "Attendre"
      }
    },
    "request_page": {
      "action_button": "Recevez des offres",
      "add_locations": "Vous pouvez ajouter plus d'emplacements et de détails dans le commentaire ci-dessous",
      "boat_types": "Les types",
      "budget": "Offrez votre prix",
      "captain": "Besoin d'un capitaine",
      "captain_text": "Vous devrez peut-être fournir la licence avant la location si l'option capitaine n'est pas sélectionnée",
      "comments": "Dites-nous tous vos commentaires et demandes",
      "dates": "Rendez-vous",
      "days": "journées",
      "diving": "Équipement de plongée",
      "email": "E-mail",
      "email_message": "Veuillez fournir votre e-mail.",
      "error_form": "Il y a une erreur. Contactez nous s'il vous plait.",
      "extra_options": "Options",
      "flexible": "Les dates sont flexibles",
      "from": "de",
      "guests": "Invités",
      "jet_ski": "Jet ski",
      "location": "Emplacement",
      "maximum": "Max",
      "minimal": "Min",
      "per_day": "par jour",
      "per_week": "par semaine",
      "phone": "Téléphoner",
      "phone_extra_info": "Veuillez fournir votre numéro de téléphone afin que notre équipe puisse vous contacter avec les meilleures offres",
      "submit": "Soumettre",
      "success_form": "Votre demande a été soumise avec succès",
      "transfer": "Transfert de / vers le bateau nécessaire",
      "watersports": "Sports nautiques"
    },
    "sign_in": {
      "form": {
        "email": {
          "placeholder": "E-mail"
        },
        "error": "identifiant ou mot de passe incorrect",
        "error_name": "Veuillez saisir votre email",
        "error_password": "Veuillez saisir votre mot de passe",
        "password": {
          "placeholder": "Mot de passe"
        },
        "phone": {
          "placeholder": "Téléphoner"
        },
        "remember": "Souviens-toi de moi",
        "submit": "CONNEXION",
        "success": "Signed_in"
      },
      "registration": {
        "label": "Pas encore de compte?",
        "sign_up": "S'inscrire"
      },
      "title": "Connexion"
    },
    "sign_up": {
      "form": {
        "and": "et",
        "below_i_agree": "je suis d'accord avec le",
        "by_selecting": "En cliquant",
        "email": {
          "placeholder": "E-mail"
        },
        "error": "Erreur lors de l'inscription",
        "error_name": "Veuillez saisir votre email",
        "error_password": "Veuillez saisir votre mot de passe",
        "name": {
          "placeholder": "Nom"
        },
        "password": {
          "placeholder": "Mot de passe"
        },
        "password_confirmation": {
          "placeholder": "Confirmez le mot de passe"
        },
        "phone": {
          "placeholder": "Téléphoner"
        },
        "read": {
          "terms": "Conditions d'utilisation",
          "text": "j'ai lu"
        },
        "submit": "Acceptez et continuez"
      },
      "login": {
        "already": "Déjà enregistré?",
        "forget_password": "Mot de passe oublié?",
        "no_account": "Pas encore de compte?",
        "sign_in": "Connexion"
      },
      "success_form": "Merci de consulter vos emails. La lettre peut se trouver dans le dossier spam.",
      "terms_of_service": "Conditions d'utilisation",
      "title": "S'inscrire"
    }
  },
  "notifications": {
    "sms": {
      "charter_created": "Merci d'avoir créé une demande sur https://GetBoat.com\nNos partenaires locaux vous enverront bientôt des offres.\nSi vous avez des questions, veuillez appeler ce numéro."
    }
  },
  "registration": {
    "sms_code_verification": "Le code de confirmation est %{code}"
  }
});
export default I18n;
