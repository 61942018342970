import { useState } from 'react';
import { BoatCard, Swiper, SwiperSlide } from '$components/index';

import { SwipeBoatListProps } from './types';
import styles from './SwipeBoats.module.less';

const SwipeBoats = ({ boats, numberBoats = 6, isBuy, isNewPhoto, isSkeleton }: SwipeBoatListProps) => {
  const [isLazyImages, setIsLazyImages] = useState<boolean>(true);

  return (
    <Swiper
      className={styles.swiper}
      onSliderFirstMove={() => {
        setIsLazyImages(false);
      }}
    >
      {boats?.map((boat) => (
        <SwiperSlide key={boat.id} className={styles.slide}>
          <BoatCard item={boat} isBuy={isBuy} isLazyImages={isLazyImages} isNewSize={isNewPhoto} />
        </SwiperSlide>
      ))}

      {isSkeleton &&
        Array.from({ length: numberBoats }, (_, i) => (
          <SwiperSlide key={i} className={styles.slide}>
            <BoatCard />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default SwipeBoats;
