import { useRef, useState, useLayoutEffect, lazy, Suspense } from 'react';
import dayjs from 'dayjs';
import { Popover, Input } from '$ui/index';

import { useDateTime } from '$hooks/index';
import { DatepickerProps } from '$components/Forms/Datepicker/types';
import { FloatInputLabel } from '$components/Forms';

import styles from './Datepicker.module.less';
import { LoadingOutlined } from '@ant-design/icons';

const Calendar = lazy(() => import('./components/Calendar'));

const Datepicker = ({ value, onChange, config, placeholder, dataCy, oneDate, size }: DatepickerProps) => {
  const dateTime = useDateTime();

  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(280);
  const [isSpin, setIsSpin] = useState<boolean>(false);

  useLayoutEffect(() => {
    setInputWidth(ref?.current?.offsetWidth || 280);

    const updateWindow = () => {
      setInputWidth(ref?.current?.offsetWidth || 280);
    };

    window.addEventListener('resize', updateWindow);
    return () => window.removeEventListener('resize', updateWindow);
  }, []);

  if (value === undefined || onChange === undefined) {
    return null;
  }

  const handleTextValue = (oneDate?: boolean, startsAt?: Date, endsAt?: Date): string | undefined => {
    if (!oneDate && startsAt && endsAt && startsAt !== endsAt) {
      const magicShort = dateTime.magicShort(startsAt?.toISOString(), endsAt?.toISOString());
      return magicShort ? magicShort : undefined;
    } else if (startsAt) {
      return dayjs(startsAt.toISOString()).format('DD MMM YYYY');
    }

    return undefined;
  };

  const handleLoad = (visible: boolean) => {
    setShow(visible);

    setTimeout(() => setIsSpin(true), 450);
  };

  // TODO 2023 Нужен рефакторинг Popover
  return (
    <div className={styles.wrapper} data-cy={dataCy}>
      <Popover
        placement="bottomLeft"
        content={
          <Suspense fallback={isSpin ? <LoadingOutlined className={styles.spin} spin /> : null}>
            <Calendar
              value={value}
              onChange={onChange}
              width={inputWidth}
              config={config}
              placeholder={placeholder}
              show={show}
              handleShow={setShow}
              oneDate={oneDate}
            />
          </Suspense>
        }
        trigger="click"
        visible={show}
        onVisibleChange={(visible) => handleLoad(visible)}
      >
        <div ref={ref}>
          <FloatInputLabel
            label={placeholder}
            isFloated={!!handleTextValue(oneDate, value?.startsAt, value?.endsAt)}
            labelFloatedStyles={{ marginLeft: '2px' }}
            size={size}
          >
            <Input
              className={styles.input}
              onClick={() => setShow(true)}
              value={handleTextValue(oneDate, value?.startsAt, value?.endsAt)}
              data-cy="input"
            />
          </FloatInputLabel>
        </div>
      </Popover>
    </div>
  );
};

export default Datepicker;
