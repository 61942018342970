import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { useTranslation } from 'react-i18next';

const useDateTime = () => {
  const { i18n, t } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const isValid = (data?: string | null): boolean => {
    return !!(data && new Date(data).toString() !== 'Invalid Date' && dayjs(data).isValid());
  };

  const diffDays = (startAt?: string | null, endAt?: string | null): number | null => {
    if (startAt && endAt && isValid(startAt) && isValid(endAt)) {
      return dayjs.utc(endAt).diff(dayjs.utc(startAt), 'days');
    }

    return null;
  };

  const magicShort = (startAtProps?: string | null, endsAtProps?: string | null): string | null => {
    const startAt = startAtProps && isValid(startAtProps) ? dayjs(startAtProps) : undefined;
    const endAt = endsAtProps && isValid(endsAtProps) ? dayjs(endsAtProps) : undefined;

    dayjs.locale(i18n.language);

    let resultStart: string | undefined = undefined;

    if (startAt && !endAt) {
      return startAt.format('DD MMM YYYY');
    } else if (!startAt && endAt) {
      return endAt.format('DD MMM YYYY');
    }

    if (startAt && endAt) {
      if (startAt.format('YYYY') === endAt.format('YYYY')) {
        if (startAt.format('MMM') === endAt.format('MMM')) {
          if (startAt.format('DD') !== endAt.format('DD')) {
            resultStart = startAt.format('DD');
          }
        } else {
          resultStart = startAt.format('DD MMM');
        }
      } else {
        resultStart = startAt.format('DD MMM YYYY');
      }

      return [resultStart, endAt.format('DD MMM YYYY')].filter((i) => i).join(' - ');
    }

    return null;
  };

  const format = (data?: string | null, type?: 'output' | 'withoutDay' | 'th', utc?: boolean): string | null => {
    dayjs.locale(i18n.language);

    if (!data || !isValid(data)) {
      return null;
    }

    let formatData = 'DD MMM YYYY';

    switch (type) {
      case 'output':
        formatData = 'YYYY-MM-DD';
        break;
      case 'withoutDay':
        formatData = 'MMM YYYY';
        break;
      case 'th':
        formatData = 'MMMM Do YYYY';
        break;
    }

    if (utc) {
      return dayjs(data).utc().format(formatData);
    }

    return dayjs(data).format(formatData);
  };

  const textDateRange = (startsAtProps: string, endsAtProps: string): string | null => {
    dayjs.locale(i18n.language);

    const startsAt = isValid(startsAtProps) ? dayjs(startsAtProps).utc() : undefined;
    const endsAt = isValid(endsAtProps) ? dayjs(endsAtProps).utc() : undefined;

    const getDateText = (date: dayjs.Dayjs): string => {
      return date.format('HH:mm') !== '00:00' ? date.format('MMMM Do YYYY, HH:mm') : date.format('MMMM Do YYYY');
    };

    if (startsAt && endsAt) {
      const days = endsAt.diff(startsAt, 'days');
      // display minimum rental time = 1 day
      return `${getDateText(startsAt)} - ${getDateText(endsAt)} / ${days > 0 ? days : 1} ${t('frontend.charter.days')}`;
    } else if (startsAt) {
      return `${t('frontend.boat_page.check_in')} ${getDateText(startsAt)}`;
    } else if (endsAt) {
      return `${t('frontend.boat_page.check_out')} ${getDateText(endsAt)}`;
    }

    return null;
  };

  return { diffDays, magicShort, format, textDateRange };
};

export default useDateTime;
