import { memo, useState } from 'react';
import { Button } from '$ui/index';

import { Swiper, SwiperSlide } from '$components/index';

import { ArticlesProps } from './types';
import styles from './Articles.module.less';

const Articles = ({ items }: ArticlesProps) => {
  const [isLazyImages, setIsLazyImages] = useState<boolean>(true);

  return (
    <Swiper
      className={styles.swiper}
      onSliderFirstMove={() => {
        setIsLazyImages(false);
      }}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id} className={styles.slide}>
          <a href={item.link} className={styles.card} target="_blank" rel="noreferrer">
            <div className={styles.photo}>
              <picture>
                <source media="(max-width: 767px)" srcSet={`${item.photoW280}`} />
                <source media="(min-width: 768px)" srcSet={`${item.photoW380}`} />
                <img src={`${item.photoW380}`} alt={item.title} loading={isLazyImages ? 'lazy' : undefined} />
              </picture>
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.short}>{item.short}</div>
              <div className={styles.date}>{item.date}</div>
              <Button type="primary" shape="round" size="middle">
                Read article
              </Button>
            </div>
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(Articles, (oldProps, newProps) => {
  return oldProps.items.map((i) => i.id).join(',') === newProps.items.map((i) => i.id).join(',');
});
