import cn from 'classnames';
import { Swiper as LibSwiper } from 'swiper/react/swiper-react';
import { Navigation, Pagination, FreeMode } from 'swiper';

import 'swiper/swiper.less';
import 'swiper/modules/navigation/navigation.less';
import 'swiper/modules/pagination/pagination.less';
import 'swiper/modules/free-mode/free-mode.less';

import { SwiperProps } from './types';
import styles from './Swiper.module.less';

const Swiper = ({
  children,
  isNavigation = true,
  isPagination = false,
  isFreeMode = false,
  spaceBetween = 20,
  onSliderFirstMove,
  className,
}: SwiperProps) => (
  <LibSwiper
    modules={[Navigation, Pagination, FreeMode]}
    onSliderFirstMove={onSliderFirstMove}
    slidesPerView="auto"
    spaceBetween={spaceBetween}
    navigation={isNavigation}
    pagination={isPagination}
    freeMode={isFreeMode}
    className={cn(styles.swiperComponent, className)}
  >
    {children}
  </LibSwiper>
);

export default Swiper;
