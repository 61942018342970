import { useTranslation } from 'react-i18next';

import { sectionNames } from './data';
import { AdditionalInfoProps } from './types';
import styles from './AdditionalInfo.module.less';

const AdditionalInfo = ({ additionalInfo }: AdditionalInfoProps) => {
  const { t } = useTranslation();

  const equipment: Array<{ sectionKey: string; key: string }> = [];
  const specifications: Array<{ sectionKey: string; key: string; value: string | number }> = [];

  additionalInfo &&
    Object.entries(additionalInfo).forEach(([sectionKey, parameters]) => {
      sectionNames.includes(sectionKey) &&
        Object.entries(parameters).forEach(([key, value]) => {
          if (value === true) {
            equipment.push({ sectionKey, key });
          } else if (typeof value === 'string' || typeof value === 'number') {
            specifications.push({ sectionKey, key, value });
          }
        });
    });

  const getParameterLabel = (sectionKey: string, parameterKey: string) => {
    const fullKey = 'frontend.charter.boats_more.' + sectionKey + '.' + parameterKey;

    if (t(fullKey) !== fullKey) {
      return t(fullKey);
    } else {
      // BackEnd отдаёт under_score в переводе lowerCamelCase, по этому пытаемся найти перевод по сконвертированному ключу
      const possibleKey = parameterKey
        .split('_')
        .map((key, i) =>
          i === 0 ? key.charAt(0).toLowerCase() + key.slice(1) : key.charAt(0).toUpperCase() + key.slice(1)
        )
        .join('');

      const possibleFullKey = 'frontend.charter.boats_more.' + sectionKey + '.' + possibleKey;
      if (t(possibleFullKey) !== possibleFullKey) return t(possibleFullKey);

      // Если ни чего не найдено, делаем из ключа текст для label
      return (parameterKey.charAt(0).toUpperCase() + parameterKey.slice(1)).replace(/_/g, ' ');
    }
  };

  return (
    <>
      {specifications.length > 0 && (
        <div className={styles.specifications}>
          <h2 className={styles.title}>Specifications</h2>
          <div>
            {specifications.map((parameter) => (
              <div key={parameter.key} className={styles.parameter}>
                <div className={styles.label}>{getParameterLabel(parameter.sectionKey, parameter.key)}:</div>
                <div className={styles.value}>{parameter.value}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {equipment.length > 0 && (
        <div className={styles.equipment}>
          <h2 className={styles.title}>Equipment</h2>
          <ul className={styles.list}>
            {equipment.map((parameter) => (
              <li key={parameter.key} className={styles.parameter}>
                {getParameterLabel(parameter.sectionKey, parameter.key)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default AdditionalInfo;
