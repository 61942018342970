import { Spin } from '$ui/index';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { formatCurrency } from '$utils/formatCurrency';
import { Swiper, SwiperSlide, PictureSource } from '$components/index';

import { CategoriesProps } from './types';
import styles from './Categories.module.less';

const Categories = ({ categories, value, onChange }: CategoriesProps) => {
  const { t } = useTranslation();

  if (onChange === undefined) return null;

  // TODO 2021 Переделать на скрытые Checkbox
  const handleSelectCategory = (value?: Array<string>, id?: string) => {
    if (id && Array.isArray(value)) {
      if (value.includes(id)) onChange(value.filter((i) => i !== id));
      else onChange([...value, id]);
    } else {
      id && onChange([id]);
    }
  };

  if (!categories) {
    return (
      <div className={styles.categoriesSpinner}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      </div>
    );
  }

  return (
    <Swiper spaceBetween={15} className={cn(styles.categories, 'cy-Categories')}>
      {categories.map((category) => (
        <SwiperSlide
          key={category.id}
          className={cn(
            styles.category,
            'cy-Category',
            'cy-CategoryId_' + category.id,
            value?.includes(category.id) && [styles.selectedCategory, 'cy-SelectedCategory']
          )}
          onClick={() => handleSelectCategory(value, category.id)}
        >
          {category.picture && (
            <PictureSource
              x1={category.picture.w154}
              x2={category.picture.w308}
              x3={category.picture.w462}
              x4={category.picture.w616}
              alt={'category ' + category.name}
              className={styles.icon}
            />
          )}
          <div className={styles.text}>
            <div className={styles.name}>{category.name}</div>
            <div className={styles.price}>
              <span className={styles.from}>{t('frontend.request_page.from')}</span>
              <span className={styles.value}>{formatCurrency(category.price?.amount)}</span>
              <span className={styles.perDay}>{t('frontend.request_page.per_day')}</span>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Categories;
