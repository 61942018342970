import cn from 'classnames';

import { Icon, ManualSkeleton } from '$components/index';

import { SpecificationsProps } from './types';
import styles from './Specifications.module.less';

const Specifications = ({ items, className }: SpecificationsProps) => {
  return (
    <div className={cn(styles.list, className)}>
      {items ? (
        <>
          {items.map((item) => (
            <div key={item.type} className={styles.item}>
              <Icon name={item.icon} className={styles.icon} />
              {item.value}
            </div>
          ))}
        </>
      ) : (
        <ManualSkeleton className={styles.skeleton} />
      )}
    </div>
  );
};

export default Specifications;
