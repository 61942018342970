import { useTranslation } from 'react-i18next';
import { Button, Form } from '$ui/index';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { useCallMeRequestMutation } from '$graphql/hooks';
import { FloatInputLabel, InputPhone } from '$components/Forms';

import styles from './CallMe.module.less';

const CallMe = () => {
  const [formOrig] = Form.useForm<{ phone?: string }>();

  const { i18n } = useTranslation();

  const [callMeRequestMutation, { data, loading, error }] = useCallMeRequestMutation();

  const handleFormComplete = async (values: { phone?: string }) => {
    if (values.phone && values.phone.length > 0) {
      callMeRequestMutation({ variables: { phone: values.phone } });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>Haven&apos;t found what you&apos;re looking for?</div>
        <div className={styles.description}>Leave your phone number and we will call you back within 15 minutes</div>
      </div>

      {!loading && !error && !data && (
        <Form
          form={formOrig}
          layout="vertical"
          onFinish={(values) => handleFormComplete(values)}
          className={styles.form}
        >
          <Form.Item shouldUpdate className={styles.inputPhone}>
            {(form) => (
              <Form.Item name="phone" noStyle>
                <FloatInputLabel label="Phone number" labelFloatedClass={styles.miniThemeInputPhone} isFloated>
                  <InputPhone
                    value={form.getFieldValue('phone')}
                    onChange={(value) => form.setFieldsValue({ phone: value })}
                    language={i18n.language}
                    theme="mini"
                  />
                </FloatInputLabel>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle>
            <Button type="primary" shape="round" size="large" htmlType="submit" className={styles.buttonSubmit} block>
              Call me back
            </Button>
          </Form.Item>
        </Form>
      )}

      {loading && !error && (
        <div className={cn(styles.message, styles.loading)}>
          <LoadingOutlined className={styles.icon} size={24} />
          Loading
        </div>
      )}

      {data?.callMeRequest?.success && !loading && !error && (
        <div className={cn(styles.message, styles.success)}>
          <CheckOutlined className={styles.icon} size={24} />
          Thank you! We will get in touch with you soon.
        </div>
      )}

      {error && !loading && (
        <div className={cn(styles.message, styles.error)}>
          <CloseOutlined className={styles.icon} size={24} />
          Data sending error
        </div>
      )}
    </div>
  );
};

export default CallMe;
