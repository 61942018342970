type formFieldsLocalStorage = {
  startDate?: string;
  endDate?: string;
  guests?: number;
  flexibleDates?: boolean;
  captain?: boolean;
  comment?: string;
  buyBoatName?: string;
  buyBoatEmail?: string;
  buyBoatPhone?: string;
  buyBoatComment?: string;
};

interface ISetFieldsValues {
  (values: formFieldsLocalStorage): void;
}

interface IGetFieldsValues {
  (): formFieldsLocalStorage | undefined;
}

function useStoringFormFields(): { getFieldsValues: IGetFieldsValues; setFieldsValues: ISetFieldsValues } {
  const setFieldsValues: ISetFieldsValues = (values) => {
    localStorage.setItem('form-fields', JSON.stringify(values));
  };

  const getFieldsValues: IGetFieldsValues = () => {
    return JSON.parse(localStorage.getItem('form-fields') || '{}');
  };

  return {
    getFieldsValues,
    setFieldsValues,
  };
}

export default useStoringFormFields;
