import { InMemoryCache } from '@apollo/client';

import { userVar } from '../local/vars';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentUser: {
          read() {
            return userVar();
          },
        },
      },
    },
  },
});
