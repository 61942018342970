import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Image } from '$ui/index';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { formatCurrency } from '$utils/formatCurrency';
import { ManualSkeleton, Icon } from '$components/index';

import Specifications from './components/Specifications';

import { BoatCardBigRatingProps } from './types';
import styles from './BoatCardBigRating.module.less';
import picturePlaceholder from '$assets/img/placeholder.svg';

// TODO 2022 This is billet
const BoatCardBigRating = ({ item, isBuy, isSkeleton }: BoatCardBigRatingProps) => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { generatePath } = useGeneratePath();

  if (isSkeleton || !item) {
    return (
      <div className={styles.themeBigRating}>
        <div className={styles.card}>
          <div className={styles.photo}>
            <ManualSkeleton className={styles.skeletonPhoto} />
          </div>
          <div className={styles.skeletonInfo}>
            <ManualSkeleton className={styles.skeletonName} />
            {Array.from({ length: 4 }, (_, i) => (
              <ManualSkeleton key={i} className={styles.skeletonText} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Link
      to={{
        pathname: generatePath(isBuy ? routes.BUY_BOAT_VIEW : routes.BOAT_VIEW, {
          boatIdOrSlug: item.slug ? String(item.slug) : item.id,
        }),
        state: {
          prevPath: generatePath(isBuy ? routes.BUY_BOATS : routes.BOATS) + routerLocation.search,
        },
      }}
      className={styles.card}
    >
      <div className={styles.photo}>
        <Image
          className={styles.photo}
          alt={item.name ?? 'boat'}
          preview={false}
          fallback={picturePlaceholder}
          src={item.pictures[0]?.md || picturePlaceholder}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {item.name}
          {item.builtAt && ` (${item.builtAt})`}
        </div>
        {(item.location?.address || item.location?.country?.name) && (
          <ul className={styles.location}>
            {[item.location?.address, item.location?.country?.name]
              .filter((_) => _)
              .map((name) => (
                <li key={name} className={styles.item}>
                  {name}
                </li>
              ))}
          </ul>
        )}

        <Specifications
          items={[
            {
              type: 'boatLength',
              icon: 'boatLengthV2',
              value: item.length ? item.length : t('frontend.boat_card.n_a').toString(),
            },
            {
              type: 'persons',
              icon: 'personsV2',
              value: item.persons ? item.persons : t('frontend.boat_card.n_a').toString(),
            },
            {
              type: 'cabins',
              icon: 'cabinsV2',
              value: item.cabins ? item.cabins : t('frontend.boat_card.n_a').toString(),
            },
            {
              type: 'crewTotal',
              icon: 'capitanV2',
              value: item.crewTotal ? item.crewTotal : t('frontend.boat_card.n_a').toString(),
            },
          ]}
          className={styles.specifications}
        />

        <Specifications
          items={[
            {
              type: 'time',
              icon: 'timeV2',
              value: '4 - 6 hours',
            },
            {
              type: 'captained',
              icon: 'capV2',
              value: 'No captained',
            },
          ]}
          className={styles.specifications}
        />

        <div className={styles.rating}>
          <Icon name="rating" className={styles.icon} />
          {item.rating ? item.rating.toFixed(1) : '5.0'}
        </div>

        {!isBuy && !!item.exclusivePrice?.pricePerDay && !!item.exclusivePrice?.currency && (
          <div className={styles.price}>
            <div>
              From{' '}
              <span className={styles.value}>
                {formatCurrency(item.exclusivePrice.pricePerDay, item.exclusivePrice.currency)}
              </span>
            </div>
            <div>per day</div>
          </div>
        )}

        {isBuy && !!item.salePrice?.value && !!item.salePrice?.currency && (
          <div className={styles.price}>
            Sale price:{' '}
            <span className={styles.value}>{formatCurrency(item.salePrice.value, item.salePrice.currency)}</span>
          </div>
        )}
      </div>
    </Link>
  );
};

export default BoatCardBigRating;
