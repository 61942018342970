import cn from 'classnames';

import { Icon, ManualSkeleton } from '$components/index';

import { RatingProps } from './types';
import styles from './Rating.module.less';

const Rating = ({ rating, isSkeleton, className }: RatingProps) => {
  if (isSkeleton) {
    return <ManualSkeleton className={styles.skeleton} />;
  }

  return (
    <div className={cn(styles.main, className)}>
      <Icon name="rating" className={styles.icon} />
      {rating ? rating.toFixed(1) : '5.0'}
    </div>
  );
};

export default Rating;
