import cn from 'classnames';

import { formatCurrency } from '$utils/formatCurrency';
import { ManualSkeleton } from '$components/index';

import { PriceRentProps } from './types';
import styles from './PriceRent.module.less';

const PriceRent = ({ pricePerHour, pricePerDay, pricePerWeek, currency, isSkeleton, className }: PriceRentProps) => {
  if (isSkeleton) {
    return (
      <>
        <ManualSkeleton className={styles.skeletonTiny} />
        <ManualSkeleton className={styles.skeletonBig} />
      </>
    );
  }

  if (!currency) {
    return null;
  }

  const prices = [
    {
      label: 'per hour',
      value: pricePerHour,
    },
    {
      label: 'per day',
      value: pricePerDay,
    },
    {
      label: 'per week',
      value: pricePerWeek,
    },
  ]
    .filter((p) => p.value)
    .slice(0, 2);

  return (
    <>
      {prices.length >= 1 && (
        <div className={cn(styles.main, className)}>
          {prices[0]?.value && (
            <div className={styles.min}>
              {formatCurrency(prices[0].value, currency)} {prices[0].label}
            </div>
          )}
          {prices[1]?.value && (
            <div className={styles.big}>
              {formatCurrency(prices[1].value, currency)} {prices[1].label}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PriceRent;
