import { useState } from 'react';
import { Button, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { useCurrentUserLocalLazyQuery, useCreateCheckoutSessionMutation } from '$graphql/hooks';
import { AuthModal } from '$components/index';

import { RateProps, TLookupKey } from './types';
import styles from './Rate.module.less';

const Rate = ({ name, price, period, lookupKey, options, className }: RateProps) => {
  const [getCurrentUser] = useCurrentUserLocalLazyQuery();
  const [createCheckoutSession] = useCreateCheckoutSessionMutation({ errorPolicy: 'all' });

  const [isAuthModalVisible, setIsAuthModalVisible] = useState<boolean>(false);
  const [isNotCentralAgentModalVisible, setIsNotCentralAgentModalVisible] = useState<boolean>(false);

  const handleSelectRate = (lookupKey: TLookupKey) => {
    getCurrentUser().then((result) => {
      if (result.data?.currentUser.isCentralAgent) {
        const windowReference = window.open(); // Safari

        createCheckoutSession({ variables: { lookupKey: lookupKey } }).then((result) => {
          if (result.data?.createCheckoutSession?.sessionUrl && windowReference) {
            windowReference.location = result.data?.createCheckoutSession?.sessionUrl;
          }
        });
      } else if (result.data?.currentUser.isLoggedIn) {
        setIsNotCentralAgentModalVisible(true);
      } else {
        setIsAuthModalVisible(true);
      }
    });
  };

  return (
    <div className={cn(styles.main, className)}>
      <h2 className={styles.name}>{name}</h2>

      <div className={styles.price}>
        <span className={styles.value}>{price}</span>
        <span className={styles.time}> / {period === 'month' ? 'month' : 'per year'}</span>
      </div>

      <div className={styles.billed}>{period === 'month' ? 'Billed monthly' : 'Billed annually'}</div>

      <Button
        onClick={() => handleSelectRate(lookupKey)}
        type="primary"
        size="large"
        shape="round"
        className={styles.buttonSelect}
      >
        Select
      </Button>

      <ul className={styles.options}>
        {options.map((option) => (
          <li key={option.id} className={styles.option}>
            {option.value ? (
              <CheckOutlined className={cn(styles.icon, styles.included)} />
            ) : (
              <CloseOutlined className={cn(styles.icon, styles.notIncluded)} />
            )}
            {option.name}
          </li>
        ))}
      </ul>

      <AuthModal
        visible={isAuthModalVisible}
        handleChangeVisible={setIsAuthModalVisible}
        request={() => {
          handleSelectRate(lookupKey);
          setIsAuthModalVisible(false);
        }}
        isCentralAgent
      />

      <Modal
        visible={isNotCentralAgentModalVisible}
        onCancel={() => setIsNotCentralAgentModalVisible(false)}
        footer={
          <div className={styles.footer}>
            <Button type="primary" shape="round" href="mailto:info@getboat.com" target="_blank" rel="noreferrer">
              Contact us
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                setIsAuthModalVisible(true);
                setIsNotCentralAgentModalVisible(false);
              }}
            >
              Sign Up
            </Button>
          </div>
        }
        title="Subscription"
        className={styles.modal}
      >
        <p>
          To unlock the full potential of our subscription services, upgrade to a central agent account. Whether
          you&apos;re new here or already have an account, we&apos;ve got you covered. Simply register a new account or
          reach out to us via email to seamlessly transition your existing one. Let&apos;s take your experience to the
          next level!
        </p>
      </Modal>
    </div>
  );
};

export default Rate;
