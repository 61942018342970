import { useTranslation } from 'react-i18next';
import { Button } from '$ui/index';
import { CloseOutlined } from '@ant-design/icons';

import { ButtonsNumberInput } from '$components/index';

import { DaysSelectMenuProps } from './types';
import styles from './DaysSelectMenu.module.less';

const DaysSelectMenu = ({ daysCount, setDaysCount, setIsDaysSelectOpen }: DaysSelectMenuProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div className={styles.title}>{t('frontend.banner.arrival.menu.label')}</div>

      <ButtonsNumberInput
        currentValue={daysCount ? daysCount : 0}
        setCurrentValue={setDaysCount}
        className={styles.buttonsNumberInput}
      />

      <Button onClick={() => setDaysCount(7)} shape="round" block>
        {t('frontend.banner.arrival.menu.options.week')}
      </Button>

      <Button onClick={() => setDaysCount(14)} shape="round" block>
        {t('frontend.banner.arrival.menu.options.two_week')}
      </Button>

      <div className={styles.title}>Same day return</div>

      <Button
        onClick={() => {
          setDaysCount(0);
          setIsDaysSelectOpen(false);
        }}
        shape="round"
        block
      >
        Daytime charter
      </Button>

      <Button
        className={styles.close}
        shape="circle"
        type="text"
        icon={<CloseOutlined />}
        onClick={() => setIsDaysSelectOpen(false)}
      />
    </div>
  );
};

export default DaysSelectMenu;
