export const steps = [
  {
    key: 1,
    name: 'Make a request',
    icon: 'request',
    description: 'Select date and location, and tell us all your preferences!',
  },
  {
    key: 2,
    name: 'Get offers',
    icon: 'offers',
    description: 'Our tender engine selects best offers at the best prices!',
  },
  {
    key: 3,
    name: 'Book the best',
    icon: 'book',
    description: 'Select best deal and book!',
  },
];
