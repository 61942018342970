import { CancellationPolicyProps } from './types';
import styles from './CancellationPolicy.module.less';

const CancellationPolicy = ({ mainText }: CancellationPolicyProps) => {
  return (
    <>
      {!!mainText && (
        <div>
          <div className={styles.title}>Cancellation Policy</div>
          <div
            dangerouslySetInnerHTML={{
              __html: mainText
                .replace(/<(h1|h2|h3|h4|h5|h6)[^>]*>/gi, '<p>')
                .replace(/<\/(h1|h2|h3|h4|h5|h6)>/gi, '</p>')
                .replace(/<a[^>]*>/gi, ' ')
                .replace(/<\/a>/gi, ' '),
            }}
          />
        </div>
      )}
    </>
  );
};

export default CancellationPolicy;
