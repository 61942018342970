import cn from 'classnames';

import { MobileAppsProps } from './types';
import styles from './MobileApps.module.less';

const MobileApps = ({ items, className }: MobileAppsProps) => {
  return (
    <div className={cn(styles.main, className)}>
      {items.map((item) => (
        <a href={item.link} target="_blank" rel="noreferrer" key={item.name} className={styles.item}>
          <img src={item.img} alt={item.name} className={styles.img} style={{ width: item.width }} loading="lazy" />
        </a>
      ))}
    </div>
  );
};

export default MobileApps;
