import { Form } from '$ui/index';
import cn from 'classnames';

import { AbstractFormProps } from './types';
import styles from './Form.module.less';

const AbstractForm = ({
  onFinish,
  onFinishFailed,
  title,
  isModal,
  className,
  classNameWrapper,
  children,
}: AbstractFormProps) => {
  return (
    <div className={cn(classNameWrapper, styles.form)}>
      <div className={cn(!isModal && styles.wrap)}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <Form
          name="basic"
          initialValues={{ remember: true }}
          layout="horizontal"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={className}
        >
          {children}
        </Form>
      </div>
    </div>
  );
};

export default AbstractForm;
