import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import localization from '../translations';

export const supportedLanguages = ['en', 'de', 'fr'];

const currentLanguage = localStorage.getItem('lng') || 'en';

const resources: Record<string, typeof localization> = {};

supportedLanguages.forEach((language) => {
  resources[language] = {
    translation: localization.translations[language],
  };
});

i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage,
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
