import cn from 'classnames';

import { ManualSkeletonProps } from './types';
import styles from './ManualSkeleton.module.less';

const ManualSkeleton = ({ className, style }: ManualSkeletonProps) => {
  return <div className={cn(styles.manualSkeleton, className)} style={style} />;
};

export default ManualSkeleton;
