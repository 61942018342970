import { useState } from 'react';
import { Button, Drawer } from '$ui/index';
import { MenuOutlined } from '@ant-design/icons';

import { Navigation } from '$components/Header/components';
import styles from './MobileMenu.module.less';

const MobileMenu = () => {
  /*
  AntD(4.13.1 - 4.20.7)
  Hidden Drawer + LanguageSelector = render 20-50ms
  Hidden Drawer = render 12-20ms
  For test set default isLoad = true
  */

  const [isLoad, setIsLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // without async-await = first opening without animation
  const handleOpen = async () => {
    if (!isLoad) {
      await setIsLoad(true);
    }

    await setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div data-cy="MobileMenuComponent">
      <Button
        className={styles.buttonMobileMenu}
        type="text"
        size="large"
        icon={<MenuOutlined />}
        onClick={handleOpen}
        data-cy="mobileMenuButton"
      />
      {isLoad && (
        <Drawer
          placement="right"
          visible={isOpen}
          onClose={handleClose}
          /*title={<LanguageSelector />}*/
          data-cy="mobileMenu"
        >
          <Navigation onItemClick={handleClose} mode="vertical-left" />
        </Drawer>
      )}
    </div>
  );
};

export default MobileMenu;
