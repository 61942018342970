import { generatePath as generatePathReactRouter } from 'react-router-dom'; /* useLocation */
/* import { useTranslation } from 'react-i18next'; */

import { RouteParams } from '$router/types';

const useGeneratePath = () => {
  /*
  const { i18n } = useTranslation();
  const location = useLocation(); // Из-за этого hook не работает React.memo, временно выключил пока нет мультиязычности
  */

  const generatePath = (route: string, params?: RouteParams) => {
    /*
    const language = params?.lng ?? i18n.language;
    const path = location.pathname.toLocaleLowerCase();

    let isLongUrlEn = false;

    if ((path.length <= 3 && path.slice(1, 3) === 'en') || path.match(/^\/([en])\//gi)) {
      isLongUrlEn = true;
    }
    */

    const url = generatePathReactRouter(route.length > 1 && route.slice(-1) === '/' ? route.slice(0, -1) : route, {
      ...params,
      /* lng: language === 'en' && !isLongUrlEn ? undefined : language, */
    });

    return url === '' ? '/' : url;
  };

  return { generatePath };
};

export default useGeneratePath;
