import { memo } from 'react';
import MediaQuery from 'react-responsive';
import { Avatar } from '$ui/index';

import { Swiper, SwiperSlide, Rating } from '$components/index';

import { ReviewsProps, TReview } from './types';
import styles from './Reviews.module.less';

const Reviews = ({ items }: ReviewsProps) => {
  const Card = ({ item }: { item: TReview }) => (
    <div className={styles.item}>
      <div className={styles.head}>
        <Avatar style={{ backgroundColor: item.userColor ? item.userColor : undefined }} className={styles.avatar}>
          {item.userName.charAt(0).toUpperCase()}
        </Avatar>
        <Rating value={item.rating} theme="shadow" />
      </div>

      <div className={styles.name}>
        <strong>{item.userName}</strong>
        <span> reviewed </span>
        <strong>{item.boatName}</strong>
      </div>

      <div className={styles.text}>{item.text}</div>
    </div>
  );

  return (
    <div className={styles.list}>
      <MediaQuery maxWidth={1199}>
        <Swiper isNavigation={false} className={styles.swiper}>
          {items.map((item) => (
            <SwiperSlide key={item.id} className={styles.slide}>
              <Card item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MediaQuery>
      <MediaQuery minWidth={1200}>
        {items.map((item) => (
          <Card item={item} key={item.id} />
        ))}
      </MediaQuery>
    </div>
  );
};

export default memo(Reviews, (oldProps, newProps) => {
  return oldProps.items.map((i) => i.id).join(',') === newProps.items.map((i) => i.id).join(',');
});
