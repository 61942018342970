import { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { Swiper, SwiperSlide } from '$components/index';

import { categories } from './data';
import { CategoriesProps } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './Categories.module.less';

const Categories = ({ title, availableCategories, className }: CategoriesProps) => {
  const { generatePath } = useGeneratePath();

  const [isLazyImages, setIsLazyImages] = useState<boolean>(true);

  const resultCategories = availableCategories?.map((availableCategory) => {
    // Временное решение. Объядинение категорий, потанциальных и из data(из-за фото для карточного вида)
    const dataCategory = categories.find((i) => i.id === availableCategory.id);

    if (availableCategory.url && availableCategory.name && dataCategory) {
      return {
        id: availableCategory.id,
        name: availableCategory.name,
        url: availableCategory.url,
        img: dataCategory.img,
        backgroundColor: dataCategory.backgroundColor,
      };
    }

    return undefined;
  });

  return (
    <div className={cn(commonStyles.container, styles.container, className)}>
      <div className={styles.title}>Charter another yacht or boat type in {title}</div>
      <Swiper
        spaceBetween={28}
        onSliderFirstMove={() => {
          setIsLazyImages(false);
        }}
        isNavigation={false}
        className={styles.categories}
      >
        {resultCategories &&
          resultCategories
            .filter((i) => i)
            .map((category) => {
              if (category) {
                return (
                  <SwiperSlide key={category.id} className={styles.slide}>
                    <Link
                      to={{ pathname: generatePath(routes.LOCATION, { path: category.url }) }}
                      className={styles.category}
                    >
                      <div className={styles.photo}>
                        <picture style={{ backgroundColor: category.backgroundColor }}>
                          <source
                            type="image/webp"
                            media="(max-width: 767px)"
                            srcSet={`${category.img.mobile.x1.webp} 1x, ${category.img.mobile.x2.webp} 2x, ${category.img.mobile.x3.webp} 3x`}
                          />
                          <source
                            type="image/webp"
                            media="(min-width: 768px)"
                            srcSet={`${category.img.desktop.x1.webp} 1x, ${category.img.desktop.x2.webp} 2x, ${category.img.desktop.x3.webp} 3x`}
                          />
                          <img
                            src={category.img.desktop.x1.jpeg}
                            alt={category.name}
                            loading={isLazyImages ? 'lazy' : undefined}
                          />
                        </picture>
                      </div>
                      <div className={styles.name}>{category.name}</div>
                    </Link>
                  </SwiperSlide>
                );
              }

              return <></>;
            })}
      </Swiper>
    </div>
  );
};

export default Categories;
