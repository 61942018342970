type TReviews = {
  id: string;
  rating: number;
  userName: string;
  userColor?: string;
  boatName: string;
  text: string;
};

const reviews: Array<TReviews> = [
  {
    id: '1',
    rating: 4,
    userName: 'Nick D',
    userColor: '#a9a9a9',
    boatName: 'Yacht Beluga',
    text: 'It was easy to find some decent and affordable watercraft for our voyage. We booked the one that best suited us and almost immediately received all the necessary documents. At one point I had a question regarding travel costs and contacted support via the chat on the homepage. I got my answer immediately and everything was explained to me in great detail.',
  },
  {
    id: '2',
    rating: 5,
    userName: 'Sara L',
    userColor: '#D0EECE',
    boatName: 'Cayman boat',
    text: 'Everything was smooth and relaxing! I think this voyage was overall a great experience.',
  },
  {
    id: '3',
    rating: 5,
    userName: 'Norman B',
    userColor: '#DCE7F9',
    boatName: 'Cayman boat',
    text: 'I would like to thank my agent Anne for providing me with as much support as possible! The quality of service is top-notch.',
  },
  {
    id: '4',
    rating: 4,
    userName: 'Sam W',
    boatName: 'Dream Yacht',
    text: 'The website is extremely easy to use! I would like to commend the Comparisons feature. It was very useful and the differences between each option were clearly shown. While I have been annoyed by many reminders from other companies, the Aardy reminders were quite useful since they referred back to the specific search that was done.',
  },
  {
    id: '5',
    rating: 4,
    userName: 'Jess D',
    boatName: 'Black boat',
    text: "Great customer service! I contacted Joe via chat, and he encouraged me to allow him to have an agent call me. Sylvia was my agent, and she helped me purchase my travel insurance. I didn't exactly know what questions I wanted to ask, but she expertly guided me through all the procedures. If it wasn’t for her, I would’ve had a much harder time understanding all the details.",
  },
  {
    id: '6',
    rating: 5,
    userName: 'John M',
    userColor: '#ad8f9c',
    boatName: 'Larson boat',
    text: 'The agent was more than helpful, especially since I don’t know much about travel insurance policies. I called several times and asked for the same agent. He made everything as smooth and transparent as possible.',
  },
];

export default reviews;
