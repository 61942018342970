import { useEffect, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import cn from 'classnames';
import { Button, Input, Form, message } from '$ui/index';

import { useBuyBoatMutation, useCurrentUserLocalQuery } from '$graphql/hooks';
import { useStoringFormFields, useUtmTags } from '$hooks/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { Icon } from '$components/index';
import LinkUserAgreement from '$components/LinkUserAgreement';

import { FormArgs, BoatPageFormSaleProps } from './types';
import styles from './FormSale.module.less';

const BoatPageFormSale = ({ boat }: BoatPageFormSaleProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();

  const { data: user } = useCurrentUserLocalQuery();
  const utmTags = useUtmTags().get();
  const sendDataToGTM = useGTMDispatch();

  const [fetchBuyBoat] = useBuyBoatMutation();
  const [sendingData, setSendingData] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const { getFieldsValues, setFieldsValues } = useStoringFormFields();
  const storingValues = getFieldsValues();
  const [form] = Form.useForm();

  /*
   TODO 2021 useEffect - временный костыль. Форма Antd не обновляеться если обновились initialValues,
   ставить if и ждать пока все данные "созреют" не возможно,
   так как будет ситуация? что Form.useForm() будет без вёрстке, а это выдаёт ошибку.
   */

  useEffect(() => {
    form.setFieldsValue({ email: user && user.currentUser.email ? user.currentUser.email : '' });
  }, [form, user]);

  const initialValues = {
    name: storingValues?.buyBoatName ? storingValues.buyBoatName : '',
    email: user && user.currentUser.email ? user.currentUser.email : '',
    phone: storingValues?.buyBoatPhone ? storingValues.buyBoatPhone : '',
    comment: storingValues?.buyBoatComment ? storingValues.buyBoatComment : '',
  };

  const formChange = (_?: FormEvent<HTMLFormElement>) => {
    //, dataProps?: dataFormType
    const dataForm = form.getFieldsValue();

    const values = {
      buyBoatName: dataForm?.name,
      buyBoatEmail: dataForm?.email,
      buyBoatPhone: dataForm?.phone,
      buyBoatComment: dataForm.comment,
    };

    setFieldsValues(values);
  };

  const onFormComplete = (values: FormArgs) => {
    requestBuyBoat(values);
  };

  const requestBuyBoat = async (values?: FormArgs) => {
    if (values && !sendingData) {
      setSendingData(true);

      try {
        setSendingData(true);

        // TODO PPL boatLink
        const { data, errors } = await fetchBuyBoat({
          variables: {
            userName: values.name,
            userEmail: values.email,
            userPhone: values.phone,
            userPrice: values.userPrice,
            boatPrice: boat?.salePrice?.value ? boat?.salePrice?.value : 0,
            boatPriceCurrency: boat?.salePrice?.currency ? boat?.salePrice?.currency : '',
            boatId: String(boat?.id),
            boatLink: generatePath(routes.BOAT_VIEW, {
              boatIdOrSlug: boat?.slug ? boat?.slug : `${boat?.id}`,
            }),
            utms: utmTags ? JSON.stringify(utmTags) : '',
            // comment: values.comment || '',
          },
        });

        if (!errors && data?.buyBoat?.success === true) {
          sendDataToGTM({ event: 'submit_ga_boat_sale_page_form' });
          setFormSuccess(true);
        } else {
          message.error(t('Server error'));
        }
      } catch (err) {
        message.error('Server error');
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={(values) => onFormComplete(values)}
      onChange={formChange}
      initialValues={initialValues}
      className={cn(styles.form, 'ga_boat_sale_page_form')}
    >
      <div className={styles.wrapperInput}>
        <Form.Item name="userPrice" noStyle>
          <Input
            className={styles.input}
            placeholder="Offer your price"
            size="large"
            disabled={formSuccess}
            allowClear
          />
        </Form.Item>
      </div>
      <div className={styles.wrapperInput}>
        <Form.Item name="name" noStyle>
          <Input className={styles.input} placeholder="Name" size="large" disabled={formSuccess} allowClear />
        </Form.Item>
      </div>
      <div className={styles.wrapperInput}>
        <Form.Item name="phone" noStyle>
          <Input className={styles.input} placeholder="Phone number" size="large" disabled={formSuccess} allowClear />
        </Form.Item>
      </div>

      <div className={styles.wrapperInput}>
        <Form.Item name="email" noStyle>
          <Input className={styles.input} placeholder="E-mail" size="large" disabled={formSuccess} allowClear />
        </Form.Item>
      </div>

      {/*
      <div className={styles.wrapperInput}>
        <Form.Item name="comment" noStyle>
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder={t('frontend.request_page.comments')}
            maxLength={200}
            showCount
            disabled={formSuccess}
          />
        </Form.Item>
      </div>
      */}

      {!formSuccess ? (
        <div className={styles.wrapperSubmit}>
          <div className={styles.wrapperInput}>
            <Form.Item noStyle>
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                loading={sendingData}
                className="ga_boat_sale_page_send_button"
                block
              >
                Send request
              </Button>
            </Form.Item>
          </div>
          <LinkUserAgreement clickText="Check availability" className={styles.linkUserAgreement} />
        </div>
      ) : (
        <div>
          <p>
            <Icon name="check" className={styles.iconCheck} /> Thank you. We will contact you shortly. Please fill free
            to submit requests to any boats you are interested in.
          </p>
        </div>
      )}
    </Form>
  );
};

export default BoatPageFormSale;
