import cn from 'classnames';

import { DescriptionProps } from './types';
import styles from '$pages/LocationPage/LocationPage.module.less';

const Description = ({ title, children, className }: DescriptionProps) => {
  return (
    <div className={cn(className, styles.block)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{children}</div>
    </div>
  );
};

export default Description;
