import { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Pagination, Button, Result } from '$ui/index';
import cn from 'classnames';

import { useSearchBoatsQuery, useCurrentUserLocalQuery } from '$graphql/hooks';
import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { BoatCard, ManualSkeleton } from '$components/index';

import FilterSidebar from './components/FilterSidebar';
import { BoatListPageProps, BoatListPageSearchParams } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './BoatListPage.module.less';

const BoatListPage = ({ isBuy }: BoatListPageProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const { search } = useLocation();
  const routerHistory = useHistory();
  const urlSearchParams = parseUrlQuery(search) as BoatListPageSearchParams;
  const { data: currentUser } = useCurrentUserLocalQuery();

  const [boatCategories, setBoatCategories] = useState<Array<string>>(
    urlSearchParams && urlSearchParams.cat ? [...urlSearchParams.cat] : []
  );

  const [locationName, setLocationName] = useState<string>(urlSearchParams?.l || '');
  const [page, setPage] = useState(urlSearchParams?.page ? +urlSearchParams.page : 1);
  const [condition, setCondition] = useState<number | undefined>(undefined);

  const perPage = 12;

  const { data, loading, error } = useSearchBoatsQuery({
    variables: {
      locationName,
      boatCategories,
      page,
      condition,
      perPage,
      providedFor: isBuy ? 1 : 2,
    },
  });

  const paginate = useCallback((page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  }, []);

  /* When using fields Filters for URL-query */
  const searchParams = new URLSearchParams();
  searchParams.append('page', String(page));

  if (locationName) {
    searchParams.append('l', locationName);
  }

  if (boatCategories.length) {
    boatCategories.forEach((cat) => searchParams.append('cat', cat));
  }

  const searchParamsString = searchParams.toString();
  const redirectUrl = generatePath(isBuy ? routes.BUY_BOATS : routes.BOATS);

  useEffect(() => {
    routerHistory.push({
      pathname: redirectUrl,
      search: searchParamsString,
    });
  }, [redirectUrl, searchParamsString, routerHistory]);
  /**/

  if (error) {
    return (
      <Result
        status="500"
        title="Connection error"
        subTitle="Please check your internet connection and reload the page."
        extra={
          <Button type="primary" onClick={() => routerHistory.push(generatePath(routes.ROOT))}>
            {t('frontend.p404.return')}
          </Button>
        }
      />
    );
  }

  return (
    <>
      {isBuy ? (
        <Helmet>
          <title>Boat for sale - buy yachts for sale online</title>
          <meta name="description" content="Find your dream vessel and embark on new maritime adventures." />
        </Helmet>
      ) : (
        <Helmet>
          <title>Boat listing - getboat.com</title>
          <meta name="description" content="Range of boats and yachts for rent or sale on GetBoat." />
        </Helmet>
      )}

      <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.container)}>
        <div className={styles.wrapperFilterAndResult}>
          <div className={styles.wrapperFilter}>
            <FilterSidebar
              locationName={locationName}
              setLocationName={(name) => {
                setLocationName(name);
                paginate(1);
              }}
              showCondition={isBuy}
              condition={condition}
              setCondition={(condition) => {
                setCondition(condition);
                paginate(1);
              }}
              boatCategories={boatCategories}
              setBoatCategories={(categories) => {
                setBoatCategories(categories as Array<string>);
                paginate(1);
              }}
              isBuy={isBuy}
            />
          </div>

          <div className={styles.wrapperResult}>
            {currentUser?.currentUser.isAdmin && (
              <div className={styles.numberFound}>
                {data ? (
                  `${t('frontend.boat_list.found_number_of')}: ${data.searchBoats.meta.totalCount} ${t(
                    'frontend.boat_list.xxx_of_boats'
                  )}`
                ) : (
                  <ManualSkeleton className={styles.skeletonNumberFound} />
                )}
              </div>
            )}
            {isBuy && <h1>Boats and yachts for sale</h1>}
            <div className={styles.wrapperBoatList}>
              {data && !loading
                ? data.searchBoats.items.map((boat) => <BoatCard key={boat.id} item={boat} isBuy={isBuy} isNewSize />)
                : Array.from({ length: perPage }, (_, i) => <BoatCard key={i} />)}
            </div>
          </div>
        </div>

        {data && data.searchBoats.meta.totalCount > perPage && (
          <div className={styles.pagination}>
            <Pagination
              total={data?.searchBoats.meta.totalCount}
              current={page}
              defaultPageSize={perPage}
              onChange={paginate}
              showSizeChanger={false}
              responsive
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BoatListPage;
