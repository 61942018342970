import { Button, Input } from '$ui/index';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { ButtonsNumberInputProps } from './types';

const ButtonsNumberInput = ({
  startsFrom = 0,
  currentValue = 0,
  setCurrentValue,
  className,
}: ButtonsNumberInputProps) => {
  return (
    <div className={className}>
      <Button disabled={currentValue === startsFrom} onClick={() => setCurrentValue(currentValue - 1)}>
        <MinusOutlined />
      </Button>
      <Input readOnly style={{ width: 46, textAlign: 'center' }} value={currentValue > 0 ? currentValue : ''} />
      <Button onClick={() => setCurrentValue(currentValue + 1)}>
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default ButtonsNumberInput;
