import { useLocation } from 'react-router-dom';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { LandingPageSearchParams } from '$pages/LandingPage/types';

const useSearchLink = (routeAlias: 'buyBoats'): string => {
  const location = useLocation();
  const urlQuery = parseUrlQuery(location.search) as LandingPageSearchParams;

  const searchParams = new URLSearchParams();

  switch (routeAlias) {
    case 'buyBoats': {
      if (urlQuery?.location) {
        searchParams.append('l', urlQuery?.location);
      }

      if (urlQuery?.l) {
        searchParams.append('l', urlQuery?.l);
      }

      // TODO 2022 переделать на cat[]
      if (urlQuery?.cat && typeof urlQuery.cat === 'string') {
        searchParams.append('cat', urlQuery.cat);
      }

      if (urlQuery?.cat && typeof urlQuery.cat === 'object' && urlQuery.cat.length > 1) {
        urlQuery.cat.forEach((category) => searchParams.append('cat', category));
      }
    }
  }

  return searchParams.toString();
};

export default useSearchLink;
