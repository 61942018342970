import cn from 'classnames';

import { LinkUserAgreementProps } from './types';
import styles from './LinkUserAgreement.module.less';

const LinkUserAgreement = ({ clickText, className }: LinkUserAgreementProps) => {
  return (
    <div className={cn(styles.main, className)}>
      By clicking <q>{clickText}</q>, you agree to the{' '}
      <a href="https://getboat.com/yachts/service-agreement/" target="_blank" rel="noreferrer" className={styles.link}>
        Agreement on the use of the service.
      </a>
    </div>
  );
};

export default LinkUserAgreement;
