import { useRouteMatch } from 'react-router-dom';

import { routes } from '$router/index';

const Cookies = () => {
  const isRequestOffersPage = useRouteMatch(routes.REQUEST_OFFERS)?.isExact;
  const isPaymentViewPage = useRouteMatch(routes.PAYMENT_VIEW)?.isExact;
  const isBoatOfferViewPage = useRouteMatch(routes.BOAT_OFFER_VIEW)?.isExact;
  const isChartersPage = useRouteMatch(routes.CHARTERS)?.isExact;
  const isCharterViewPage = useRouteMatch(routes.CHARTER_VIEW)?.isExact;
  const isBookingViewPage = useRouteMatch(routes.BOOKING_VIEW)?.isExact;

  if (
    !(
      isRequestOffersPage ||
      isPaymentViewPage ||
      isBoatOfferViewPage ||
      isChartersPage ||
      isCharterViewPage ||
      isBookingViewPage
    )
  ) {
    const keyValuePairs = window.location.search.slice(1).split('&');
    const d = new Date();
    const dias = 30; /* Cookie Window */
    d.setTime(d.getTime() + dias * 24 * 60 * 60 * 1000);

    const expires = 'expires=' + d.toUTCString();

    keyValuePairs.forEach(function (keyValuePair) {
      const keyValuePairArray = keyValuePair.split('=');

      switch (keyValuePairArray[0]) {
        case 'click_id':
          document.cookie = 'clickCityAdsID=' + keyValuePairArray[1] + '; ' + expires + ';domain=.getboat.com;path=/';
          break;

        case 'utm_source':
          document.cookie = 'origem=' + keyValuePairArray[1] + '; ' + expires + ';domain=.getboat.com;path=/';
          break;

        case 'gclid':
          document.cookie = 'origem=adwords;' + expires + ';domain=.getboat.com;path=/';
          break;
      }
    });
  }

  return <></>;
};

export default Cookies;
