import { useEffect } from 'react';
import { Layout } from '$ui/index';
import { PersistGate } from 'zustand-persist';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { Helmet } from 'react-helmet-async';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import './assets/styles/index.less';
import useSession from '$stores/session';
import { requestFirebaseNotificationPermission } from '$utils/firebase';

import {
  useAddFirstGuestVisitMutation,
  useAddWebPushTokenMutation,
  useCurrentUserLocalQuery
} from '$graphql/hooks';
import { useUserParametersFromUrl, useProfile, useUtmTags, useSearchLink } from '$hooks/index';

import { Footer, Header } from '$components/index';
import UserGtm from '$components/UserGtm';
import UserAgentGtm from "$components/UserAgentGtm";
import { RouterView } from './router';
import styles from './App.module.less';

const App = () => {
  const { session } = useSession();

  useProfile();

  const [addWebPushToken] = useAddWebPushTokenMutation();

  const { parseUserParameters } = useUserParametersFromUrl();
  const [fetchAddFirstGuestVisit] = useAddFirstGuestVisitMutation();

  useUtmTags().set();
  const utmTags = useUtmTags().get();

  const { data: currentUser } = useCurrentUserLocalQuery();
  const searchParamsForLinkBuyBoats = useSearchLink('buyBoats');

  const loadingCurrentUser = useSession.getState()?.session?.token && !currentUser?.currentUser.id;

  useEffect(() => {
    parseUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Logging for CityAds
  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      await fp.get().then(result => {
        fetchAddFirstGuestVisit({
          variables: {
            browserId: result.visitorId,
            utmCampaign: utmTags?.utmCampaign,
            utmContent: utmTags?.utmContent,
            utmMedium: utmTags?.utmMedium,
            utmReferralLink: utmTags?.utmReferralLink,
            utmSource: utmTags?.utmSource,
            utmTerm:  utmTags?.utmTerm,
          }
        });
      });
    };

    setFp();
  }, [ fetchAddFirstGuestVisit, utmTags?.utmCampaign, utmTags?.utmContent, utmTags?.utmMedium, utmTags?.utmReferralLink, utmTags?.utmSource, utmTags?.utmTerm]);

  useEffect(() => {
    if (session) {
      const initWebPushNotifications = async () => {
        try {
          const token = await requestFirebaseNotificationPermission();

          addWebPushToken({
            variables: { token: token as string },
            context: {
              errorPolicy: 'IgnoreErrorsQuery',
            },
          });
        } catch (err) {
          // Если юзер, например, заблочил нотификации
          return err;
        }
      };

      initWebPushNotifications();
    }
  }, [addWebPushToken, session]);

  const getGtmId = ():  { id: string, dataLayerName: string } | undefined => {
    switch (window.location.hostname) {
      case 'getboat.com':
      case 'travelpeople.getboat.com':
        if (process.env.REACT_APP_GTM_ID) {
          return { id: process.env.REACT_APP_GTM_ID, dataLayerName: 'dataLayer' };
        }
        return undefined;
      case 'staging.getboat.com':
      case 'staging-travelpeople.getboat.com':
        if (process.env.REACT_APP_GTM_ID_STAGING) {
          return { id: process.env.REACT_APP_GTM_ID_STAGING, dataLayerName: 'dataLayer' };
        }
        return undefined;
      default:
        return undefined;
    }
  };

  return (
    <GTMProvider state={getGtmId()}>
      <PersistGate>
        <Helmet>
          <title>Rent a Yacht with GetBoat. Yacht charters, Boat rentals in Europe, US, Caribbean</title>
          {window.location.hostname !== 'getboat.com' && (<meta name="robots" content="noindex, nofollow" />)}
        </Helmet>
        {process.env.REACT_APP_GTM_ID && <UserGtm />}
        {process.env.REACT_APP_GTM_ID && <UserAgentGtm />}
        <Layout>
          <Layout.Header className={styles.layoutHeader}>
            <Header
              currentUser={currentUser?.currentUser}
              searchParamsForLinkBuyBoats={searchParamsForLinkBuyBoats}
              loading={!!loadingCurrentUser}
            />
          </Layout.Header>

          <Layout.Content className={styles.layoutContent}>
            <RouterView />
          </Layout.Content>

          <Layout.Footer className={styles.layoutFooter}>
            <Footer />
          </Layout.Footer>
        </Layout>
      </PersistGate>
    </GTMProvider>
  );
};

export default App;
