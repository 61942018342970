import { routes } from '$router/index';

import { TContact } from './components/Contacts/types';
import { TLink } from './components/LinkList/types';
import { TMobileApp } from '$components/Footer/components/MobileApps/types';
import { TSocialNetwork } from './components/SocialNetworks/types';

import appleStore from './assets/apps/apple_store.svg';
import googlePlay from './assets/apps/google_play.svg';
import spriteSocialNetworks from './assets/socialNetworks/sprite.svg';

export const contacts: Array<TContact> = [
  {
    key: 1,
    type: 'phone',
    labelI18n: 'frontend.footer.contacts.phone.title.us',
    text: '+1 305 614 7090',
    link: 'tel:+13056147090',
  },
  {
    key: 2,
    type: 'phone',
    labelI18n: 'frontend.footer.contacts.phone.title.en',
    text: '+32 460 219 999',
    link: 'tel:+32460219999',
  },
  {
    key: 3,
    type: 'email',
    labelI18n: 'frontend.footer.contacts.email.title',
    text: 'info@getboat.com',
    link: 'mailto:info@getboat.com',
  },
  {
    key: 4,
    type: 'address',
    label: 'Address',
    text: 'GETBOAT GLOBAL INC., Wilmington, New Castle County, 251 Little Falls Drive, DE 19808',
  },
  {
    key: 5,
    type: 'address',
    text: 'GLOBAL ONLINE TRAVEL LLC 0070, ARMENIA, YEREVAN, YERVAND KOCHAR STR, 23/2 REG NUM 271.110.1183229 TIN 00238516',
  },
];

export const services: Array<TLink> = [
  {
    key: 1,
    titleI18n: 'frontend.footer.services.rent',
    route: routes.REQUEST_OFFERS,
  },
  {
    key: 2,
    titleI18n: 'frontend.header.menu.charter',
    route: routes.BOATS,
    query: 'not-query',
  },
  {
    key: 3,
    titleText: 'Sales',
    route: routes.BUY_BOATS,
    query: 'not-query',
  },
  {
    key: 5,
    titleI18n: 'frontend.footer.services.blog',
    linkExternal: 'https://newsgetboat.com/',
  },
  {
    key: 6,
    titleI18n: 'frontend.footer.services.list_your_boat',
    route: routes.SIGN_UP,
    query: 'iam=ca',
  },
  {
    key: 7,
    titleText: 'Car rental',
    linkExternal: 'https://getrentacar.com/?utm_source=GetBoat&utm_medium=referral&utm_campaign=footer_menu',
  },
  {
    key: 8,
    titleText: 'Book a transfer',
    linkExternal: 'https://gettransfer.com/?utm_source=GetBoat&utm_medium=referral&utm_campaign=footer_menu',
  },
  {
    key: 9,
    titleI18n: 'frontend.footer.services.experiences',
    linkExternal: 'https://getexperience.com/?utm_source=GetBoat&utm_medium=referral&utm_campaign=footer_menu',
  },
];

export const mobileApps: Array<TMobileApp> = [
  {
    link: 'https://apps.apple.com/ru/app/get-boat/id1153410214',
    name: 'Apple Store',
    img: appleStore,
    width: '95px',
  },
  {
    link: 'https://play.google.com/store/apps/details?id=zs.getboat',
    name: 'Google Play',
    img: googlePlay,
    width: '107px',
  },
];

export const sitemaps: Array<TLink> = [
  {
    key: 1,
    titleI18n: 'frontend.footer.site_map.why',
    linkInterior: '/why_us',
  },
  {
    key: 2,
    titleI18n: 'frontend.footer.site_map.faq',
    linkInterior: '/questions',
  },
  {
    key: 3,
    titleI18n: 'frontend.footer.site_map.partnership',
    linkInterior: '/pages/partnership',
  },
  {
    key: 4,
    titleI18n: 'frontend.footer.site_map.privacy_policy',
    linkExternal: 'https://getboat.com/yachts/privacy-policy/',
  },
  {
    key: 5,
    titleText: 'Service agreement',
    linkExternal: 'https://getboat.com/yachts/service-agreement/',
  },
  {
    key: 6,
    titleText: 'Service license contract',
    linkExternal: 'https://getboat.com/yachts/service-license-contract/',
  },
  {
    key: 7,
    titleText: 'Service partner agreement',
    linkExternal: 'https://getboat.com/yachts/partner-service-agreement/',
  },
  {
    key: 8,
    titleI18n: 'frontend.footer.site_map.data_processing',
    linkExternal: 'https://getboat.com/yachts/consent-to-personal-data-processing/',
  },
  {
    key: 9,
    titleI18n: 'frontend.footer.site_map.sign_in',
    route: routes.SIGN_IN,
    subLink: {
      titleI18n: 'frontend.footer.site_map.sign_up',
      route: routes.SIGN_UP,
    },
  },
];

export const socialNetworks: Array<TSocialNetwork> = [
  {
    link: 'https://facebook.com/getboatcom',
    name: 'facebook',
    icon: spriteSocialNetworks + '#facebook',
    width: 21,
  },
  {
    link: 'https://www.instagram.com/getboat__com/',
    name: 'instagram',
    icon: spriteSocialNetworks + '#instagram',
    width: 21,
  },
  {
    link: 'https://www.youtube.com/watch?v=zb8OjLam0vE',
    name: 'youtube',
    icon: spriteSocialNetworks + '#youtube',
    width: 29,
  },
  {
    link: 'https://www.linkedin.com/company/getboat-com/',
    name: 'linkedin',
    icon: spriteSocialNetworks + '#linkedin',
    width: 22,
  },
  {
    link: 'https://newsgetboat.com',
    name: 'blog',
    icon: spriteSocialNetworks + '#blog',
    width: 44,
  },
];

export const copyright =
  '©GETBOAT GLOBAL INC. GETBOAT™ GET LIVING™, are trademarks of GETBOAT GLOBAL INC. All rights reserved.';
