import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '$ui/index';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { ManualSkeleton } from '$components/index';

import { BreadcrumbComponentProps } from './types';
import styles from './Breadcrumb.module.less';

const BreadcrumbComponent = ({ items, isSkeleton }: BreadcrumbComponentProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();

  if (isSkeleton) {
    return <ManualSkeleton className={styles.skeleton} />;
  }

  if (items) {
    return (
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link to={generatePath(routes.ROOT)}>
            {localStorage.getItem('hidden_seo') === 'true' ? 'Home' : t('frontend.breadcrumb.main_page')}
          </Link>
        </Breadcrumb.Item>
        {items?.map((item, index) => (
          <Breadcrumb.Item key={item.url || 'notUrl_' + index}>
            {item.url ? (
              <Link to={item.url}>{item.title}</Link>
            ) : (
              <>{item.tag === 'h1' ? <h1>{item.title}</h1> : <>{item.title}</>}</>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }

  return null;
};

export default BreadcrumbComponent;
