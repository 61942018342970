import cn from 'classnames';

import { Icon, ManualSkeleton } from '$components/index';

import { LocationProps } from './types';
import styles from './Location.module.less';

const Location = ({ address, country, isSkeleton, className }: LocationProps) => {
  if (isSkeleton) {
    return <ManualSkeleton className={styles.skeleton} />;
  }

  if (!address || !country) {
    return <></>; // Skeleton
  }

  return (
    <div className={cn(styles.main, className)}>
      <Icon name="location" className={styles.icon} />
      {[address, country].filter((i) => i).join(', ')}
    </div>
  );
};

export default Location;

/*
t('frontend.boat_card.no_address')
t('frontend.boat_card.no_country')
 */
