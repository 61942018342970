/*
import { useState } from 'react';
import {
  CheckOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  LikeOutlined,
  LineChartOutlined,
  PictureOutlined,
  SearchOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons/lib/icons';
import cn from 'classnames';

import MascotImage from './assets/mascot.svg';

import styles from './AdvantagesTabs.module.less';
*/

const AdvantagesTabs = () => {
  return <></>;

  /*
  const [currentTab, setCurrentTab] = useState<number | null>(1);

  const arrayData = [
    {
      id: 1,
      name: 'Renter',
      steps: [
        {
          id: 1,
          icon: <EditOutlined />,
          title: 'Make a request',
          text: 'Mention everything you expect from the boat, including size, price, capacity, and any other preferences.',
        },
        {
          id: 2,
          icon: <UnorderedListOutlined />,
          title: 'Get offers',
          text: 'You will receive offers online from real and verified owners.',
        },
        {
          id: 3,
          icon: <CheckOutlined />,
          title: 'Choose the best',
          text: 'Among all the good boats, pick the most suitable one for you.',
        },
        {
          id: 4,
          icon: <LikeOutlined />,
          title: 'Start your trip',
          text: 'Enjoy your ultimate water adventure!',
        },
      ],
    },
    {
      id: 2,
      name: 'Owner',
      steps: [
        {
          id: 5,
          icon: <PictureOutlined />,
          title: 'Make it work',
          text: "Sign up and upload your boat's information and high-quality photos on our website to start.",
        },
        {
          id: 6,
          icon: <SearchOutlined />,
          title: 'Check the area',
          text: 'Get notifications about inquiries in your area and conduct research to stay ahead of the game.',
        },
        {
          id: 7,
          icon: <LineChartOutlined />,
          title: 'Offer the best',
          text: 'Make appealing offers with the most favorable terms to attract more customers and provide them with the best service.',
        },
        {
          id: 8,
          icon: <TeamOutlined />,
          title: 'We are one team',
          text: 'GetBoat will provide a steady flow of clients and take care of promotion of your boats, allowing you to focus on your rental business!',
        },
      ],
    },
  ];

  const handleTabsChange = (id: number) => {
    const el = document.getElementById('js-tabs-block-content');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    setCurrentTab(id);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.menu}>
        <div className={styles.mascot}>
          <img src={MascotImage} alt="Mascot" />
        </div>
        <div className={styles.panelNavs}>
          <div className={styles.text}>
            <h2>Simple guide for…</h2>
          </div>
          <div className={styles.navs}>
            {arrayData.map((tab) => (
              <div
                key={tab.id}
                onClick={() => handleTabsChange(tab.id)}
                className={cn(styles.nav, currentTab === tab.id && styles.active)}
              >
                {tab.name}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.content} id="js-tabs-block-content">
        <div className={styles.steps}>
          {arrayData
            .find((tab) => currentTab === tab.id)
            ?.steps?.map((step) => (
              <div key={step.id} className={styles.step}>
                <div className={styles.title}>
                  <span className={styles.icon}>{step.icon}</span>
                  {step.title}
                </div>
                <div className={styles.text}>{step.text}</div>
              </div>
            ))}
        </div>
        <div className={styles.support}>
          <IssuesCloseOutlined className={styles.icon} />
          We are available 24/7 and fully prepared to support you throughout the booking process.
        </div>
      </div>
    </div>
  );
  */
};

export default AdvantagesTabs;
