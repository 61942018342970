import { useTranslation } from 'react-i18next';
import { Form, Checkbox } from '$ui/index';

import { FiltersProps } from './types';

import styles from './Filters.module.less';

import { PictureSource } from '$components/index';

const Filters = ({ boatCategories, selectedBoatCategories, setSelectedBoatCategories, isSkeleton }: FiltersProps) => {
  const { t } = useTranslation();

  if (isSkeleton) {
    return <></>;
  }

  return (
    <Form
      layout="vertical"
      initialValues={{
        selectedBoatCategories: selectedBoatCategories,
      }}
      className={styles.form}
    >
      <Form.Item label={t('frontend.request_page.boat_types')} noStyle name="selectedBoatCategories">
        <Checkbox.Group
          onChange={(values) => setSelectedBoatCategories(values as Array<string>)}
          className={styles.categories}
        >
          {boatCategories?.map((category) => (
            <div key={category.id} className={styles.category}>
              <Checkbox id={category.id} value={category.id} />
              <label htmlFor={category.id} className={styles.label}>
                {category.picture && (
                  <PictureSource
                    x1={category.picture.w85}
                    x2={category.picture.w170}
                    x3={category.picture.w255}
                    x4={category.picture.w340}
                    alt={'category ' + category.name}
                    className={styles.icon}
                  />
                )}
              </label>
              <label htmlFor={category.id} className={styles.label}>
                {category.name}
              </label>
            </div>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

export default Filters;
