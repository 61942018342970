import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Icon, ManualSkeleton } from '$components/index';

import { BoatIconsProps, TOptions } from './types';
import styles from './BoatIcons.module.less';

const BoatIcons = ({ boat, size, isSkeleton, className }: BoatIconsProps) => {
  const { t } = useTranslation();

  if (isSkeleton) {
    return <ManualSkeleton className={styles.skeleton} />;
  }

  const options: Array<TOptions> = [
    {
      name: 'boatLength',
      icon: 'boatLength',
      value: boat?.length ? boat.length + ' ft' : t('frontend.boat_page.n_a').toString(),
    },
    {
      name: 'persons',
      icon: 'persons',
      value: boat?.persons ? boat.persons : t('frontend.boat_card.n_a').toString(),
    },
    {
      name: 'cabins',
      icon: 'cabins',
      value: boat?.cabins ? boat.cabins : t('frontend.boat_card.n_a').toString(),
    },
    {
      name: 'crewTotal',
      icon: 'capitan',
      value: boat?.crewTotal ? boat.crewTotal : t('frontend.boat_card.n_a').toString(),
    },
  ];

  return (
    <div className={cn(styles.parameters, className)}>
      {options.map((option) => (
        <div key={option.name} className={cn(styles.parameter, size === 'low' && styles.low)}>
          <Icon name={option.icon} className={cn(styles.icon, size === 'low' && styles.low)} />
          {option.value}
        </div>
      ))}
    </div>
  );
};

export default BoatIcons;
