import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '$ui/index';
import cn from 'classnames';

import { useCurrentUserLocalQuery, useStripeBillingPortalLazyQuery } from '$graphql/hooks';
import { RateListParams } from '$router/types';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { Tabs } from '$components/index';

import { RateListPageSearchParams } from './types';
import { faqs, rates } from './data';
import { Rate, Faq, Contact, Message } from './components/index';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './RateListPage.module.less';

const RateListPage = () => {
  const [type, setType] = useState<'sale' | 'rent'>('sale');
  const [period, setPeriod] = useState<'month' | 'year'>('month');
  const [redirectUrl, setRedirectUrl] = useState<string | undefined | null>(null);
  const { data: currentUser } = useCurrentUserLocalQuery();
  const [getUrlBillingPortal] = useStripeBillingPortalLazyQuery();
  const params = useParams<RateListParams>();

  const { search } = useLocation();
  const urlParams = parseUrlQuery(search) as RateListPageSearchParams;

  useEffect(() => {
    if (currentUser?.currentUser.isPaidSubscription) {
      getUrlBillingPortal({
        variables: {
          returnUrl: '/rates',
        },
      }).then((resultUrlBillingPortal) => {
        setRedirectUrl(resultUrlBillingPortal.data?.stripeBillingPortal?.redirectUrl);
      });
    }
  }, [getUrlBillingPortal, currentUser]);

  return (
    <div className={cn(commonStyles.container, styles.main)}>
      <h1 className={styles.titlePage}>Purchase a subscription</h1>

      {currentUser?.currentUser?.isPaidSubscription && redirectUrl && (
        <div className={styles.blockTransitionSubscription}>
          <a href={redirectUrl} target="_blank" rel="noreferrer">
            <Button type="primary" shape="round" size="large">
              Manage subscription
            </Button>
          </a>
        </div>
      )}
      <Tabs
        items={[
          { key: 'sale', name: 'For brokers' },
          { key: 'rent', name: 'For owners/crew' },
        ]}
        selected={type}
        changeSelected={(key: 'sale' | 'rent') => setType(key)}
        theme="underlining"
        className={styles.selectType}
      />

      <Tabs
        items={[
          { key: 'month', name: 'Bill monthly' },
          { key: 'year', name: 'Bill annually' },
        ]}
        selected={period}
        changeSelected={(key: 'month' | 'year') => setPeriod(key)}
        theme="button"
        className={styles.selectTime}
      />

      <div className={styles.rates}>
        {type === 'sale' &&
          rates.brokers.rates.map((item) => (
            <Rate
              key={item.id}
              name={item.name}
              price={'$' + (period === 'month' ? item.priceMonth : item.priceYear)}
              period={period}
              lookupKey={period === 'month' ? item.lookupKeyMonth : item.lookupKeyYear}
              options={item.options}
              className={styles.rate}
            />
          ))}
        {type === 'rent' &&
          rates.owners.rates.map((item) => (
            <Rate
              key={item.id}
              name={item.name}
              price={'$' + (period === 'month' ? item.priceMonth : item.priceYear)}
              period={period}
              lookupKey={period === 'month' ? item.lookupKeyMonth : item.lookupKeyYear}
              options={item.options}
              className={styles.rate}
            />
          ))}
      </div>

      <Faq title="Frequently asked questions" items={faqs} className={styles.faq} />

      <Contact title="Still have more questions?" className={styles.contact}>
        Contact us{' '}
        <a href="mailto:info@getboat.com" target="_blank" rel="noopener noreferrer">
          info@getboat.com
        </a>{' '}
        for more information
      </Contact>
      {params.callback === 'success' && urlParams?.session_id && <Message sessionId={urlParams?.session_id} />}
    </div>
  );
};

export default RateListPage;
