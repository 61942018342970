import { FocusEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Result } from '$ui/index';
import cn from 'classnames';

import { useAvailableBoatsAndLocationInfoLazyQuery, useLocationSuggestionsLazyQuery } from '$graphql/hooks';
import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { LocationParams } from '$router/types';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { BoatCardBigRating, ManualSkeleton, Collapse } from '$components/index';

import { defaultFaqs, federalStateCode } from './data';
import { TCategory, LocationPagePageSearchParams } from './types';
import { Filters, Form, Description, Reviews, SubLocations, Categories, Seo } from './components';

import commonStyles from '$assets/styles/Common.module.less';
import styles from './LocationPage.module.less';
import { TInfoCache } from '$pages/LocationPage/types';

const LocationPage = () => {
  const { t } = useTranslation();
  const { path } = useParams<LocationParams>();
  const history = useHistory();
  const { generatePath } = useGeneratePath();
  const { search, pathname } = useLocation();
  const urlParams = parseUrlQuery(search) as LocationPagePageSearchParams;
  const [page, setPage] = useState<number>(urlParams?.page ? Number(urlParams.page) : 1);

  const [availableCategories, setAvailableCategories] = useState<Array<TCategory> | null>();
  const [selectedBoatCategories, setSelectedBoatCategories] = useState<Array<string> | undefined>(undefined);
  const [selectedVisualBoatCategories, setSelectedVisualBoatCategories] = useState<Array<string> | undefined>(
    undefined
  );
  const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(undefined);

  const [getAvailableBoatsAndInfo, { data: dataBoatList, loading: loadingBoatList, error: errorBoatList }] =
    useAvailableBoatsAndLocationInfoLazyQuery();
  const [getLocationSuggestions, { data: dataLocationSuggestions }] = useLocationSuggestionsLazyQuery();

  const [infoCache, setInfoCache] = useState<TInfoCache | undefined>(undefined);

  useEffect(() => {
    handleQuery(undefined, undefined, undefined, path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginate = (page: number) => {
    handleQuery(undefined, undefined, page);
    setPage(page);
    window.scrollTo(0, 0);
  };

  const handleQuery = (
    locationIdQuery?: string,
    boatCategoriesQuery?: Array<string>,
    pageQuery?: number,
    pathQuery?: string
  ) => {
    const withInfo = !!locationIdQuery || !!pathQuery;

    getAvailableBoatsAndInfo({
      variables: pathQuery
        ? {
            url: pathQuery,
            page: page,
            perPage: 12,
            withInfo,
          }
        : {
            locationId: locationIdQuery ? locationIdQuery : selectedLocationId,
            boatCategories: boatCategoriesQuery ? boatCategoriesQuery : selectedBoatCategories,
            page: pageQuery ? pageQuery : page,
            perPage: 12,
            withInfo,
          },
    }).then((result) => {
      setSelectedLocationId(result.data?.availableBoats.meta.location?.id);
      setSelectedVisualBoatCategories(result.data?.availableBoats?.meta?.selectedCategories?.map((c) => c.id));

      if (result.data?.availableBoats?.meta?.availableCategories) {
        setAvailableCategories(
          // Временное решение для подтягивания URL = "location + boatCategory"
          result.data.availableBoats.meta.availableCategories.map((i) => {
            return {
              ...i,
              url: result.data?.availableBoats.meta.availableUrlCategories?.find(
                (availableUrlCategories) => availableUrlCategories.id === i.id
              )?.url,
            };
          })
        );
      }

      if (withInfo) {
        setInfoCache({
          locationInfo: result.data?.locationInfo,
          searchBoatsWithTopReview: result.data?.searchBoatsWithTopReview,
        });
      }

      const url = result.data?.availableBoats?.meta?.url;
      const newPage = result.data?.availableBoats.meta.page || 1;
      setPage(newPage);
      const queryUrl = newPage > 1 ? '?page=' + newPage : '';

      if (url && url.length > 0 && (pathname !== `${url}${queryUrl}` || newPage === 1)) {
        history.push(generatePath(routes.LOCATION, { path: url }) + queryUrl);
      }
    });
  };

  const {
    availableBoats: { items: boats, meta },
  } = dataBoatList || { availableBoats: { items: undefined, meta: undefined } };

  const { locationSuggestions: estimatedLocations } = dataLocationSuggestions || { locationSuggestions: undefined };

  const location = {
    ...infoCache?.locationInfo?.location,
    description: infoCache?.locationInfo?.locationDescription,
  };

  const subLocations = infoCache?.locationInfo?.destinations;
  const faqs = infoCache?.locationInfo?.faqs;
  const reviews = infoCache?.searchBoatsWithTopReview?.items;
  const metaReviews = infoCache?.searchBoatsWithTopReview?.meta;

  if (errorBoatList) {
    return (
      <Result
        status="404"
        title={t('frontend.p404.h1')}
        subTitle={t('frontend.p404.subtitle')}
        extra={
          <Button type="primary" onClick={() => history.push(generatePath(routes.ROOT))}>
            {t('frontend.p404.return')}
          </Button>
        }
      />
    );
  }

  const handleSearchLocation = (name: string) => {
    getLocationSuggestions({ variables: { name } });
  };

  const handleSelectLocation = (location: string, options: OptionData | OptionGroupData) => {
    setSelectedLocationId(`${options?.key}`);
    handleQuery(`${options?.key}`);
  };

  const handleBlurLocation = (e: FocusEvent<HTMLInputElement>) => {
    // const location = (e.target as HTMLInputElement).value;
  };

  const seoBoatCategories =
    selectedVisualBoatCategories && availableCategories?.filter((i) => selectedVisualBoatCategories?.includes(i.id));

  return (
    <div className={cn(commonStyles.container, styles.layout)} data-cy="LocationPageComponent">
      {!loadingBoatList && meta && meta.location && (
        <Seo
          location={{
            type: meta.location.type,
            name: meta.location.address,
            country: meta.location.country?.name,
            countryCode: meta.location.country?.code,
            federalState: meta.location.state?.address,
          }}
          federalStateCodes={federalStateCode}
          boatCategories={seoBoatCategories}
          minPrice="$1200"
          totalCount={meta.totalCount}
        />
      )}
      <div className={styles.filter}>
        <Filters
          boatCategories={availableCategories}
          selectedBoatCategories={selectedVisualBoatCategories}
          setSelectedBoatCategories={(categories) => {
            handleQuery(undefined, categories, 1);
            setSelectedBoatCategories(categories);
            //paginate(1);
          }}
          isSkeleton={!availableCategories}
        />
      </div>

      <div className={styles.result}>
        <Form
          locationName={meta?.location?.address || ''}
          handleSearchLocation={handleSearchLocation}
          handleSelectLocation={handleSelectLocation}
          handleBlurLocation={handleBlurLocation}
          items={estimatedLocations}
        />
        {!loadingBoatList && meta && meta.location ? (
          <>
            <Seo.H1
              location={{
                type: meta.location.type,
                name: meta.location.address,
                country: meta.location.country?.name,
                countryCode: meta.location.country?.code,
                federalState: meta.location.state?.address,
              }}
              federalStateCodes={federalStateCode}
              boatCategories={seoBoatCategories}
              className={styles.title}
            />
            <div className={styles.breadcrumbs}>Yacht charter / rent a boat in {meta.location?.address}</div>
          </>
        ) : (
          <>
            <div className={styles.title}>
              <ManualSkeleton className={styles.skeletonTitle} />
            </div>
            <div className={styles.breadcrumbs}>
              <ManualSkeleton className={styles.skeletonBreadcrumbs} />
            </div>
          </>
        )}

        {!loadingBoatList && boats && (
          <div className={styles.boats}>
            <div className={styles.list}>
              {boats ? boats.map((boat) => <BoatCardBigRating key={boat.id} item={boat} />) : <div>Skeleton</div>}
            </div>

            {meta && meta.totalCount > 12 && (
              <Pagination
                total={meta.totalCount}
                current={page}
                defaultPageSize={12}
                onChange={paginate}
                showSizeChanger={false}
                responsive
                className={styles.pagination}
              />
            )}
          </div>
        )}

        {loadingBoatList && (
          <div className={styles.boats}>
            <div className={styles.list}>
              {Array.from({ length: 10 }, (_, i) => (
                <BoatCardBigRating key={i} isSkeleton />
              ))}
            </div>
          </div>
        )}

        {!!subLocations && subLocations.length > 0 && (
          <SubLocations items={subLocations} title={meta?.location?.address} />
        )}

        {!!meta?.location?.address && (
          <Categories
            title={meta?.location?.address}
            availableCategories={availableCategories?.map((i) => {
              return { id: i.id, name: i.name, url: i.url };
            })}
          />
        )}

        {!loadingBoatList && location && location.description?.enContent && (
          <Description title={`Yacht Charter ${location.address}`} className={styles.blockDescription}>
            {location.description?.enContent}
          </Description>
        )}

        {!loadingBoatList && reviews && reviews.length > 0 && (
          <Reviews
            reviews={reviews}
            total={{ average: metaReviews?.locationAverageRating, customers: metaReviews?.totalCountReviews }}
          />
        )}

        {!loadingBoatList && (
          <>
            <h2 className={styles.subTitle}>Frequently asked questions</h2>
            <Collapse
              items={
                faqs && faqs.length > 0
                  ? faqs?.map((faq) => {
                      return { key: faq.id, label: faq.enAnswer, children: faq.enQuestion };
                    })
                  : defaultFaqs
              }
              className={styles.faq}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LocationPage;
