import { ContactProps } from './types';
import styles from './Contact.module.less';

const Contact = ({ title, children, className }: ContactProps) => {
  return (
    <div className={className}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Contact;
