import { Wrapper, Status } from '@googlemaps/react-wrapper';

import { ManualSkeleton } from '$components/index';

import DrawMap from './components/DrawMap';
import { AppGooglemapProps } from './types';

const AppGooglemap = ({ markerPosition, circle, skeleton, language, zoom, height }: AppGooglemapProps) => {
  const defaultValue = {
    position: {
      lat: 34.658926,
      lng: -28.504677,
    },
    zoom: markerPosition ? 9 : 1,
    height: '600px',
  };

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return skeleton ? (
          <ManualSkeleton
            style={{
              height: height ? height : defaultValue.height,
              width: '100%',
            }}
          />
        ) : (
          <></>
        );
      case Status.FAILURE:
        return <></>;
      default:
        return <></>;
    }
  };

  if (process.env.REACT_APP_GOOGLE_ONLY_SKELETON) {
    return (
      <div
        style={{
          backgroundColor: '#f3f3f3',
          height: height ? height : defaultValue.height,
          width: '100%',
        }}
      />
    );
  }

  const getApiKey = (): string | undefined => {
    if (process.env.REACT_APP_GOOGLE_MAP_DEVELOPER_MODE) {
      return;
    }

    switch (window.location.hostname) {
      case 'staging.getboat.com':
      case 'staging-travelpeople.getboat.com':
      case 'localhost':
        if (process.env.REACT_APP_GOOGLE_MAP_KEY_STAGING) {
          return process.env.REACT_APP_GOOGLE_MAP_KEY_STAGING;
        }
        break;
      default:
        if (process.env.REACT_APP_GOOGLE_MAP_KEY) {
          return process.env.REACT_APP_GOOGLE_MAP_KEY;
        }
        break;
    }

    return;
  };

  const apiKey = getApiKey();

  if (apiKey) {
    return (
      <Wrapper apiKey={apiKey} language={language || 'en'} render={render}>
        <DrawMap
          lat={markerPosition.lat}
          lng={markerPosition.lng}
          zoom={zoom || defaultValue.zoom}
          circle={circle}
          height={height ? height : defaultValue.height}
        />
      </Wrapper>
    );
  }

  return null;
};

export default AppGooglemap;
