import useTextGenerator from '../../hooks/useTextGenerator';
import { seoH1Props } from './types';

export const SeoH1 = ({ location, boatCategories, federalStateCodes, className }: seoH1Props) => {
  const { locationText, boatCategoriesText } = useTextGenerator(location, federalStateCodes, boatCategories);

  if (boatCategories && boatCategories?.length > 0) {
    return <h1 className={className}>{`${boatCategoriesText()} rentals in ${locationText('withBoatCategory')}`}</h1>;
  }

  return <h1 className={className}>{`Boat rentals in ${locationText()}`}</h1>;
};
