import Table from 'antd/lib/table';
import { PromoCodesProps } from './types';

const PromoCodes = ({ items }: PromoCodesProps) => {
  const columns = [
    {
      title: 'Text',
      dataIndex: 'promoCode',
      key: 'promoCode',
    },
    {
      title: 'Discount percentage',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
    },
    {
      title: 'Quantity for use',
      dataIndex: 'quantityForUse',
      key: 'quantityForUse',
    },
    {
      title: 'Quantity used',
      dataIndex: 'quantityUsed',
      key: 'quantityUsed',
    },
    {
      title: 'Expired at',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return <Table dataSource={items ? items : []} columns={columns} pagination={false} scroll={{ x: true }} />;
};

export default PromoCodes;
