export interface ICurrencyData {
  name: 'frontend.header.currency.dollar' | 'frontend.header.currency.euro';
  sign: string;
  code: string;
}

export class Formatter {
  // code: https://www.iban.com/currency-codes
  static currencies: Array<ICurrencyData> = [
    {
      name: 'frontend.header.currency.dollar',
      sign: '$',
      code: 'usd',
    },
    {
      name: 'frontend.header.currency.euro',
      sign: '€',
      code: 'eur',
    },
  ];

  static findCurrencySymbol = (code: ICurrencyData['code']): ICurrencyData['sign'] => {
    const currency = Formatter.currencies.find((c) => c.code === code.toLowerCase());
    return currency ? currency.sign : '€';
  };

  static price(currentCurrency?: ICurrencyData['code'], currentLanguage?: string, value?: number | string): string {
    const currency = currentCurrency || 'eur';

    const result = {
      value: Number(value) > 0 ? Number(value) : '',
      currencySymbol: Formatter.findCurrencySymbol(currency),
      isSymbolAtBeginning: currentLanguage === 'en',
    };

    if (result.isSymbolAtBeginning) {
      return `${result.currencySymbol}${result.value}`;
    }

    return `${result.value} ${result.currencySymbol}`;
  }

  static guests(value?: number | string): number {
    return Number(value) >= 1 ? Math.round(Number(value)) : 0;
  }
}
