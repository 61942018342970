import cn from 'classnames';

import { useDateTime } from '$hooks/index';
import { formatCurrency } from '$utils/formatCurrency';

import { PaymentProps } from './types';
import styles from './Payment.module.less';

const Payment = ({ offer, className }: PaymentProps) => {
  const dateTime = useDateTime();

  const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;
  const isPaidList = offer?.payments.filter((payment) => payment.status === 'paid').length === 2;
  const isPaidOne = offer?.payments.find(
    (payment) =>
      payment.paymentMethod?.type === 'PaymentMethod::Platron' &&
      (payment.status === 'paid' || payment.status === 'hold')
  );

  if (offer) {
    return (
      <div className={cn(styles.main, className)}>
        <div className={styles.title}>Payment information</div>

        {offer.charter?.promoCode?.promoCode && offer.charter?.promoCode?.discountPercentage && (
          <div className={styles.promoCode}>
            <div className={styles.description}>The discount has been applied</div>
            <div className={styles.code}>
              <div className={styles.label}>Promo code</div>
              <div className={styles.value}>{offer.charter.promoCode.promoCode}</div>
            </div>
            <div className={styles.discount}>
              <div className={styles.label}>Discount</div>
              <div className={styles.value}>{offer.charter.promoCode.discountPercentage}%</div>
            </div>
          </div>
        )}

        {offer?.priceWithoutDiscount?.amount &&
          offer?.price?.amount &&
          offer.priceWithoutDiscount.amount > offer.price.amount && (
            <div className={styles.withoutDiscount}>
              <div className={styles.label}>Without discount</div>
              <div className={styles.value}>
                <s>{formatCurrency(offer.priceWithoutDiscount?.amount, offer.priceWithoutDiscount?.currency)}</s>
              </div>
            </div>
          )}

        {offer.price?.amount && (
          <div className={styles.total}>
            <div className={styles.label}>
              Total
              {!!diffDays && diffDays > 1 && (
                <div className={styles.sub}>
                  {formatCurrency(Math.ceil(offer.price.amount / diffDays), offer.price.currency)} x {diffDays} Days
                </div>
              )}
            </div>
            <div className={styles.value}>{formatCurrency(offer.price.amount, offer.price.currency)}</div>
          </div>
        )}

        {isPaidOne ? (
          <div className={styles.firstPayment}>
            <div className={styles.label}>Paid</div>
            <div className={styles.value}>{formatCurrency(offer.price?.amount, offer.price?.currency)}</div>
          </div>
        ) : (
          <>
            {isPaidList && (
              <>
                {offer.payments
                  .filter((payment) => payment.status === 'paid')
                  .sort((a, b) => a.id - b.id)
                  .map((payment) => (
                    <div className={styles.itemPayment} key={payment.id}>
                      <div className={styles.label}>Paid</div>
                      <div className={styles.value}>
                        {formatCurrency(payment.amount?.amount, payment.amount?.currency)}
                      </div>
                    </div>
                  ))}
                <div className={styles.secondPayment}>
                  <div className={styles.label}>Amount due</div>
                  <div className={styles.value}>{formatCurrency(-1, offer.price?.currency)}</div>
                </div>
              </>
            )}

            {!isPaidList && (
              <>
                <div className={styles.firstPayment}>
                  <div className={styles.label}>Paid</div>
                  <div className={styles.value}>{formatCurrency(offer.firstPaymentAmount, offer.price?.currency)}</div>
                </div>

                <div className={styles.secondPayment}>
                  <div className={styles.label}>
                    Amount due
                    <div className={styles.sub}>Due on {dateTime.format(offer.payTillOn)}</div>
                  </div>
                  <div className={styles.value}>{formatCurrency(offer.secondPaymentAmount, offer.price?.currency)}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  return <></>;
};

export default Payment;
