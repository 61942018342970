import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { Collapse } from '$ui/index';
import cn from 'classnames';

import { Contacts, LinkList, SocialNetworks, MobileApps } from './components';

import { contacts, mobileApps, services, socialNetworks, sitemaps, copyright } from './data';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './Footer.module.less';

const Footer = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  // TODO 2021 нужно написать свой аккардион с возможностью выключения для desktop, а не вот это извращение
  return (
    <div className={cn(commonStyles.container, styles.footer)}>
      <MediaQuery maxWidth={767}>
        <Collapse
          defaultActiveKey={['contacts']}
          accordion
          ghost
          expandIconPosition="right"
          className={styles.collapse}
        >
          <Panel header={<h3 className={styles.title}>{t('frontend.footer.contacts.label')}</h3>} key="contacts">
            <Contacts items={contacts} />
          </Panel>
          <Panel header={<h3 className={styles.title}>{t('frontend.footer.services.label')}</h3>} key="services">
            <LinkList items={services} />
          </Panel>
          <Panel header={<h3 className={styles.title}>{t('frontend.footer.site_map.label')}</h3>} key="site_map">
            <LinkList items={sitemaps} />
          </Panel>
        </Collapse>
        <SocialNetworks items={socialNetworks} className={cn(commonStyles.container, styles.socialNetworks)} />
        <MobileApps items={mobileApps} className={cn(commonStyles.container, styles.mobileApps)} />
        <div className={cn(commonStyles.container, styles.copyright)}>{copyright}</div>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <Contacts
          items={contacts}
          title={<h3 className={styles.title}>{t('frontend.footer.contacts.label')}</h3>}
          className={styles.contacts}
        />
        <LinkList
          items={services}
          title={<h3 className={styles.title}>{t('frontend.footer.services.label')}</h3>}
          className={styles.services}
        />
        <LinkList
          items={sitemaps}
          title={<h3 className={styles.title}>{t('frontend.footer.site_map.label')}</h3>}
          className={styles.sitemaps}
        />
        <div className={styles.copyright}>{copyright}</div>
        <MobileApps items={mobileApps} className={styles.mobileApps} />
        <SocialNetworks items={socialNetworks} className={styles.socialNetworks} />
      </MediaQuery>
    </div>
  );
};

export default memo(Footer);
