import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '$ui/index';

import { useCharterQuery, useOffersQuery, useCharterOffersSubscription } from '$graphql/hooks';
import { OfferStatus } from '$graphql/types';

import { routes } from '$router/index';
import { CharterViewParams } from '$router/types';
import useGeneratePath from '$hooks/useGeneratePath';
import { useDateTime } from '$hooks/index';
import { Breadcrumb, Icon } from '$components/index';

import RequestBoats from './components/RequestBoats';

import commonStyles from '$assets/styles/Common.module.less';
import styles from './CharterPage.module.less';
import '$assets/styles/offersPage/index.less';

const CharterPage = () => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const dateTime = useDateTime();
  const params = useParams<CharterViewParams>();

  const charterId = Number(params.charterId);
  const {
    data: charterData,
    loading: charterLoading,
    error: charterError,
  } = useCharterQuery({ variables: { id: charterId } });

  const {
    data: offersData,
    loading: offersLoading,
    error: offersError,
  } = useOffersQuery({
    variables: { charterId },
    fetchPolicy: 'no-cache',
  });

  const { data: charterOffersSubscriptionDate } = useCharterOffersSubscription({ variables: { charterId: charterId } });

  if (offersError || charterError) {
    return (
      <div className="offers-page">
        <div className={commonStyles.container}>
          <div>Error</div>
        </div>
      </div>
    );
  }

  const charterInfo = () => {
    if (!charterData?.charter) return null;

    const charter = charterData.charter;

    return (
      <>
        <p>{dateTime.textDateRange(charter.startsAt, charter.endsAt)}</p>
        <span>
          {t('frontend.charter.charter_request')} # {charter.id}
        </span>
        <p>{charter.locations.map((l) => l?.location?.address || l.name).join(', ')}</p>

        {charter.promoCodeOriginal && (
          <div className={styles.promoCode}>
            <div className={styles.description}>The discount will be applied when calculating the final cost</div>
            <div className={styles.code}>
              Promo code: <strong>{charter.promoCode?.promoCode}</strong>
            </div>
            <div className={styles.discount}>
              Discount: <strong>{charter.promoCode?.discountPercentage}%</strong>
            </div>
          </div>
        )}
      </>
    );
  };

  let breadcrumbItems;

  if (charterData && offersData) {
    breadcrumbItems = [
      {
        title: t('frontend.breadcrumb.request_list'),
        url: generatePath(routes.CHARTERS),
      },
      {
        title: [
          charterData?.charter?.id,
          charterData?.charter?.locations.map((l) => l?.location?.address || l.name).join(', '),
        ]
          .filter((i) => i)
          .join(', '),
      },
    ];
  }

  return (
    <div className="offers-page">
      <div className={commonStyles.container}>
        <Breadcrumb items={breadcrumbItems} />
        <div className="offers-page__header">
          <div className="offers-page__header-info">
            <Skeleton paragraph={{ rows: 1 }} loading={charterLoading}>
              {charterInfo()}
            </Skeleton>
          </div>
        </div>
        {offersData &&
          offersData.offers?.length > 0 &&
          !offersData?.offers.find(
            (offer) => offer.status === OfferStatus.Processing || offer.status === OfferStatus.Selected
          ) && (
            <div className="offers-page__text">
              <Icon name="lockOutline" />
              <p>{t('frontend.charter.header_when_there_is_offers')}</p>
            </div>
          )}
      </div>
      {!(offersLoading || charterLoading) && (
        <RequestBoats offers={charterOffersSubscriptionDate?.charterOffers || offersData?.offers} />
      )}
    </div>
  );
};

export default CharterPage;
