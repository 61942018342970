import firebase from 'firebase/app';
import 'firebase/messaging';

import { showAntdNotification } from '../showAntdNotification';

const config = {
  apiKey: 'AIzaSyDV59_BZ4Jwk2J06pliuKRi4PmE2L_oCfI',
  authDomain: 'getboat-214911.firebaseapp.com',
  projectId: 'getboat-214911',
  storageBucket: 'getboat-214911.appspot.com',
  messagingSenderId: '300850798657',
  appId: '1:300850798657:web:7e0d3fa713e32f441f66c0',
  measurementId: 'G-9QV5SJWF50',
};

const prodConfig = {
  apiKey: 'AIzaSyBPprl3hkI3LaZxtta0VyVFXzkcF3FkHxE',
  authDomain: 'getboat-com.firebaseapp.com',
  projectId: 'getboat-com',
  storageBucket: 'getboat-com.appspot.com',
  messagingSenderId: '1062506838591',
  appId: '1:1062506838591:web:919f4e23ae76adb907168a',
  measurementId: 'G-H52VPZJGSC',
};

let messaging: firebase.messaging.Messaging;

// Safari не поддерживает нотификации
// https://firebase.google.com/support/guides/environments_js-sdk#supported_environments
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(process.env.REACT_APP_ENV === 'production' ? prodConfig : config);

  messaging = firebase.messaging();

  messaging.onMessage((payload) => {
    showAntdNotification('success', payload.data);
  });
}

export const requestFirebaseNotificationPermission = (): Promise<string | null> =>
  new Promise((resolve, reject) => {
    if (!messaging) return; // reject('Not supported browser');//Temporarily. Display of Firebase Notification errors is disabled

    messaging
      .getToken()
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
