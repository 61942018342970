// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const registerServiceWorker = () => {
  const configPath =
    process.env.REACT_APP_ENV === 'production' ? '/firebase-messaging-sw.production.js' : '/firebase-messaging-sw.js';

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(configPath)
      .then(function (registration) {
        return registration.scope;
      })
      .catch(function (err) {
        console.log('/manifest.json', err);
        return err;
      });
  }
};
