import { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cn from 'classnames';

import { routes } from '$router/index';
import { Swiper, SwiperSlide } from '$components/index';

import { CardProps, CountriesProps } from './types';
import styles from './Countries.module.less';
import useGeneratePath from '$hooks/useGeneratePath';

const Countries = ({ items }: CountriesProps) => {
  const { generatePath } = useGeneratePath();

  const [isLazyImages, setIsLazyImages] = useState<boolean>(true);

  const Card = ({ country, isMobile, isLazyImages }: CardProps) => {
    return (
      <Link
        to={{
          pathname: generatePath(routes.REQUEST_OFFERS),
          search: 'locationId=' + country.locationId + '&location=' + country.location,
        }}
        className={styles.country}
      >
        <picture style={{ backgroundColor: country.backgroundColor }}>
          <source
            type="image/webp"
            media="(max-width: 1199px)"
            srcSet={`${country.img.mobile.x1.webp} 1x, ${country.img.mobile.x2.webp} 2x, ${country.img.mobile.x3.webp} 3x`}
          />
          <source
            type="image/webp"
            media="(min-width: 1200px)"
            srcSet={`${country.img.desktop.x1.webp} 1x, ${country.img.desktop.x2.webp} 2x, ${country.img.desktop.x3.webp} 3x`}
          />
          <img src={country.img.desktop.x1.jpeg} alt={country.name} loading={isLazyImages ? 'lazy' : undefined} />
        </picture>
        <div className={cn(styles.text, isMobile && styles.textIsMobile)}>
          <div className={styles.numberYachts}>{country.countBoats} yachts</div>
          <div className={styles.name}>{country.name}</div>
        </div>
      </Link>
    );
  };

  return (
    <>
      <MediaQuery maxWidth={1199}>
        <Swiper
          onSliderFirstMove={() => {
            setIsLazyImages(false);
          }}
          isNavigation={false}
          className={styles.countries}
        >
          {items.map((country) => (
            <SwiperSlide key={country.locationId} className={styles.slide}>
              <Card country={country} isLazyImages={isLazyImages} isMobile />
            </SwiperSlide>
          ))}
        </Swiper>
      </MediaQuery>
      <MediaQuery minWidth={1200}>
        <div className={styles.countries}>
          {items.map((country) => (
            <Card key={country.locationId} country={country} isLazyImages={isLazyImages} />
          ))}
        </div>
      </MediaQuery>
    </>
  );
};

export default memo(Countries, (oldProps, newProps) => {
  return oldProps.items.map((i) => i.locationId).join(',') === newProps.items.map((i) => i.locationId).join(',');
});
