import img_d_x1_j_boat from './assets/descktop/x1/boat.jpg';
import img_d_x1_j_catamaran from './assets/descktop/x1/catamaran.jpg';
import img_d_x1_j_gulet from './assets/descktop/x1/gulet.jpg';
import img_d_x1_j_megaYacht from './assets/descktop/x1/megaYacht.jpg';
import img_d_x1_j_sailingYacht from './assets/descktop/x1/sailingYacht.jpg';
import img_d_x1_j_yacht from './assets/descktop/x1/yacht.jpg';

import img_d_x1_w_boat from './assets/descktop/x1/boat.webp';
import img_d_x1_w_catamaran from './assets/descktop/x1/catamaran.webp';
import img_d_x1_w_gulet from './assets/descktop/x1/gulet.webp';
import img_d_x1_w_megaYacht from './assets/descktop/x1/megaYacht.webp';
import img_d_x1_w_sailingYacht from './assets/descktop/x1/sailingYacht.webp';
import img_d_x1_w_yacht from './assets/descktop/x1/yacht.webp';

import img_d_x2_w_boat from './assets/descktop/x2/boat.webp';
import img_d_x2_w_catamaran from './assets/descktop/x2/catamaran.webp';
import img_d_x2_w_gulet from './assets/descktop/x2/gulet.webp';
import img_d_x2_w_megaYacht from './assets/descktop/x2/megaYacht.webp';
import img_d_x2_w_sailingYacht from './assets/descktop/x2/sailingYacht.webp';
import img_d_x2_w_yacht from './assets/descktop/x2/yacht.webp';

import img_d_x3_w_boat from './assets/descktop/x3/boat.webp';
import img_d_x3_w_catamaran from './assets/descktop/x3/catamaran.webp';
import img_d_x3_w_gulet from './assets/descktop/x3/gulet.webp';
import img_d_x3_w_megaYacht from './assets/descktop/x3/megaYacht.webp';
import img_d_x3_w_sailingYacht from './assets/descktop/x3/sailingYacht.webp';
import img_d_x3_w_yacht from './assets/descktop/x3/yacht.webp';

import img_m_x1_j_boat from './assets/mobile/x1/boat.jpg';
import img_m_x1_j_catamaran from './assets/mobile/x1/catamaran.jpg';
import img_m_x1_j_gulet from './assets/mobile/x1/gulet.jpg';
import img_m_x1_j_megaYacht from './assets/mobile/x1/megaYacht.jpg';
import img_m_x1_j_sailingYacht from './assets/mobile/x1/sailingYacht.jpg';
import img_m_x1_j_yacht from './assets/mobile/x1/yacht.jpg';

import img_m_x1_w_boat from './assets/mobile/x1/boat.webp';
import img_m_x1_w_catamaran from './assets/mobile/x1/catamaran.webp';
import img_m_x1_w_gulet from './assets/mobile/x1/gulet.webp';
import img_m_x1_w_megaYacht from './assets/mobile/x1/megaYacht.webp';
import img_m_x1_w_sailingYacht from './assets/mobile/x1/sailingYacht.webp';
import img_m_x1_w_yacht from './assets/mobile/x1/yacht.webp';

import img_m_x2_w_boat from './assets/mobile/x2/boat.webp';
import img_m_x2_w_catamaran from './assets/mobile/x2/catamaran.webp';
import img_m_x2_w_gulet from './assets/mobile/x2/gulet.webp';
import img_m_x2_w_megaYacht from './assets/mobile/x2/megaYacht.webp';
import img_m_x2_w_sailingYacht from './assets/mobile/x2/sailingYacht.webp';
import img_m_x2_w_yacht from './assets/mobile/x2/yacht.webp';

import img_m_x3_w_boat from './assets/mobile/x3/boat.webp';
import img_m_x3_w_catamaran from './assets/mobile/x3/catamaran.webp';
import img_m_x3_w_gulet from './assets/mobile/x3/gulet.webp';
import img_m_x3_w_megaYacht from './assets/mobile/x3/megaYacht.webp';
import img_m_x3_w_sailingYacht from './assets/mobile/x3/sailingYacht.webp';
import img_m_x3_w_yacht from './assets/mobile/x3/yacht.webp';

export type TCategoriesBoats = {
  id: number;
  name: string;
  img: {
    mobile: {
      x1: { webp: string; jpeg: string };
      x2: { webp: string };
      x3: { webp: string };
    };
    desktop: {
      x1: { webp: string; jpeg: string };
      x2: { webp: string };
      x3: { webp: string };
    };
  };
  backgroundColor: string;
};

const categoriesBoats: Array<TCategoriesBoats> = [
  {
    id: 1,
    name: 'Boat',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_boat,
          jpeg: img_m_x1_j_boat,
        },
        x2: {
          webp: img_m_x2_w_boat,
        },
        x3: {
          webp: img_m_x3_w_boat,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_boat,
          jpeg: img_d_x1_j_boat,
        },
        x2: {
          webp: img_d_x2_w_boat,
        },
        x3: {
          webp: img_d_x3_w_boat,
        },
      },
    },
    backgroundColor: '#5C6471',
  },
  {
    id: 2,
    name: 'Yacht',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_yacht,
          jpeg: img_m_x1_j_yacht,
        },
        x2: {
          webp: img_m_x2_w_yacht,
        },
        x3: {
          webp: img_m_x3_w_yacht,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_yacht,
          jpeg: img_d_x1_j_yacht,
        },
        x2: {
          webp: img_d_x2_w_yacht,
        },
        x3: {
          webp: img_d_x3_w_yacht,
        },
      },
    },
    backgroundColor: '#6B7EA9',
  },
  {
    id: 4,
    name: 'Sailing yacht',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_sailingYacht,
          jpeg: img_m_x1_j_sailingYacht,
        },
        x2: {
          webp: img_m_x2_w_sailingYacht,
        },
        x3: {
          webp: img_m_x3_w_sailingYacht,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_sailingYacht,
          jpeg: img_d_x1_j_sailingYacht,
        },
        x2: {
          webp: img_d_x2_w_sailingYacht,
        },
        x3: {
          webp: img_d_x3_w_sailingYacht,
        },
      },
    },
    backgroundColor: '#B5C3C6',
  },
  {
    id: 5,
    name: 'Catamaran',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_catamaran,
          jpeg: img_m_x1_j_catamaran,
        },
        x2: {
          webp: img_m_x2_w_catamaran,
        },
        x3: {
          webp: img_m_x3_w_catamaran,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_catamaran,
          jpeg: img_d_x1_j_catamaran,
        },
        x2: {
          webp: img_d_x2_w_catamaran,
        },
        x3: {
          webp: img_d_x3_w_catamaran,
        },
      },
    },
    backgroundColor: '#014857',
  },
  {
    id: 6,
    name: 'Mega yacht',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_megaYacht,
          jpeg: img_m_x1_j_megaYacht,
        },
        x2: {
          webp: img_m_x2_w_megaYacht,
        },
        x3: {
          webp: img_m_x3_w_megaYacht,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_megaYacht,
          jpeg: img_d_x1_j_megaYacht,
        },
        x2: {
          webp: img_d_x2_w_megaYacht,
        },
        x3: {
          webp: img_d_x3_w_megaYacht,
        },
      },
    },
    backgroundColor: '#78A4B9',
  },
  {
    id: 8,
    name: 'Gulet',
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_gulet,
          jpeg: img_m_x1_j_gulet,
        },
        x2: {
          webp: img_m_x2_w_gulet,
        },
        x3: {
          webp: img_m_x3_w_gulet,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_gulet,
          jpeg: img_d_x1_j_gulet,
        },
        x2: {
          webp: img_d_x2_w_gulet,
        },
        x3: {
          webp: img_d_x3_w_gulet,
        },
      },
    },
    backgroundColor: '#3FA1BC',
  },
];

export default categoriesBoats;
