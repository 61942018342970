import { Route, Switch } from 'react-router-dom';

import { useScrollToTop, useSetLanguage } from '$hooks/index';

import {
  LandingPage,
  LocationPage,
  BoatListPage,
  BoatPage,
  BoatOfferPage,
  RequestPage,
  SignInPage,
  SignUpPage,
  CharterListPage,
  CharterPage,
  PaymentPage,
  BookingPage,
  RateListPage,
  PartnershipPage,
  NotFoundPage,
  ForgotPasswordPage,
  PasswordResetPage,
  ServerErrorPage,
} from '../pages';

import TestPage from '$pages/TestPage';

export const routes = {
  ROOT: '/',
  BOATS: '/boats',
  BUY_BOATS: '/for_sale',
  BOAT_VIEW: '/boat/:boatIdOrSlug',
  BOAT_VIEW_OLD: '/boats/:boatIdOrSlug',
  BUY_BOAT_VIEW: '/boat/:boatIdOrSlug/sale',
  BOAT_OFFER_VIEW: '/boat/:boatIdOrSlug/offer/:offerId',
  REQUEST_OFFERS: '/request_offers',
  CHARTERS: '/charters',
  CHARTER_VIEW: '/charters/:charterId',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  PAYMENT_VIEW: '/payments/:paymentId',
  BOOKING_VIEW: '/booking/:offerId',
  RATES_CALLBACK: '/rates/:callback',
  RATES: '/rates',
  PARTNERSHIP: '/partnership',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset/:accountId/:confirmationCode',
  TEST: '/test_page',
  LOCATION: '/:path',
};

const localizeRoutes = (routes: Record<string, string>) => {
  const basePath = '/:lng(en|de|fr)?';

  Object.entries(routes).forEach(([key, val]) => {
    routes[key] = basePath + val;
  });
};

localizeRoutes(routes);

export const RouterView = () => {
  useSetLanguage();
  useScrollToTop();

  return (
    <>
      <Switch>
        <Route exact path={routes.ROOT} component={LandingPage} />

        <Route exact path={routes.BOATS} component={BoatListPage} />

        <Route exact path={routes.BUY_BOATS}>
          <BoatListPage isBuy />
        </Route>

        {/*Порядок важен, сначало частный случий /boat/:boatIdOrSlug/sale, далее основной URL */}
        <Route path={routes.BOAT_OFFER_VIEW} component={BoatOfferPage} />

        <Route path={routes.BUY_BOAT_VIEW}>
          <BoatPage isBuy />
        </Route>

        <Route path={routes.BOAT_VIEW} component={BoatPage} />

        <Route path={routes.BOAT_VIEW_OLD}>
          <BoatPage idOldUrl />
        </Route>

        <Route path={routes.REQUEST_OFFERS} component={RequestPage} />

        <Route exact path={routes.CHARTERS} component={CharterListPage} />

        <Route path={routes.CHARTER_VIEW} component={CharterPage} />

        <Route path={routes.PAYMENT_VIEW} component={PaymentPage} />

        <Route path={routes.BOOKING_VIEW} component={BookingPage} />

        <Route path={routes.RATES_CALLBACK} component={RateListPage} />

        <Route path={routes.RATES} component={RateListPage} />

        <Route path={routes.PARTNERSHIP} component={PartnershipPage} />

        <Route path={routes.SIGN_IN} component={SignInPage} />

        <Route path={routes.SIGN_UP} component={SignUpPage} />

        <Route path={routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />

        <Route path={routes.PASSWORD_RESET} component={PasswordResetPage} />

        <Route path={routes.TEST} component={TestPage} />

        <Route path={routes.LOCATION} component={LocationPage} />

        <Route path="/server-error" component={ServerErrorPage} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};
