import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Result, Button } from '$ui/index';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';

const NotFoundPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();

  return (
    <Result
      status="404"
      title={t('frontend.p404.h1')}
      subTitle={t('frontend.p404.subtitle')}
      extra={
        <Button type="primary" onClick={() => history.push(generatePath(routes.ROOT))}>
          {t('frontend.p404.return')}
        </Button>
      }
    />
  );
};

export default NotFoundPage;
