import cn from 'classnames';
import { Collapse as LibCollapse } from '$ui/index';
import { PlusOutlined } from '@ant-design/icons';

import { CollapseProps } from './types';
import styles from './Collapse.module.less';

const Collapse = ({ items, className }: CollapseProps) => {
  const { Panel } = LibCollapse;

  return (
    <LibCollapse
      defaultActiveKey={items?.find((i) => i.key)?.key}
      expandIconPosition="right"
      expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} className={styles.icon} />}
      className={cn(className, styles.block)}
      accordion
      ghost
    >
      {items?.map((item) => (
        <Panel header={item.label} key={item.key}>
          <div
            dangerouslySetInnerHTML={{
              __html: item.children,
            }}
          />
        </Panel>
      ))}
    </LibCollapse>
  );
};

export default Collapse;
