import { formatCurrency } from '$utils/formatCurrency';
import { IconAccount } from '$components/index';

import { PaymentProps } from './types';
import styles from './Payment.module.less';

const Payment = ({ offer, isOnePayment, className }: PaymentProps) => {
  if (offer) {
    return (
      <div className={className}>
        <div className={styles.title}>Payment</div>
        <div className={styles.payments}>
          <div className={styles.icon}>
            <IconAccount name="payment" />
          </div>
          {isOnePayment ? (
            <div className={styles.firstPayment}>{formatCurrency(offer?.price?.amount, offer?.price?.currency)}</div>
          ) : (
            <>
              <div className={styles.firstPayment}>
                {formatCurrency(offer?.firstPaymentAmount, offer?.price?.currency)}
              </div>
              {offer && offer.secondPaymentAmount > 0 && (
                <>
                  <div>now and</div>
                  <div className={styles.secondPayment}>
                    {formatCurrency(offer?.secondPaymentAmount, offer?.price?.currency)}
                  </div>
                  <div>later</div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export default Payment;
