import { SpoilerProps } from '$components/Spoiler/types';

import styles from './Spoiler.module.less';

const Spoiler = ({ label = 'Read more', suffixId, isDisabled, children }: SpoilerProps) => {
  if (isDisabled) {
    return <div>{children}</div>;
  }

  return (
    <>
      <input type="checkbox" id={'jsChangeShow' + suffixId} className={styles.shadyCheckbox} />
      <div className={styles.content}>{children}</div>
      <label htmlFor={'jsChangeShow' + suffixId} className={styles.show}>
        {label}
      </label>
    </>
  );
};

export default Spoiler;
