import { useState, useEffect, Suspense, lazy, ReactNode } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import cn from 'classnames';
import 'react-image-gallery/styles/css/image-gallery.css'; // Docs: @import "~react-image-gallery/styles/css/image-gallery.css";

import { ManualSkeleton } from '$components/index';

import { CarouselProps } from './types';
import styles from './Carousel.module.less';
import picturePlaceholder from '$assets/img/placeholder.svg';

const ImageGallery = lazy(() => import('react-image-gallery'));

const Carousel = ({ images, isSkeleton, className }: CarouselProps) => {
  // По умолчанию в списке фотографий лодок одна фото с Placeholder
  const [galleryItems, setGalleryItems] = useState<Array<ReactImageGalleryItem>>([
    {
      fullscreen: picturePlaceholder,
      original: picturePlaceholder,
      thumbnail: picturePlaceholder,
    },
  ]);

  // Всё закомменщено, так как выявился баг - при возврате из FullScreen в обычный режим, preview остаёться от FullScreen
  /**
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  /**/

  const renderThumb = (
    item: ReactImageGalleryItem,
    image: {
      w100?: string | null;
      w300?: string | null;
    }
  ): ReactNode => {
    return (
      <span className="image-gallery-thumbnail-inner">
        <img
          src={`${image?.w100}`}
          srcSet={[
            image?.w100 ? `${image?.w100} 1x` : null,
            image?.w300 ? `${image?.w300} 2x` : null, // TODO 2023 нужно исправить, когда будет добавлен w200
            image?.w300 ? `${image?.w300} 3x` : null,
          ]
            .filter((i) => i)
            .join(', ')}
          alt={`${item.thumbnailAlt}`}
          className="image-gallery-thumbnail-image"
        />
      </span>
    );
  };

  /**
  const Item = ({ item, isFullScreen }: { item: ReactImageGalleryItem; isFullScreen: boolean }) => {
    if (isFullScreen) {
      return (
        <img
          className="image-gallery-image"
          src={item.fullscreen}
          srcSet={item.fullscreen}
          alt="/static/media/placeholder.699b08a0.svg"
        />
      );
    }

    return (
      <img
        className="image-gallery-image"
        src={item.original}
        srcSet={item.srcSet}
        alt="/static/media/placeholder.699b08a0.svg"
      />
    );
  };
  /**/

  useEffect(() => {
    if (images && images.length > 0) {
      setGalleryItems(
        images.map((image) => ({
          /**
          srcSet: [
            image?.w680 ? `${image.w680} 1x` : null,
            image?.w1360 ? `${image.w1360} 2x` : null,
            image?.w2040 ? `${image.w2040} 3x` : null,
          ]
            .filter((i) => i)
            .join(', '),
          /**/
          fullscreen: `${image.w2040}`,
          original: `${image.w680}`,
          renderThumbInner: (item) => renderThumb(item, image),
          thumbnail: `${image.w100}`,
          originalAlt: picturePlaceholder,
          thumbnailAlt: picturePlaceholder,
          originalClass: styles.original,
          thumbnailClass: styles.thumbnail,
        }))
      );
    }
  }, [images]);

  if (isSkeleton) {
    return (
      <div className={styles.skeletonMain}>
        <ManualSkeleton className={styles.skeletonPreview} />
        <ManualSkeleton className={styles.skeletonThumbnails} />
      </div>
    );
  }

  // Подгружает компонент библиотеке, только после того как будет получен ответ по запросу на данные boat
  // В идеале можно было бы начать подгрузку ещё до начало запроса на данные, но пока так, а дальше будет Next
  return (
    <Suspense
      fallback={
        <div className={styles.skeletonMain}>
          <ManualSkeleton className={styles.skeletonPreview} />
          <ManualSkeleton className={styles.skeletonThumbnails} />
        </div>
      }
    >
      <ImageGallery
        showPlayButton={false}
        items={galleryItems}
        onErrorImageURL={picturePlaceholder}
        useBrowserFullscreen={false}
        showBullets={false}
        additionalClass={cn(styles.main, className)}
        /**
        onScreenChange={(value) => {
          setIsFullScreen(value);
        }}
        renderItem={(item) => {
          return <Item item={item} isFullScreen={isFullScreen} />;
        }}
        /**/
      />
    </Suspense>
  );
};

export default Carousel;
