import cn from 'classnames';

import { ManualSkeleton } from '$components/index';
import { TitleProps } from './types';
import styles from './Title.module.less';

const Title = ({ name, year, isSkeleton, className }: TitleProps) => {
  if (isSkeleton) {
    return <ManualSkeleton className={styles.skeleton} />;
  }

  return <h1 className={cn(styles.main, className)}>{[name, year].filter((i) => i).join(', ')}</h1>;
};

export default Title;
