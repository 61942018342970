import cn from 'classnames';

import { formatCurrency } from '$utils/formatCurrency';

import { PriceSaleProps } from './types';
import styles from './PriceSale.module.less';

const PriceSale = ({ price, currency, className }: PriceSaleProps) => {
  if (!price || !currency) {
    return <></>; // Skeleton
  }

  return (
    <div className={cn(styles.main, className)}>
      <div className={styles.price}>
        Sale price: <span className={styles.value}>{formatCurrency(price, currency)}</span>
      </div>
    </div>
  );
};

export default PriceSale;
