import { useLocation } from 'react-router-dom';

import { parseUrlQuery } from '$utils/parseUrlQuery';
import { Formatter } from '$utils/Formatter';

interface IParseUserParameters {
  (): void;
}

interface IGetUserParameters {
  (): {
    currency?: string;
    lang?: string;
  } | null;
}

const arrayConformityKeys = [
  {
    url: 'cur',
    localStore: 'currency',
  },
  {
    url: 'unit',
    localStore: 'unit',
  },
  {
    url: 'geo',
    localStore: 'country',
  },
];

function useUserParametersFromUrl(): {
  parseUserParameters: IParseUserParameters;
  getUserParameters: IGetUserParameters;
} {
  const query = parseUrlQuery(useLocation().search);

  const parseUserParameters: IParseUserParameters = () => {
    if (query) {
      Object.keys(query).forEach((key) => {
        const parConformity = arrayConformityKeys.find((conformity) => key === conformity.url);
        const value = query[key];

        if (parConformity && typeof value === 'string') {
          switch (parConformity.localStore) {
            case 'currency': {
              const currency = Formatter.currencies.find((c) => c.code.toLowerCase() === value.toLowerCase());
              if (currency) {
                localStorage.setItem(parConformity.localStore, currency.code);
              }
              break;
            }
            default:
              localStorage.setItem(parConformity.localStore, value);
              break;
          }
        }
      });
    }
  };

  const getUserParameters: IGetUserParameters = () => {
    return {
      currency: localStorage.getItem('currency') || undefined,
      lang: localStorage.getItem('lng') || undefined,
    };
  };

  return {
    parseUserParameters,
    getUserParameters,
  };
}

export default useUserParametersFromUrl;
