import { useDateTime } from '$hooks/index';

import { DatesProps } from './types';
import styles from './Dates.module.less';

const Dates = ({ offer, className }: DatesProps) => {
  const dateTime = useDateTime();

  if (offer) {
    return (
      <div className={className}>
        <div className={styles.option}>
          <div>Dates</div>
          <div>{offer.startAt && offer.endAt && <div>{dateTime.magicShort(offer.startAt, offer.endAt)}</div>}</div>
        </div>
        {offer.location?.address && (
          <div className={styles.option}>
            <div>Pick up location</div>
            <div>
              {offer.location?.address}, {offer.location?.country?.name}
            </div>
          </div>
        )}
        <div className={styles.option}>
          {offer.charter?.captain === true ? (
            <>
              <div>Skipper fee</div>
              <div>included in the price</div>
            </>
          ) : (
            <>
              <div>Bareboat</div>
              <div>no captain is provided</div>
            </>
          )}
        </div>
        {offer.charter.guests && offer.charter.guests > 0 && (
          <div className={styles.option}>
            <div>Number of guests</div>
            <div>{offer.charter.guests} people</div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

export default Dates;
