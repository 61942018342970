import { notification } from '$ui/index';

type NotificationType = 'success' | 'error' | 'info' | 'warning' | 'warn';
type NotificationPayload = Partial<{
  message: string;
  description: string;
  link: string;
}>;

export const showAntdNotification = (type: NotificationType, payload: NotificationPayload): void => {
  const { message = '', description = '', link = null } = payload;

  notification[type]({
    duration: null,
    message,
    description,
    onClick: () => (link ? (window.location.href = link) : false),
  });
};
