import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { useSearchLink } from '$hooks/index';
import { LinkListProps } from './types';

import styles from './LinkList.module.less';

const LinkList = ({ items, title, className }: LinkListProps) => {
  const { i18n, t } = useTranslation();
  const { generatePath } = useGeneratePath();

  const searchParamsForLinkBuyBoats = useSearchLink('buyBoats');

  return (
    <div className={cn(className)}>
      {title}
      {items.map((item) => (
        <div className={styles.item} key={item.key}>
          {item.linkExternal && (
            <a href={item.linkExternal} target="_blank" rel="noreferrer">
              {item.titleI18n && t(item.titleI18n)}
              {item.titleText && item.titleText}
            </a>
          )}
          {item.linkInterior && (
            <a href={'https://getboat.com/' + i18n.language + item.linkInterior} target="_blank" rel="noreferrer">
              {item.titleI18n && t(item.titleI18n)}
              {item.titleText && item.titleText}
            </a>
          )}
          {item.route && (
            <>
              <Link
                to={
                  item.query
                    ? {
                        pathname: generatePath(item.route),
                        search:
                          item.route === routes.BUY_BOATS || item.route === routes.BOATS
                            ? searchParamsForLinkBuyBoats
                            : item.query,
                      }
                    : generatePath(item.route)
                }
              >
                {item.titleI18n && t(item.titleI18n)}
                {item.titleText && item.titleText}
              </Link>

              {item.subLink && item.subLink.route && (
                <>
                  <span> / </span>
                  <Link
                    to={
                      item.query
                        ? {
                            pathname: generatePath(item.subLink.route),
                            search:
                              item.route === routes.BUY_BOATS || item.route === routes.BOATS
                                ? searchParamsForLinkBuyBoats
                                : item.query,
                          }
                        : generatePath(item.subLink.route)
                    }
                  >
                    {t(item.subLink.titleI18n)}
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default LinkList;
