import cn from 'classnames';

import { IconAccountProps } from './types';
import account from './assats/account.svg';
import styles from './IconAccount.module.less';

const IconAccount = ({ name, className, style }: IconAccountProps) => {
  return (
    <svg className={cn(styles[name], className)} style={style}>
      <use xlinkHref={account + '#' + name} />
    </svg>
  );
};

export default IconAccount;
