import img_m_x1_j_croatia from './assets/mobile/x1/croatia.jpg';
import img_m_x1_j_france from './assets/mobile/x1/france.jpg';
import img_m_x1_j_greece from './assets/mobile/x1/greece.jpg';
import img_m_x1_j_italy from './assets/mobile/x1/italy.jpg';
import img_m_x1_j_spain from './assets/mobile/x1/spain.jpg';
import img_m_x1_j_turkey from './assets/mobile/x1/turkey.jpg';

import img_m_x1_w_croatia from './assets/mobile/x1/croatia.webp';
import img_m_x1_w_france from './assets/mobile/x1/france.webp';
import img_m_x1_w_greece from './assets/mobile/x1/greece.webp';
import img_m_x1_w_italy from './assets/mobile/x1/italy.webp';
import img_m_x1_w_spain from './assets/mobile/x1/spain.webp';
import img_m_x1_w_turkey from './assets/mobile/x1/turkey.webp';

import img_m_x2_w_croatia from './assets/mobile/x2/croatia.webp';
import img_m_x2_w_france from './assets/mobile/x2/france.webp';
import img_m_x2_w_greece from './assets/mobile/x2/greece.webp';
import img_m_x2_w_italy from './assets/mobile/x2/italy.webp';
import img_m_x2_w_spain from './assets/mobile/x2/spain.webp';
import img_m_x2_w_turkey from './assets/mobile/x2/turkey.webp';

import img_m_x3_w_croatia from './assets/mobile/x3/croatia.webp';
import img_m_x3_w_france from './assets/mobile/x3/france.webp';
import img_m_x3_w_greece from './assets/mobile/x3/greece.webp';
import img_m_x3_w_italy from './assets/mobile/x3/italy.webp';
import img_m_x3_w_spain from './assets/mobile/x3/spain.webp';
import img_m_x3_w_turkey from './assets/mobile/x3/turkey.webp';

import img_d_x1_j_croatia from './assets/descktop/x1/croatia.jpg';
import img_d_x1_j_france from './assets/descktop/x1/france.jpg';
import img_d_x1_j_greece from './assets/descktop/x1/greece.jpg';
import img_d_x1_j_italy from './assets/descktop/x1/italy.jpg';
import img_d_x1_j_spain from './assets/descktop/x1/spain.jpg';
import img_d_x1_j_turkey from './assets/descktop/x1/turkey.jpg';

import img_d_x1_w_croatia from './assets/descktop/x1/croatia.webp';
import img_d_x1_w_france from './assets/descktop/x1/france.webp';
import img_d_x1_w_greece from './assets/descktop/x1/greece.webp';
import img_d_x1_w_italy from './assets/descktop/x1/italy.webp';
import img_d_x1_w_spain from './assets/descktop/x1/spain.webp';
import img_d_x1_w_turkey from './assets/descktop/x1/turkey.webp';

import img_d_x2_w_croatia from './assets/descktop/x2/croatia.webp';
import img_d_x2_w_france from './assets/descktop/x2/france.webp';
import img_d_x2_w_greece from './assets/descktop/x2/greece.webp';
import img_d_x2_w_italy from './assets/descktop/x2/italy.webp';
import img_d_x2_w_spain from './assets/descktop/x2/spain.webp';
import img_d_x2_w_turkey from './assets/descktop/x2/turkey.webp';

import img_d_x3_w_croatia from './assets/descktop/x3/croatia.webp';
import img_d_x3_w_france from './assets/descktop/x3/france.webp';
import img_d_x3_w_greece from './assets/descktop/x3/greece.webp';
import img_d_x3_w_italy from './assets/descktop/x3/italy.webp';
import img_d_x3_w_spain from './assets/descktop/x3/spain.webp';
import img_d_x3_w_turkey from './assets/descktop/x3/turkey.webp';

type TCountry = {
  name: string;
  locationId: number;
  location: string;
  countBoats: number;
  img: {
    mobile: {
      x1: { webp: string; jpeg: string };
      x2: { webp: string };
      x3: { webp: string };
    };
    desktop: {
      x1: { webp: string; jpeg: string };
      x2: { webp: string };
      x3: { webp: string };
    };
  };
  backgroundColor: string;
};

const countries: Array<TCountry> = [
  {
    name: 'Spain',
    locationId: 625,
    location: 'Spain',
    countBoats: 620,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_spain,
          jpeg: img_m_x1_j_spain,
        },
        x2: {
          webp: img_m_x2_w_spain,
        },
        x3: {
          webp: img_m_x3_w_spain,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_spain,
          jpeg: img_d_x1_j_spain,
        },
        x2: {
          webp: img_d_x2_w_spain,
        },
        x3: {
          webp: img_d_x3_w_spain,
        },
      },
    },
    backgroundColor: '#026EAF',
  },
  {
    name: 'Turkey',
    locationId: 636,
    location: 'Turkey',
    countBoats: 366,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_turkey,
          jpeg: img_m_x1_j_turkey,
        },
        x2: {
          webp: img_m_x2_w_turkey,
        },
        x3: {
          webp: img_m_x3_w_turkey,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_turkey,
          jpeg: img_d_x1_j_turkey,
        },
        x2: {
          webp: img_d_x2_w_turkey,
        },
        x3: {
          webp: img_d_x3_w_turkey,
        },
      },
    },
    backgroundColor: '#136597',
  },
  {
    name: 'Croatia',
    locationId: 619,
    location: 'Croatia',
    countBoats: 2839,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_croatia,
          jpeg: img_m_x1_j_croatia,
        },
        x2: {
          webp: img_m_x2_w_croatia,
        },
        x3: {
          webp: img_m_x3_w_croatia,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_croatia,
          jpeg: img_d_x1_j_croatia,
        },
        x2: {
          webp: img_d_x2_w_croatia,
        },
        x3: {
          webp: img_d_x3_w_croatia,
        },
      },
    },
    backgroundColor: '#002B5A',
  },
  {
    name: 'France',
    locationId: 618,
    location: 'France',
    countBoats: 361,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_france,
          jpeg: img_m_x1_j_france,
        },
        x2: {
          webp: img_m_x2_w_france,
        },
        x3: {
          webp: img_m_x3_w_france,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_france,
          jpeg: img_d_x1_j_france,
        },
        x2: {
          webp: img_d_x2_w_france,
        },
        x3: {
          webp: img_d_x3_w_france,
        },
      },
    },
    backgroundColor: '#579FAE',
  },
  {
    name: 'Greece',
    locationId: 628,
    location: 'Greece',
    countBoats: 2529,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_greece,
          jpeg: img_m_x1_j_greece,
        },
        x2: {
          webp: img_m_x2_w_greece,
        },
        x3: {
          webp: img_m_x3_w_greece,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_greece,
          jpeg: img_d_x1_j_greece,
        },
        x2: {
          webp: img_d_x2_w_greece,
        },
        x3: {
          webp: img_d_x3_w_greece,
        },
      },
    },
    backgroundColor: '#5B85AF',
  },
  {
    name: 'Italy',
    locationId: 622,
    location: 'Italy',
    countBoats: 1020,
    img: {
      mobile: {
        x1: {
          webp: img_m_x1_w_italy,
          jpeg: img_m_x1_j_italy,
        },
        x2: {
          webp: img_m_x2_w_italy,
        },
        x3: {
          webp: img_m_x3_w_italy,
        },
      },
      desktop: {
        x1: {
          webp: img_d_x1_w_italy,
          jpeg: img_d_x1_j_italy,
        },
        x2: {
          webp: img_d_x2_w_italy,
        },
        x3: {
          webp: img_d_x3_w_italy,
        },
      },
    },
    backgroundColor: '#0D7BA5',
  },
];

export default countries;
