import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Dropdown, Button, Menu } from '$ui/index';
import { CompassOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';

import useSession from '$stores/session';
import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { userVar } from '$graphql/local/vars';

import { UserProfileProps } from './types';
import styles from './UserProfile.module.less';

const UserProfile = ({ currentUser }: UserProfileProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const { destroySession } = useSession();

  if (!currentUser?.isLoggedIn) {
    return (
      <Button shape="round" className={styles.spaceComponent}>
        <Link to={generatePath(routes.SIGN_IN)}>{t('frontend.header.avatar_menu.log_in')}</Link>
      </Button>
    );
  }

  const handleLogOut = () => {
    destroySession();
    userVar({
      id: undefined,
      email: '',
      isLoggedIn: false,
      accessAdmin: false,
      isCentralAgent: false,
      isAdmin: false,
      isPaidSubscription: false,
      isSeniorAccount: false,
      isPartnerAccount: false,
    });
  };

  /*
  it is "overlay" () => Menu, no React.Component, https://ant.design/components/dropdown/#API
  if React.Component no show shadow
   */
  const userProfileMenu = () => (
    <Menu className={styles.userProfileMenu}>
      <div className={styles.cardUser}>
        <Avatar size={48} className={styles.avatar}>
          {currentUser?.email.charAt(0).toUpperCase()}
        </Avatar>
        <div className={styles.name}>{currentUser?.email}</div>

        {currentUser?.accessAdmin && (
          <a
            href={
              window.location.hostname === 'staging.getboat.com' || window.location.hostname === 'localhost'
                ? 'https://staging.getboat.com/admin'
                : 'https://getboat.com/admin'
            }
            className={styles.linkAdmin}
          >
            <Button shape="round">{t('frontend.header.avatar_menu.large_button')}</Button>
          </a>
        )}
      </div>

      <Menu.Item>
        <Link to={generatePath(routes.CHARTERS)}>
          <div className={styles.item}>
            <CompassOutlined className={styles.icon} />
            {t('frontend.header.avatar_menu.charters')}
          </div>
        </Link>
      </Menu.Item>

      <Menu.Item onClick={handleLogOut}>
        <div className={styles.item}>
          <LogoutOutlined className={styles.icon} />
          {t('frontend.header.avatar_menu.log_out')}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userProfileMenu} trigger={['click']} placement="bottomRight" className={styles.spaceComponent}>
      <div className={styles.selectorLink}>
        <Avatar>{currentUser?.email.charAt(0).toUpperCase()}</Avatar>
        <DownOutlined className="ml-2" />
      </div>
    </Dropdown>
  );
};

export default UserProfile;
