import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Helmet } from 'react-helmet-async';
import { message, notification } from '$ui/index';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import useSession from '$stores/session';
import {
  useLocationSuggestionsLazyQuery,
  useAddChareterMutation,
  useLocationLazyQuery,
  useSearchBoatCategoriesQuery,
  refetchChartersQuery,
} from '$graphql/hooks';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { useUserParametersFromUrl, useUtmTags } from '$hooks/index';
import { AuthModal } from '$components/index';

import { Form, Map } from './components';
import { FormArgs, RequestPageQueryParams, TBoatCategory } from './types';
import commonStyles from '$assets/styles/Common.module.less';

const RequestPage = ({ location, history }: RouteComponentProps) => {
  const { generatePath } = useGeneratePath();
  const { session } = useSession();
  const urlQuery = parseUrlQuery(location.search) as RequestPageQueryParams;
  const { getUserParameters } = useUserParametersFromUrl();
  const utmTags = useUtmTags().get();
  const sendDataToGTM = useGTMDispatch();

  // Form data
  const [formData, setFormData] = useState<FormArgs>();

  // Move GoogleMap marker
  const [locationId, setLocationId] = useState<string | undefined>(urlQuery?.locationId);
  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number } | null>(null);

  // Boat categories
  const [boatCategoriesLocation, setBoatCategoriesLocation] = useState<string | undefined>(urlQuery?.location);
  const [boatCategories, setBoatCategories] = useState<Array<TBoatCategory>>([]);

  // Auth Modal
  const [authModalVisible, setAuthModalVisible] = useState<boolean>(false);
  const [cellPhoneForSignUp, setCellPhoneForSignUp] = useState<string | undefined>(undefined);
  const [emailForSingUp, setEmailForSingUp] = useState<string | undefined>(undefined);

  // API
  // Autocomplete
  const [getLocationSuggestions, { data: suggestionsData }] = useLocationSuggestionsLazyQuery();

  // Set GoogleMap marker
  const [getLocationData, { data: locationData }] = useLocationLazyQuery();

  const [sendingData, setSendingData] = useState<boolean>(false);

  // Set boat categories by selected location
  const { data: boatCategoriesData } = useSearchBoatCategoriesQuery({
    variables: {
      location: boatCategoriesLocation,
    },
  });

  // Save Form Data
  const [fetchAddCharter] = useAddChareterMutation({
    refetchQueries: [refetchChartersQuery()],
  });

  const [showMap, setShowMap] = useState<boolean>(window.innerWidth >= 991);

  const [selectedTab, setSelectedTab] = useState<'perDay' | 'perHour'>('perDay');

  useEffect(() => {
    const updateWindowDimensions = () => {
      setShowMap(window.innerWidth >= 991);
    };

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const handleSearchLocations = (name: string) => {
    setLocationId(undefined);
    return getLocationSuggestions({ variables: { name } });
  };

  const handleFormComplete = async (values: FormArgs) => {
    setFormData(values);

    if (!session) {
      setCellPhoneForSignUp(values?.phone ? values.phone : undefined);
      setEmailForSingUp(values.email || undefined);
      setAuthModalVisible(true);
      return;
    }

    addCharter(values);
  };

  const addCharter = async (form: FormArgs) => {
    if (form && !sendingData) {
      setSendingData(true);

      const getDateTime = (date?: Date, time?: string): string | undefined => {
        if (date && time) {
          return `${format(date, 'yyyy-MM-dd')} ${time}`;
        } else if (date) {
          return format(date, 'yyyy-MM-dd');
        }

        return undefined;
      };

      try {
        const resp = await fetchAddCharter({
          variables: {
            ...form,
            guests: Number(form?.guests) > 0 ? Number(form?.guests) : undefined,
            startsAt: form?.startsEndsAt?.startsAt
              ? getDateTime(form.startsEndsAt.startsAt, form.startsAtTime)
              : undefined,
            endsAt: form?.startsEndsAt?.endsAt ? getDateTime(form.startsEndsAt.endsAt, form.endsAtTime) : undefined,
            hoursFrom:
              selectedTab === 'perHour' && form?.hourStartsEndsAt?.from ? form.hourStartsEndsAt.from : undefined,
            hoursTo: selectedTab === 'perHour' && form?.hourStartsEndsAt?.to ? form.hourStartsEndsAt.to : undefined,
            phones: form?.phone ? [form?.phone] : undefined,
            locationName: form?.locationName ? form?.locationName : undefined,
            locationId: locationId ? String(locationId) : undefined,
            ...utmTags,
            ...getUserParameters(),
          },
        });

        const charterId = resp?.data?.addCharter?.id;

        if (charterId) {
          sendDataToGTM({ event: 'submit_ga_request_page_form', value: charterId });

          notification.success({
            message: 'Charter request created!',
            key: 'request_form',
            placement: 'bottomRight',
            duration: 5,
          });

          history.push(generatePath(routes.CHARTER_VIEW, { charterId: `${charterId}` }));
        } else {
          message.error('This boat is busy for these dates');
        }
      } catch (err) {
        message.error('This boat is busy for these dates');
      }
    }
  };

  const handleLocationSelect = (id: string, address: string) => {
    setLocationId(id);
    setBoatCategoriesLocation(address);
  };

  useEffect(() => {
    if (locationId) {
      getLocationData({ variables: { id: +locationId } });
    }
  }, [locationId, getLocationData]);

  // Move GoogleMap marker
  useEffect(() => {
    if (!locationData || !locationData.location?.latitude || !locationData.location?.longitude) {
      return;
    }

    const lat = locationData.location?.latitude as number;
    const lng = locationData.location?.longitude as number;

    setMarkerPosition({ lat, lng });
  }, [locationData]);

  // Get boat categories
  useEffect(() => {
    if (!boatCategoriesData) return;

    setBoatCategories(boatCategoriesData.searchBoatCategories);
  }, [boatCategoriesData]);

  const getEndsAt = (arrival?: string, days?: string) => {
    if (arrival && days) {
      return addDays(new Date(arrival), +days);
    } else if (arrival) {
      return new Date(arrival);
    }

    return undefined;
  };

  return (
    <>
      <Helmet>
        <title>Yacht and Boat charter special offers - getboat.com</title>
        <meta name="description" content="Request custom offers for boat rentals and charters on GetBoat." />
      </Helmet>

      <div className={commonStyles.container} data-cy="RequestPage">
        <h1>Yacht and Boat charter special offer</h1>
        <Form
          searchLocations={handleSearchLocations}
          locationSuggestions={suggestionsData?.locationSuggestions}
          onLocationSelect={handleLocationSelect}
          onFormComplete={handleFormComplete}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          boatCategoriesData={boatCategories}
          initialValues={{
            boatCategoryIds: urlQuery?.cat ? urlQuery.cat : undefined,
            locationId: locationId,
            locationName: urlQuery?.location ? urlQuery.location : undefined,
            startsEndsAt: {
              startsAt: urlQuery?.arrival ? new Date(urlQuery.arrival) : undefined,
              endsAt: getEndsAt(urlQuery?.arrival, urlQuery?.days),
            },
            captain: true,
          }}
          ComponentMap={
            <Map
              position={markerPosition}
              zoom={locationData?.location.type === 'Location::Country' ? 7 : 9}
              isCircle={locationData?.location.type !== 'Location::Country'}
            />
          }
          showMap={showMap}
        />
        <AuthModal
          visible={authModalVisible}
          cellPhoneForSignUp={cellPhoneForSignUp}
          emailForSingUp={emailForSingUp}
          handleChangeVisible={setAuthModalVisible}
          request={() => {
            addCharter(formData as FormArgs).then(() => {
              setAuthModalVisible(false);
            });
          }}
        />
        {/*
        <div className={styles.banners}>
            <div className={cn(styles.banner, styles.bannerDesktop)}>
              <Banner
                name={BannerName.GetTransfer}
                type={BannerType.desktop}
              />
            </div>
            <div className={cn(styles.banner, styles.bannerDesktop)}>
              <Banner
                name={BannerName.GetRentaCar}
                type={BannerType.desktop}
              />
            </div>
            <div className={cn(styles.banner, styles.bannerMobile)}>
              <Banner
                name={BannerName.GetRentaCar}
                type={BannerType.mobile}
              />
            </div>
          </div>
        */}
      </div>
    </>
  );
};

export default RequestPage;
