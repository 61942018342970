import { Link } from 'react-router-dom';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { BoatIcons, Icon } from '$components/index';
import picturePlaceholder from '$assets/img/placeholder.svg';

import { BoatProps } from './types';
import styles from './Boat.module.less';

const Boat = ({ boat, offer }: BoatProps) => {
  const { generatePath } = useGeneratePath();

  if (boat) {
    return (
      <>
        <div className={styles.photo}>
          {boat.pictures[0]?.md && (
            <Link
              to={{
                pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                  boatIdOrSlug: offer?.boat.slug ? offer.boat.slug : `${offer?.boat.id}`,
                  offerId: `${offer?.id}`,
                }),
              }}
            >
              <img src={boat.pictures[0]?.md || picturePlaceholder} alt={boat.name ? boat.name : 'boat'} />
            </Link>
          )}
        </div>
        {boat && (
          <div className={styles.main}>
            <Link
              to={{
                pathname: generatePath(routes.BOAT_OFFER_VIEW, {
                  boatIdOrSlug: offer?.boat.slug ? offer.boat.slug : `${offer?.boat.id}`,
                  offerId: `${offer?.id}`,
                }),
              }}
            >
              <h1 className={styles.title}>{boat.name}</h1>
            </Link>
            <div className={styles.rating}>
              {boat.rating ? boat.rating.toFixed(1) : '5.0'} <Icon name="rating" className={styles.icon} />
            </div>
            <div className={styles.parameters}>
              <BoatIcons boat={boat} size="low" />
            </div>
          </div>
        )}
      </>
    );
  }

  return <></>;
};

export default Boat;
