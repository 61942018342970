// https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export const formatCurrency = (
  value: number | undefined,
  currency = localStorage.getItem('currency') || 'EUR',
  locale = window.navigator.language || 'de-DE'
): string => {
  if (!value) {
    return '';
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: value <= 10 ? 2 : 0, // Если цена меньше $10, то отображаются центы
    minimumFractionDigits: 0,
  });

  if (value === -1) {
    return formatter.format(0);
  }

  return formatter.format(value);
};
