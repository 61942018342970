import { useState, useEffect, FocusEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerLazy, Select, Button } from '$ui/index';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';

import { useLocationSuggestionsLazyQuery, useSearchBoatCategoriesQuery } from '$graphql/hooks';
import useGeneratePath from '$hooks/useGeneratePath';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { routes } from '$router/index';
import localeDatePicker from '$utils/localeDatePicker';
import { LandingPageSearchParams } from '$pages/LandingPage/types';
import { Categories, Locations } from '$components/Forms';

import { LandingPageGetOffersFormProps } from './types';
import DaysSelectMenu from './components/DaysSelectMenu';
import styles from './Form.module.less';

const Form = ({ setFormLocationId, setFormLocationName, setLocationNameForTitle }: LandingPageGetOffersFormProps) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const urlQuery = parseUrlQuery(location.search) as LandingPageSearchParams;
  const { generatePath } = useGeneratePath();

  const [locationName, setLocationName] = useState(urlQuery?.l || '');
  const [locationId, setLocationId] = useState('');
  const [boatCategoriesLocation, setBoatCategoriesLocation] = useState(urlQuery?.l || '');
  const [arrivalDate, setArrivalDate] = useState(urlQuery?.arrival || '');
  const [daysCount, setDaysCount] = useState<number | null>(urlQuery?.dur ? +urlQuery?.dur : null);
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    typeof urlQuery?.cat === 'string' ? [urlQuery?.cat] : urlQuery?.cat || []
  );
  const [isDaysSelectOpen, setIsDaysSelectOpen] = useState(false);

  const [getLocationSuggestions, { data: locations }] = useLocationSuggestionsLazyQuery({
    onCompleted(data) {
      if (urlQuery?.l) {
        setLocationId(data.locationSuggestions.length ? data.locationSuggestions[0].id : '');
      }
    },
  });

  const { data: categories } = useSearchBoatCategoriesQuery({ variables: { location: boatCategoriesLocation } });
  const sendDataToGTM = useGTMDispatch();

  /*
  const [fetchOffersLink] = useOffersLinkLazyQuery({
    onCompleted(data) {
      const searchParams = new URLSearchParams();
      const offersLink = data ? '/:lng(en|de|fr)?' + data.offersLink : routes.REQUEST_OFFERS;
      let query = '';
      props.setFormLocationId(String(locationId));

      if (arrivalDate || locationName) {
        searchParams.append('arrival', arrivalDate);
        searchParams.append('locationId', locationId.toString());
        searchParams.append('location', locationName);
        searchParams.append('days', daysCount.toString());
        selectedCategories.forEach((cat) => searchParams.append('cat', cat));
        query = offersLink !== routes.BOATS ? `?${searchParams.toString()}` : '';
      }

      const path = generatePath(offersLink);
      history.push(path + query);
    },
  });
  */

  const handleChangeLocations = (name: string) => {
    setLocationName(name);
    getLocationSuggestions({ variables: { name } });
  };

  const handleSelectLocations = (value: string, option: OptionData | OptionGroupData) => {
    setFormLocationId(String(option.key));
    setLocationNameForTitle(value);
    setLocationName(value);
    setBoatCategoriesLocation(value);
    setLocationId(option.key as string);
  };

  const handleBlurLocations = (e: FocusEvent<HTMLInputElement>) => {
    // TODO 2022 Temporarily. bad code. hotfix
    setTimeout(() => setBoatCategoriesLocation(e.target.value), 500);
  };

  const handleSubmit = () => {
    const searchParams = new URLSearchParams();
    const searchParamsForBoatList = new URLSearchParams();

    let query = '';
    let queryForBoatList = '';

    setFormLocationId(String(locationId));

    if (arrivalDate || locationName) {
      searchParams.append('arrival', arrivalDate);
      searchParams.append('locationId', locationId.toString());
      searchParams.append('location', locationName);
      if (daysCount !== null) searchParams.append('days', daysCount.toString());

      selectedCategories.forEach((cat) => searchParams.append('cat', cat));
      query = `?${searchParams.toString()}`;

      searchParamsForBoatList.append('l', locationName);
      selectedCategories.forEach((cat) => searchParamsForBoatList.append('cat', cat));
      queryForBoatList = `?${searchParamsForBoatList.toString()}`;
    }

    sendDataToGTM({ event: 'submit_ga_landing_page_form' });
    const path = generatePath(routes.REQUEST_OFFERS);
    window.open(path + query, '_blank');
    history.push(generatePath(routes.BOATS) + queryForBoatList);
  };

  useEffect(() => {
    if (!urlQuery?.l) {
      return;
    }

    getLocationSuggestions({ variables: { name: locationName } });
    setFormLocationName(locationName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* When using fields Form for URL-query */
  const searchParams = new URLSearchParams();

  if (locationName) {
    searchParams.append('l', locationName);
  }

  /* TODO 2021 переделать query parameter "cat" в массив */
  if (typeof selectedCategories == 'object' && selectedCategories.length) {
    selectedCategories.forEach((cat) => searchParams.append('cat', cat));
  }

  if (arrivalDate) {
    searchParams.append('arrival', arrivalDate);
  }

  if (daysCount !== null) {
    searchParams.append('dur', daysCount.toString());
  }

  if (urlQuery?.ch) {
    searchParams.append('ch', urlQuery?.ch);
  }

  // TODO 2022 срочное закрытие проблемы. Нужно передалать формирование URL
  if (urlQuery?.utm_medium) searchParams.append('utm_medium', urlQuery?.utm_medium);
  if (urlQuery?.utm_source) searchParams.append('utm_source', urlQuery?.utm_source);
  if (urlQuery?.utm_campaign) searchParams.append('utm_campaign', urlQuery?.utm_campaign);
  if (urlQuery?.utm_content) searchParams.append('utm_content', urlQuery?.utm_content);
  if (urlQuery?.utm_term) searchParams.append('utm_term', urlQuery?.utm_term);
  if (urlQuery?.click_id) searchParams.append('click_id', urlQuery?.click_id);
  if (urlQuery?.gclid) searchParams.append('gclid', urlQuery?.gclid);

  const searchParamsString = searchParams.toString();
  const redirectUrl = generatePath(routes.ROOT);

  useEffect(() => {
    history.push({
      pathname: redirectUrl,
      search: searchParamsString,
    });
  }, [redirectUrl, searchParamsString, history]);
  /**/

  const disabledDate = (current: Dayjs, daysCount: number): boolean => {
    return current && current < dayjs().add(daysCount, 'days').endOf('day');
  };

  const getLabelDuration = (daysCount: number | null) => {
    switch (daysCount) {
      case null:
        return undefined;
      case 0:
        return 'Daytime';
      case 1:
        return daysCount + ' day';
      default:
        return daysCount + ' days';
    }
  };

  return (
    <>
      <div className={styles.form} data-cy="LandingPageFormComponent">
        <Locations
          value={locationName}
          onChange={handleChangeLocations}
          onSelect={handleSelectLocations}
          onBlur={handleBlurLocations}
          items={locations?.locationSuggestions}
          placeholder={t('frontend.banner.location.placeholder')}
          config={{ virtual: false }}
          className={styles.locationInput}
          dataCy="location"
        />
        <DatePickerLazy
          className={styles.dateInput}
          placeholder={t('frontend.banner.arrival.placeholder')}
          onChange={(_, dateString) => setArrivalDate(dateString)}
          disabledDate={(current) => disabledDate(current, 2)}
          suffixIcon={null}
          locale={localeDatePicker({ language: i18n.language })}
          value={arrivalDate ? dayjs(arrivalDate) : null}
          data-cy="date"
        />
        <Select
          open={isDaysSelectOpen}
          onFocus={() => setIsDaysSelectOpen(true)}
          onDropdownVisibleChange={() => setIsDaysSelectOpen(!isDaysSelectOpen)}
          className={styles.daysInput}
          showArrow={false}
          placeholder={t('frontend.banner.days.placeholder')}
          value={getLabelDuration(daysCount)}
          data-cy="duration"
          dropdownRender={() => (
            <DaysSelectMenu
              daysCount={daysCount}
              setDaysCount={setDaysCount}
              setIsDaysSelectOpen={setIsDaysSelectOpen}
            />
          )}
        />
        <Button shape="round" type="primary" onClick={handleSubmit} block className={styles.buttonSubmit}>
          {t('frontend.banner.get_offers')}
        </Button>
      </div>
      {locationName && (
        <Categories
          categories={categories?.searchBoatCategories}
          value={selectedCategories}
          onChange={setSelectedCategories}
        />
      )}
    </>
  );
};

export default Form;
