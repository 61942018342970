import { memo } from 'react';
import { steps } from './data';
import icons from './assets/icons.svg';
import styles from './ExploreBlock.module.less';

const ExploreBlock = () => {
  return (
    <div className={styles.steps}>
      {steps.map((step) => (
        <div key={step.key} className={styles.step}>
          <svg className={styles.icon}>
            <use xlinkHref={icons + '#' + step.icon} />
          </svg>
          <div className={styles.title}>{step.name}</div>
          <div className={styles.description}>{step.description}</div>
        </div>
      ))}
    </div>
  );
};

export default memo(ExploreBlock);
