import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ContactsProps } from './types';
import styles from './Contacts.module.less';

const Contacts = ({ title, items, className }: ContactsProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.main, className)}>
      {title}
      {items.map((item) => (
        <div key={item.key} className={styles.item}>
          <div className={styles.label}>
            {item.labelI18n && !item.label ? `${t(item.labelI18n)}:` : ''}
            {item.label && !item.labelI18n ? `${item.label}:` : ''}
          </div>
          <div className={styles.value}>{item.link ? <a href={item.link}>{item.text}</a> : <>{item.text}</>}</div>
        </div>
      ))}
    </div>
  );
};

export default Contacts;
