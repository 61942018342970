import { TitleBlockProps } from './types';
import styles from './TitlePage.module.less';

const TitleBlock = ({ title, subTitle }: TitleBlockProps) => {
  return (
    <>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </>
  );
};

export default TitleBlock;
