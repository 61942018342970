import { i18n } from 'i18next';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';

import localeDatePickerDe from './localeDatePickerDe';
import localeDatePickerFr from './localeDatePickerFr';
import localeDatePickerEn from './localeDatePickerEn';

type localeDatePickerProps = {
  language: i18n['language'];
};

const localeDatePicker = ({ language }: localeDatePickerProps): PickerLocale | undefined => {
  switch (language) {
    case 'de':
      return localeDatePickerDe;
    case 'fr':
      return localeDatePickerFr;
    default:
      return localeDatePickerEn;
  }
};

export default localeDatePicker;
