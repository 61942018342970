import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { supportedLanguages } from '../i18n';
// import useGeneratePath from '$hooks/useGeneratePath';

function useSetLanguage(): null {
  const { i18n } = useTranslation();
  // const history = useHistory();
  const location = useLocation();
  // const { generatePath } = useGeneratePath();

  useEffect(() => {
    const path = location.pathname.toLocaleLowerCase();
    let language = 'en';
    // let newPath = path;

    if (path.length <= 3) {
      language = path.slice(1, 3);
    } else if (path.match(/^\/(\w{2})\//gi)) {
      language = path.slice(1, 3);
      // newPath = path.slice(3);
    }

    if (!supportedLanguages.includes(language)) {
      language = 'en';
    }

    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }

    if (localStorage.getItem('lng') !== language) {
      localStorage.setItem('lng', language);
    }

    /**
    if (location.pathname !== generatePath('/:lng?' + newPath, { lng: language })) {
      history.replace(generatePath('/:lng?' + newPath, { lng: language }) + location.search + location.hash);
    }
    /**/

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default useSetLanguage;
