import { ApolloClient, createHttpLink, ApolloLink, split } from '@apollo/client';
import { Operation } from '@apollo/client/link/core/types';
import { getMainDefinition } from '@apollo/client/utilities';

import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import useSession, { Session } from '$stores/session';
import { linkError } from './link-error';
import { formatErrors } from './format-errors';
import { cache } from './cache';

const getCableUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const host = window.location.host;
  let token = null;

  try {
    const session = JSON.parse(localStorage.root).Session;
    token = JSON.parse(session).session.token;
  } catch (error) {
    // empty
  }
  return `${protocol}//${host}/cable?token=${token}`;
};

const createActionCableLink = () => {
  const cable = ActionCable.createConsumer(getCableUrl());
  return new ActionCableLink({ cable });
};

const hasSubscriptionOperation = ({ query }: Operation): boolean => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const authLink = new ApolloLink((operation, forward) => {
  let token: Session['token'] | undefined;

  try {
    token = useSession.getState()?.session?.token;
  } catch (error) {
    // empty
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext(({ headers }: any) => ({
    headers: {
      Authorization: token && `Bearer ${token}`,
      ...headers,
    },
  }));
  // get the authentication token from local storage if it exists
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:3001/graphql',
});

const splitLink = split(hasSubscriptionOperation, createActionCableLink(), linkError.concat(authLink).concat(httpLink));

export const client = new ApolloClient({
  link: splitLink,
  cache,
});

export const GraphQL = {
  formatErrors: formatErrors,
};
