import feel_the_luxury_w280 from './assets/w280/feel-the-luxury.webp';
import feel_the_luxury_w380 from './assets/w380/feel-the-luxury.webp';
import international_boat_show_w280 from './assets/w280/international_boat_show.webp';
import international_boat_show_w380 from './assets/w380/international_boat_show.webp';
import boating_vacation_w280 from './assets/w280/boating_vacation.webp';
import boating_vacation_w380 from './assets/w380/boating_vacation.webp';
import most_expensive_yachts_w280 from './assets/w280/most_expensive_yachts.webp';
import most_expensive_yachts_w380 from './assets/w380/most_expensive_yachts.webp';
import boating_in_naples_w280 from './assets/w280/boating_in_naples.webp';
import boating_in_naples_w380 from './assets/w380/boating_in_naples.webp';
import for_yacht_charter_w280 from './assets/w280/for_yacht_charter.webp';
import for_yacht_charter_w380 from './assets/w380/for_yacht_charter.webp';

type TArticle = {
  id: number;
  title: string;
  short: string;
  date: string;
  photoW280: string;
  photoW380: string;
  link: string;
};

const articles: Array<TArticle> = [
  {
    id: 10,
    title: 'Feel the Luxury of Saint-Tropez and Mediterranean with the Aurelia Superyacht!',
    short:
      'Saint-Tropez is considered one of the favorite destinations for yachting enthusiasts. This is not surprising, considering...',
    date: '08.06.2023',
    photoW280: feel_the_luxury_w280,
    photoW380: feel_the_luxury_w380,
    link: 'https://newsgetboat.com/feel-the-luxury/',
  },
  {
    id: 20,
    title: 'Set Sail for Adventure: Join the Athens International Boat Show 2023!',
    short:
      'The end of 2023 will please all boating enthusiasts with a rather prestigious event known as the Athens International Boat Show...',
    date: '18.05.2023',
    photoW280: international_boat_show_w280,
    photoW380: international_boat_show_w380,
    link: 'https://newsgetboat.com/international_boat_show/',
  },
  {
    id: 30,
    title: 'How to pick a perfect vessel for your boating vacation',
    short: 'Boating is a great way to spend time with your family and friends, but it’s also an expensive hobby...',
    date: '24.04.2023 ',
    photoW280: boating_vacation_w280,
    photoW380: boating_vacation_w380,
    link: 'https://newsgetboat.com/boating_vacation/',
  },
  {
    id: 40,
    title: 'The World’s Most Expensive Yachts',
    short: 'Each of us at least once in our lives has dreamed of relaxing on board a luxury superyacht...',
    date: '06.04.2023',
    photoW280: most_expensive_yachts_w280,
    photoW380: most_expensive_yachts_w380,
    link: 'https://newsgetboat.com/most_expensive_yachts/',
  },
  {
    id: 50,
    title: 'Boating in Naples: best places to rent a boat in Naples, Florida',
    short:
      'The Florida Gulf Coast resort of Naples is rather well-known. Naples and the surrounding area provide a lot to see...',
    date: '30.03.2023',
    photoW280: boating_in_naples_w280,
    photoW380: boating_in_naples_w380,
    link: 'https://newsgetboat.com/florida/',
  },
  {
    id: 60,
    title: 'Unwritten Rules for Yacht Charter',
    short:
      'When you’re on a yacht charter, there are some unspoken rules that you need to follow to make the experience a positive one...',
    date: '27.03.2023',
    photoW280: for_yacht_charter_w280,
    photoW380: for_yacht_charter_w380,
    link: 'https://newsgetboat.com/for_yacht_charter/',
  },
];

export default articles;
