import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from '$ui/index';

import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';

import SignUpPage from '$pages/SignUpPage';
import SignInPage from '$pages/SignInPage';

import { AuthModalProps } from './types';
import styles from './AuthModal.module.less';

const AuthModal = ({
  visible,
  cellPhoneForSignUp,
  emailForSingUp,
  handleChangeVisible,
  request,
  isCentralAgent,
}: AuthModalProps) => {
  const { t } = useTranslation();
  const [persistedUser, setPersistedUser] = useState<boolean>(true);
  const { generatePath } = useGeneratePath();

  return (
    <Modal
      title={persistedUser ? t('frontend.sign_up.title') : t('frontend.sign_in.title')}
      visible={visible}
      onCancel={() => handleChangeVisible(false)}
      footer={null}
      className={styles.modal}
    >
      <div className={styles.main}>
        {persistedUser ? (
          <SignUpPage
            type="request"
            cellPhone={cellPhoneForSignUp}
            email={emailForSingUp}
            request={request}
            isCentralAgent={isCentralAgent}
            isModal
          />
        ) : (
          <SignInPage type="request" request={request} isModal />
        )}
        <div>
          {persistedUser ? t('frontend.sign_up.login.already') : t('frontend.sign_up.login.no_account')}{' '}
          <span onClick={() => setPersistedUser(!persistedUser)} className={styles.changeTab}>
            {persistedUser ? t('frontend.sign_in.title') : t('frontend.sign_up.title')}
          </span>
        </div>
        {!persistedUser && (
          <Link to={{ pathname: generatePath(routes.FORGOT_PASSWORD) }} target="_blank" rel="noopener noreferrer">
            {t('frontend.sign_up.login.forget_password')}
          </Link>
        )}
      </div>
    </Modal>
  );
};

export default AuthModal;
