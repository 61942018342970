import { useDateTime } from '$hooks/index';
import { formatCurrency } from '$utils/formatCurrency';

import { PriceProps } from './types';
import styles from './Price.module.less';

const Price = ({ offer, className }: PriceProps) => {
  const dateTime = useDateTime();

  const diffDays = offer ? dateTime.diffDays(offer.startAt, offer.endAt) : null;

  if (offer) {
    return (
      <div className={className}>
        {offer.charter?.promoCode && (
          <div className={styles.promoCode}>
            <div className={styles.description}>The discount has been applied</div>
            <div className={styles.code}>
              <div className={styles.label}>Promo code</div>
              <div className={styles.value}>{offer.charter.promoCode?.promoCode}</div>
            </div>
            <div className={styles.discount}>
              <div className={styles.label}>Discount</div>
              <div className={styles.value}>{offer.charter.promoCode?.discountPercentage}%</div>
            </div>
          </div>
        )}
        {offer?.priceWithoutDiscount?.amount &&
          offer?.price?.amount &&
          offer.priceWithoutDiscount.amount > offer.price.amount && (
            <div className={styles.withoutDiscount}>
              <div className={styles.label}>Without discount</div>
              <div className={styles.value}>
                <s>{formatCurrency(offer.priceWithoutDiscount?.amount, offer.priceWithoutDiscount?.currency)}</s>
              </div>
            </div>
          )}
        <div className={styles.total}>
          <div className={styles.label}>Total</div>
          <div className={styles.value}>{formatCurrency(offer.price?.amount, offer.price?.currency)}</div>
        </div>
        {offer.price?.amount && !!diffDays && diffDays > 0 && (
          <div className={styles.perDay}>
            {formatCurrency(Math.ceil(offer.price.amount / diffDays), offer.price.currency)} x {diffDays} Days
          </div>
        )}
        <div className={styles.firstPayment}>
          <div className={styles.label}>Due now</div>
          <div className={styles.value}>{formatCurrency(offer.firstPaymentAmount, offer.price?.currency)}</div>
        </div>
        {offer.secondPaymentAmount > 0 && (
          <div className={styles.secondPayment}>
            <div className={styles.label}>Due on {dateTime.format(offer.payTillOn)}</div>
            <div className={styles.value}>{formatCurrency(offer.secondPaymentAmount, offer.price?.currency)}</div>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};

export default Price;
