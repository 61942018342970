import { Link } from 'react-router-dom';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { usePartnershipQuery } from '$graphql/hooks';
import { routes } from '$router/index';
import useGeneratePath from '$hooks/useGeneratePath';
import { Button, Spin } from '$ui/index';

import { Partner, PromoCodes, Accounts } from './componets';

import commonStyles from '$assets/styles/Common.module.less';
import styles from './PartnershipPage.module.less';

const PartnershipPage = () => {
  const { generatePath } = useGeneratePath();
  const { data, loading, error } = usePartnershipQuery();

  if (loading) {
    return (
      <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.container)}>
        <Spin style={{ marginTop: '120px' }} indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      </div>
    );
  }

  if (!data?.partnership || error) {
    return (
      <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.container)}>
        <div>Error server</div>
      </div>
    );
  }

  return (
    <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.container)}>
      <div>
        <Button type="primary" shape="round" size="large">
          <Link to={generatePath(routes.CHARTERS)}>
            <LeftOutlined /> Charters
          </Link>
        </Button>
      </div>

      <h1 className={styles.title}>Partnership</h1>

      <h2 className={styles.subTitle}>Main</h2>
      <Partner item={data.partnership.partner} />

      <h2 className={styles.subTitle}>Promo code</h2>
      <PromoCodes items={data.partnership.promoCodes} />

      <h2 className={styles.subTitle}>Accounts</h2>
      <Accounts items={data.partnership.accounts} />
    </div>
  );
};

export default PartnershipPage;
