import { TDateRates } from './types';

export const rates: TDateRates = {
  brokers: {
    id: 10,
    name: 'For brokers',
    rates: [
      {
        id: 10,
        name: 'Basic Plan',
        priceMonth: 40,
        priceYear: 400,
        lookupKeyMonth: 'basic_plan',
        lookupKeyYear: 'basic_plan_year',
        options: [
          {
            id: 10,
            name: 'No comission',
            value: true,
          },
          {
            id: 20,
            name: 'Personal account at GetBoat',
            value: true,
          },
          {
            id: 30,
            name: 'Branded PDF Brochures',
            value: true,
          },
          {
            id: 40,
            name: 'Detailed specification sheet',
            value: true,
          },
          {
            id: 50,
            name: 'Unlimited images',
            value: true,
          },
          {
            id: 60,
            name: 'Unlimited video',
            value: true,
          },
          {
            id: 70,
            name: 'Up to 5 yachts',
            value: true,
          },
          {
            id: 80,
            name: 'Personal support 24/7',
            value: false,
          },
          {
            id: 90,
            name: 'Active links with statistics',
            value: false,
          },
          {
            id: 100,
            name: 'Contact Agents',
            value: false,
          },
          {
            id: 110,
            name: 'API Feed - XML/JSON',
            value: false,
          },
        ],
      },
      {
        id: 20,
        name: 'Premium Plan',
        priceMonth: 99,
        priceYear: 990,
        lookupKeyMonth: 'premium_plan',
        lookupKeyYear: 'premium_plan_year',
        options: [
          {
            id: 10,
            name: 'No comission',
            value: true,
          },
          {
            id: 20,
            name: 'Personal account at GetBoat',
            value: true,
          },
          {
            id: 30,
            name: 'Branded PDF Brochures',
            value: true,
          },
          {
            id: 40,
            name: 'Detailed specification sheet',
            value: true,
          },
          {
            id: 50,
            name: 'Unlimited images',
            value: true,
          },
          {
            id: 60,
            name: 'Unlimited video',
            value: true,
          },
          {
            id: 70,
            name: 'Unlimited boats',
            value: true,
          },
          {
            id: 80,
            name: 'Personal support 24/7',
            value: true,
          },
          {
            id: 90,
            name: 'Active links with statistics',
            value: true,
          },
          {
            id: 100,
            name: 'Contact Agents',
            value: true,
          },
          {
            id: 110,
            name: 'API Feed - XML/JSON',
            value: true,
          },
        ],
      },
    ],
  },
  owners: {
    id: 20,
    name: 'For owners/crew',
    rates: [
      {
        id: 10,
        name: 'Basic Plan',
        priceMonth: 10,
        priceYear: 99,
        lookupKeyMonth: 'basic_plan_owners',
        lookupKeyYear: 'basic_plan_owners_year',
        options: [
          {
            id: 10,
            name: 'No comission',
            value: true,
          },
          {
            id: 20,
            name: 'Personal account at GetBoat',
            value: true,
          },
          {
            id: 30,
            name: 'Branded PDF Brochures',
            value: true,
          },
          {
            id: 40,
            name: 'Detailed specification sheet',
            value: true,
          },
          {
            id: 50,
            name: 'Unlimited images',
            value: true,
          },
          {
            id: 60,
            name: 'Unlimited video',
            value: true,
          },
          {
            id: 70,
            name: 'Up to 2 yachts',
            value: true,
          },
        ],
      },
    ],
  },
};

export const faqs: Array<{ key: string; label: string; children: string }> = [
  {
    key: '10',
    label: 'How does it work?',
    children:
      "It’s incredibly simple! Here's how you can sell your boat on our platform:" +
      '<ol>' +
      '<li>Choose the rate that suits you best.</li>' +
      '<li>Make a purchase and register your account.</li>' +
      '<li>Start listing your boat for sale.</li>' +
      '<li>Once your boat is approved, it will be showcased on our website.</li>' +
      '<li>Sit back and attract potential clients who are interested in your boat.</li>' +
      '</ol>',
  },
  {
    key: '20',
    label: 'What kinds of boats am I able to sell?',
    children:
      'There are no limits to the type of boats you can sell on our platform. ' +
      'Whether it’s a sailboat, yacht, speedboat, or any other type, you can list it for sale with us.',
  },
  {
    key: '30',
    label: 'Where can I find my advertisement on GetBoat.com?',
    children:
      'You can easily find your advertisement in the "Boats for Sale" section of our website. ' +
      'It’s conveniently located for potential buyers to browse and discover your listing.',
  },
  {
    key: '40',
    label: "Why isn't my contact information displayed on the website?",
    children:
      'To ensure your safety and protect against fraud and spam, we do not display your contact information directly on the website. ' +
      'Instead, interested customers can fill out a form expressing their interest in buying your boat. ' +
      'You will receive a notification via email, allowing you to connect with potential buyers securely.',
  },
  {
    key: '50',
    label: "Am I able to make changes to my boat listing after it's been published?",
    children:
      'Absolutely! You have the flexibility to make edits to your boat listing at any time. ' +
      'Whether it’s updating the description, adding new photos, or modifying the price, you can easily manage and refine your listing as needed.',
  },
  {
    key: '60',
    label: 'How can I cancel my membership subscription?',
    children:
      'Canceling your membership subscription is hassle-free. ' +
      'Simply log in to your personal account and click on the "Cancel Subscription" button. ' +
      'The cancellation will take effect at the end of your current billing cycle.',
  },
  {
    key: '70',
    label: 'What occurs when I cancel my membership subscription?',
    children:
      'When you cancel your membership subscription, please note that no refunds will be issued. ' +
      'However, you can still enjoy the benefits of your membership until the end of your billing cycle.',
  },
  {
    key: '80',
    label: 'What is your policy regarding refunds?',
    children:
      'Please note that we do not offer refunds for membership subscriptions. ' +
      "If you'd like to evaluate our membership, we suggest subscribing with a quarterly billing interval to assess the benefits before making a longer commitment.",
  },
  {
    key: '90',
    label: 'Will I receive a notification prior to the next billing cycle?',
    children:
      'Yes, we will send you an email notification ten days prior to processing the next charge for your membership. ' +
      'This way, you will have ample time to manage your subscription and make any necessary adjustments.',
  },
  {
    key: '100',
    label: 'Is it possible to update my email address after subscribing to the membership?',
    children:
      'Certainly! Changing your email address will not impact your membership. ' +
      'Your account and all associated benefits will remain intact while using the new email address you provide.',
  },
];
