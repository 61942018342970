import { useState } from 'react';
import { Modal, Button } from '$ui/index';

import { useCheckPaidSalePlanSubscription } from '$graphql/hooks';

import { MessageProps } from './types';

const Message = ({ sessionId }: MessageProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const {
    data: checkPaidSalePlan,
    loading: checkPaidSalePlanLoading,
    error: checkPaidSalePlanError,
  } = useCheckPaidSalePlanSubscription({
    variables: {
      sessionId: sessionId,
    },
  });

  return (
    <Modal
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      title={
        checkPaidSalePlanError ? (
          'Error Occurred. We are Already Working on Fixing It.'
        ) : (
          <>
            <div>Subscription Confirmed!</div>
            <div>Ready to Start Selling Boats?</div>
          </>
        )
      }
      footer={
        !checkPaidSalePlanError && [
          <Button key="link" href="/admin/#/Boat" type="primary" shape="round" loading={checkPaidSalePlanLoading}>
            Upload your boat
          </Button>,
        ]
      }
      centered
    >
      {checkPaidSalePlan?.checkPaidSalePlan?.success &&
        'We are delighted to inform you that your payment has been successfully processed and your subscription is now confirmed! ' +
          'You now have access to your personal account, where you can begin selling your boats and growing your business. ' +
          'Your journey into the world of boat sales is just beginning!'}
      {checkPaidSalePlanLoading && 'Loading...'}
      {checkPaidSalePlanError &&
        'Please feel free to contact us on <a href="mailto:info@getboat.com" target="_blank" rel="noreferrer">info@getboat.com</a> if you have any questions or need assistance. ' +
          'We appreciate your understanding.'}
    </Modal>
  );
};
export default Message;
