import Spoiler from '$components/Spoiler';

import { ProductsProps } from './types';
import styles from './Products.module.less';

const Products = ({ items }: ProductsProps) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Options</h2>
      <Spoiler suffixId="products" isDisabled={items.length <= 6}>
        <div className={styles.list}>
          {items.map((item) => (
            <div className={styles.item} key={item.id}>
              <div className={styles.label}>{item.productName}</div>
              <div className={styles.value}>
                {item.priceEur} {item.unit.replace('_', ' ')}
              </div>
            </div>
          ))}
        </div>
      </Spoiler>
    </div>
  );
};

export default Products;
