import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from '$ui/index';

import { useCurrentUserLocalQuery } from '$graphql/hooks';
import { routes } from '$router/index';
import { useSearchLink } from '$hooks/index';
import useGeneratePath from '$hooks/useGeneratePath';

import { NavigationProps, TNavigation } from './types';
import { ClockCircleOutlined, DollarCircleOutlined, TeamOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const horizontalModeStyles: CSSProperties = {
  borderBottom: 'none',
  justifyContent: 'center',
  display: 'flex',
};

const verticalModeStyles: CSSProperties = {
  borderRight: 0,
};

const Navigation = ({ mode = 'horizontal', onItemClick = () => false }: NavigationProps) => {
  const { t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const { data: currentUser } = useCurrentUserLocalQuery();

  const searchParamsForLinkBuyBoats = useSearchLink('buyBoats');

  const navigations: Array<TNavigation> = [
    {
      title: t('frontend.header.menu.charter'),
      link: {
        pathname: generatePath(routes.BOATS),
        search: searchParamsForLinkBuyBoats,
      },
      icon: <ClockCircleOutlined />,
    },
    {
      title: 'Sales',
      link: {
        pathname: generatePath(routes.BUY_BOATS),
        search: searchParamsForLinkBuyBoats,
      },
      icon: <DollarCircleOutlined />,
    },
    {
      title: 'Sell my boat',
      link: {
        pathname: generatePath(routes.RATES),
      },
      icon: <TeamOutlined />,
    },
    {
      title: 'List my boat',
      link: currentUser?.currentUser.accessAdmin
        ? 'https://getboat.com/admin/#/Boat'
        : {
            pathname: generatePath(routes.SIGN_UP),
            search: '?iam=ca',
          },
      icon: <MenuUnfoldOutlined />,
    },
    /*
    {
      title: 'frontend.header.menu.buy_used',
      link: 'https://getboat.com/' + i18n.language + '/boats?condition=used&for=sale',
    },
    {
      title: 'frontend.header.menu.experiences',
      link: 'https://getboat.com/' + i18n.language + '/day-trips',
    },
    */
  ];

  return (
    <Menu style={mode === 'horizontal' ? horizontalModeStyles : verticalModeStyles} mode={mode}>
      {navigations.map((navigation) => (
        <Menu.Item onClick={onItemClick} style={{ fontSize: '16px' }} key={navigation.title}>
          {typeof navigation.link === 'string' && navigation.link.startsWith('http') ? (
            <a href={navigation.link} target="_blank" rel="noreferrer">
              {navigation.icon}
              {navigation.title}
            </a>
          ) : (
            <Link to={navigation.link}>
              {navigation.icon}
              {navigation.title}
            </Link>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default Navigation;
